import angular from "angular";

angular
  .module("helme.util")
  .controller("introTourCtrl", [
    "$scope",
    "tour",
    "$state",
    function($scope, tour, $state) {
      $scope.view = "intro";
      $scope.start = function(t) {
        $scope.$close();
        tour.start(t);
      };
      $scope.show = function(v) {
        $scope.view = v;
        if (v === "budget-planning") $state.go("personnel");
        else if (v === "tuition-planning") $state.go("students");
        else if (v === "scenario-planning") $state.go("operations");
      };
      $scope.viewActive = function(v) {
        return $scope.view === v;
      };
    }
  ])
  .controller("tourStepCtrl", [
    "$scope",
    "config",
    function($scope, config) {
      $scope.config = config;
      $scope.next = function() {
        if (config.closeCB) {
          config.closeCB(function() {
            $scope.$close();
          });
        } else {
          $scope.$close();
        }
      };
    }
  ]);
