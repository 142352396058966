import _ from "underscore";
import angular from "angular";

angular.module("helme.graphs").controller("GraphCtrl", [
  "$scope",
  "$rootScope",
  "sessionService",
  "dataService",
  function($scope, $rootScope, sessionService, dataService) {
    $rootScope.$on("projection-calculated", function() {
      // Uncomment these for neat stats!
      // var s2f = edudash.budget.studentToFacultyRatio(dataService.state);
      //
      // var cps = edudash.budget.costPerStudent(dataService.state);
      //
      // var net = edudash.budget.net(dataService.state);
      //

      if (!_.isEmpty(dataService.state)) {
        $scope.projection = dataService.projection;
      }
    });

    $scope.startYear = 2013;
  }
]);

function intoDiscreteBarFormat(data) {
  var index = 2013;
  return [
    {
      key: "Reserves",
      values: data.map(function(d) {
        index += 1;
        return {
          label: index,
          value: d
        };
      })
    }
  ];
}
