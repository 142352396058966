import angular from "angular";

angular.module("helme.util").directive("includeReplace", function() {
  return {
    require: "ngInclude",
    restrict: "A" /* optional */,
    scope: true,
    link: function(scope, el, attrs) {
      el.replaceWith(el.children());
    }
  };
});
