"use strict";
import fs from "fs";

import _ from "underscore";
import angular from "angular";
import angularUiRouter from "@uirouter/angularjs";
import "@uirouter/angularjs/lib/legacy/stateEvents.js";
var stateProvider;

angular
  .module("helme.navigation", [
    "ui.router",
    "ui.router.state.events",
    "helme.models",
    "helme.config"
  ])
  .config([
    "$stateProvider",
    "$urlRouterProvider",
    "ENV",
    function($stateProvider, $urlRouterProvider, ENV) {
      var allIncome = ["fees", "development", "other-income"];
      var allExpense = [
        "personnel",
        "operations",
        "curricular",
        "co-curricular",
        "advancement",
        "other-expenses"
      ];

      var basicHeader = {
        template: fs.readFileSync("./app/js/header/basic.html"),
        controller: "headerCtrl"
      };

      // ugly hack to define stateProvider as a global variable so it can be
      // modified at runtime.
      stateProvider = $stateProvider;

      // For any unmatched url, send to /
      $urlRouterProvider.otherwise("/");
      $stateProvider
        .state("dashboard", {
          url: "/",
          views: {
            main: {
              template: fs.readFileSync("./app/js/dashboard/dashboard.html"),
              controller: "DashCtrl"
            },
            header: {
              template: fs.readFileSync(
                "./app/js/header/dashboard-header.html"
              ),
              controller: "headerCtrl"
            }
          }
        })
        .state("analytics", {
          url: "/analytics",
          views: {
            main: {
              template: fs.readFileSync(
                "./app/js/analytics/analytics-page.html"
              ),
              controller: "AnalyticsCtrl"
            },
            header: {
              template: fs.readFileSync(
                "./app/js/header/analytics-header.html"
              ),
              controller: "headerCtrl"
            }
          }
        })
        .state("compare", {
          url: "/compare",
          views: {
            main: {
              template: fs.readFileSync("./app/js/compare/compare-page.html"),
              controller: "compareCtrl"
            },
            header: basicHeader
          }
        })
        .state("school", {
          url: "/school",
          views: {
            main: {
              template: fs.readFileSync("./app/js/data-page/data-page.html"),
              controller: "SchoolCtrl"
            },
            header: basicHeader
          }
        })
        .state("tuition", {
          url: "/tuition",
          views: {
            main: {
              template: fs.readFileSync("./app/js/tuition/tuition.html"),
              sticky: true,
              controller: "TuitionCtrl"
            },
            header: {
              template: fs.readFileSync("./app/js/header/tuition-header.html"),
              controller: "headerCtrl"
            }
          }
        })
        .state("students", {
          url: "/students",
          views: {
            main: {
              template: fs.readFileSync("./app/js/tuition/students-page.html"),
              sticky: true,
              controller: "StudentCtrl"
            },
            header: {
              template: fs.readFileSync("./app/js/header/students-header.html"),
              controller: "headerCtrl"
            }
          }
        });
      _.forEach(allExpense, function(t) {
        $stateProvider.state(t, budgetCollectionState(t, ENV));
      });
      _.forEach(allIncome, function(t) {
        $stateProvider.state(t, budgetCollectionState(t, ENV));
      });
    }
  ]);

function budgetCollectionState(route, ENV) {
  return {
    url: "^/" + route,
    views: {
      main: {
        template: fs.readFileSync(
          "./app/js/budget-collection/budget-collection.html"
        ),
        controller: "CollectionCtrl",
        resolve: {}
      },
      header: {
        template: fs.readFileSync("./app/js/header/collection-header.html"),
        controller: "headerCtrl"
      }
    }
  };
}
