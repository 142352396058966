import angular from "angular";

angular.module("helme.pages", []).controller("CreateUserCtrl", [
  "userService",
  "dashboardService",
  "api",
  "$scope",
  function(userService, dashboardService, api, $scope) {
    $scope.dashboard = dashboardService.exports;

    $scope.user = {
      name: "",
      pass: "",
      type: userService.userTypes[0].key,
      _id: "new-user"
    };

    $scope.userTypes = userService.userTypes;
    $scope.validUser = function(user) {
      return validEmail(user.name) && user.name !== "";
    };

    $scope.typeDisplay = function(u) {
      return u.name;
    };

    $scope.selectType = function(t) {
      if (t === "viewer" && !$scope.user.dashboard)
        $scope.user.dashboard = "all";
      $scope.user.type = t;
    };

    $scope.dashboardDisplay = function(d) {
      return d.title;
    };

    $scope.selectDashboard = function(d) {
      $scope.user.dashboard = d;
    };

    var all = {title: "All", _id: "all"};
    $scope.dashboardDisplayList = [all].concat(dashboardService.exports.list);

    function validEmail(email) {
      var EMAIL_REGEXP = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/;

      return email.match(EMAIL_REGEXP);
    }

    $scope.validateEmail = function(user) {
      if (!validEmail(user.name)) {
        $scope.err = "Email invalid.";
        return false;
      }
      $scope.err = "";
      return true;
    };

    $scope.view = "creating";
    $scope.submit = function() {
      $scope.view = "loading";
      api.user
        .create($scope.user)
        .then(function(res) {
          $scope.view = "created";
          $scope.user = res.data;
        })
        .catch(function(err) {
          if (err === ":helme.user.database/username-not-unique") {
            $scope.err = "User already exists.";
          } else $scope.err = "User creation failed. Try again later.";
          $scope.view = "creating";
        });
    };
  }
]);
