import _ from "underscore";
import angular from "angular";

angular.module("helme.compare").controller("compareCtrl", [
  "$rootScope",
  "$scope",
  "$modal",
  "formatService",
  "compareService",
  "dataService",
  "Session",
  "ENV",
  "api",
  function(
    $rootScope,
    $scope,
    $modal,
    formatService,
    compareService,
    dataService,
    Session,
    ENV,
    api
  ) {
    function theyreShy() {
      return !$scope.school["comparison-opt-in"];
    }

    function askNicely() {
      $scope.asking = true;
    }

    $scope.agree = function() {
      $scope.school["comparison-opt-in"] = true;
      api.client.update({"comparison-opt-in": true});
      $scope.asking = false;
      setTimeout(function() {
        $rootScope.$broadcast("tile-resize");
      }, 100);
    };

    function setup() {
      $scope.school = Session.school;
      $scope.view = "overview";
      if (theyreShy()) askNicely();
      $scope.comparisons = compareService.exports;
    }

    compareService.ready.promise.then(setup);

    $scope.viewActive = function(view) {
      return $scope.view === view;
    };
    $scope.selectView = function(view) {
      return ($scope.view = view);
    };
    $scope.format = function(value, type) {
      return formatService.formatFn(type)(value);
    };
    $scope.average = function(coll) {
      return (
        _.reduce(
          coll,
          function(acc, item) {
            if (!_.isFinite(item)) return acc;
            return acc + item;
          },
          0
        ) / coll.length
      );
    };
  }
]);
