import _ from "underscore";
import angular from "angular";

angular.module("helme.budgetCollection").controller("PageMoveCtrl", [
  "$scope",
  "$modalInstance",
  "currentPage",
  "item",
  function($scope, $modalInstance, currentPage, item) {
    $scope.pages = [
      {
        name: "Fees",
        id: "fees"
      },
      {
        name: "Development",
        id: "development"
      },
      {
        name: "Other Income",
        id: "other-income"
      }
    ];

    if (!_.contains(["fees", "development", "other-income"], currentPage)) {
      $scope.pages = [
        {
          name: "Personnel",
          id: "personnel"
        },
        {
          name: "Operations",
          id: "operations"
        },
        {
          name: "Curricular",
          id: "curricular"
        },
        {
          name: "Co-Curricular",
          id: "co-curricular"
        },
        {
          name: "Advancement",
          id: "advancement"
        },
        {
          name: "Other Expenses",
          id: "other-expenses"
        }
      ];
    }

    $scope.selected = currentPage;
    $scope.item = item;

    $scope.select = function(page) {
      $scope.selected = page.id;
    };

    $scope.submit = function() {
      $modalInstance.close($scope.selected);
    };

    $scope.cancel = function() {
      $modalInstance.dismiss();
    };
  }
]);
