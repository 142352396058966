import angular from "angular";

angular.module("helme.util").service("saveService", [
  "$rootScope",
  "$interval",
  "sessionService",
  "dataService",
  "api",
  "notify",
  "Session",
  function(
    $rootScope,
    $interval,
    sessionService,
    dataService,
    api,
    notify,
    Session
  ) {
    var service = {};

    service.lastSaved = "";

    service.hasChanged = function() {
      var current = JSON.stringify(dataService.state);
      return service.lastSaved !== current;
    };

    service.save = function() {
      if (
        Session.loggedIn &&
        service.hasChanged() &&
        service.lastSaved !== "" &&
        !sessionService.mode.whatIf &&
        !sessionService.mode.replaying &&
        !sessionService.mode.offline
      ) {
        dataService.state.modified = new Date().getTime();
        service.lastSaved = JSON.stringify(dataService.state);
        localStorage.setItem("budget", service.lastSaved);
        api.budget.update(dataService.state);
      }
    };

    service.skipSave = function() {
      service.lastSaved = JSON.stringify(dataService.state);
    };

    $interval(function() {
      if (service.lastSaved === "") {
        service.lastSaved = JSON.stringify(dataService.state);
      } else {
        service.save();
      }
    }, 5000);

    return service;
  }
]);
