import _ from "underscore";
import angular from "angular";

angular.module("helme.util").service("tour", [
  "$rootScope",
  "dataService",
  "$modal",
  "$timeout",
  "$document",
  "ENV",
  "$state",
  "$sce",
  "inspectService",
  "expandService",
  "sessionService",
  "whatif",
  function(
    $rootScope,
    dataService,
    $modal,
    $timeout,
    $document,
    ENV,
    $state,
    $sce,
    inspectService,
    expandService,
    sessionService,
    whatif
  ) {
    var service = {};

    function getScenarioTour() {
      var deregisterModalShow;
      return {
        id: "scenario-tour",
        steps: [
          {
            title: "Creating a Scenario",
            content:
              '<p>To create a what-if scenario, click this button. Do that now, and name it "Building Scenario".</p>',
            target: function() {
              return document.getElementById("what-if");
            },
            placement: "bottom",
            onNext: function() {
              if (!whatif.inProgress()) whatif.start();
            }
          },
          /*-----------------------------*/
          detachedStep({
            title: "Name Scenario",
            content: "<p> Name your scenario 'Building Scenario'</p>",
            onNext: function() {
              var el = angular.element(
                document.getElementById("confirm-submit")
              );
              if (!!el) {
                $timeout(function() {
                  el.triggerHandler("click");
                }, 30);
              }
            }
          }),
          detachedStep({
            title: "Building Lease",
            content:
              "<p>The first step in building a gym is securing a lease. Add a new item to 'Rent and Mortgage', inside the 'Facilities' category for $200,000 called 'Building Lease'.</p>"
          }),
          /*-----------------------------*/
          {
            title: "Lease Duration",
            content:
              "<p> Next, change its start year to " +
              ENV.prettyYears[1] +
              " and its end year to " +
              ENV.prettyYears[4] +
              ".</p>",
            target: function() {
              return document.getElementById("start-year");
            },
            placement: "left",
            onNext: function() {}
          },
          /*-----------------------------*/
          {
            title: "Lease Growth",
            content: "<p>Finally, give it a 5% annual growth.</p>",
            target: function() {
              return document.getElementById("growth-section");
            },
            placement: "left"
          },
          /*-----------------------------*/
          {
            title: "What-if Reports",
            content:
              "<p>Good work! Normally we might need to add more items (such as maintenance and repairs for the new building, personnel to staff it, and after-school revenue from renting it out) but for now, we'll limit our planning to the lease.</p>" +
              "<p>Click 'View Changes' to see a summary of your changes and their effects.</p>",
            placement: "top",
            target: function() {
              var el = document.getElementById("view-whatif-changes");
              if (_.contains(el.classList, "ng-hide")) {
                hopscotch.prevStep();
              }
              return el;
            },
            onShow: function() {
              deregisterModalShow = $rootScope.$on(
                "show-whatif-summary-modal",
                function() {
                  $timeout(hopscotch.nextStep, 300);
                }
              );
            },
            onNext: function() {
              inspectService.exports.preventDeselect = false;
              deregisterModalShow();
              if (!whatif.summaryModalOpen) whatif.viewCurrent();
            }
          },
          {
            title: "Compare Metrics",
            content:
              "<p>The What-if report lets you compare metrics before and after the proposed changes.</p>" +
              "<p>Change this dropdown to 'Hard Income Coverage' now.</p>",
            target: function() {
              return document.getElementById("whatif-metric-dropdown");
            },
            delay: 500,
            placement: "right"
          },
          detachedStep({
            title: "Adjusting Hard Income Coverage",
            content:
              "<p>The changes you made decreased Hard Income coverage an average of 6% per year. A healthy hard income percentage should be above 94%, so let's increase tuition in the school years " +
              ENV.prettyYears[1] +
              " to " +
              ENV.prettyYears[4] +
              " to account for this.</p>" +
              "<p>Close out of this report and go to the tuition page's Rates tab next.</p>",
            closeCB: function(cb) {
              whatif.closeSummaryModal();
              $state.go("students").then(function() {
                sessionService.mode.tuitionView = "rates";
                $timeout(cb, 500);
              });
            }
          }),
          detachedStep({
            title: "Adjusting Hard Income Coverage",
            content:
              "<p>Your goal should be to hold Hard Income between 95% and 100% for the 7 year period. Try adjusting the rates until you achieve this.</p><p> One possible solution is to set " +
              ENV.prettyYears[1] +
              " growth rates to 4.5%, " +
              ENV.prettyYears[1] +
              " to 6%, " +
              ENV.prettyYears[2] +
              " to 4%, and " +
              ENV.prettyYears[3] +
              " and beyond to 2%.</p><p> Once you're done, click 'Quit What-If' and save.</p>",
            closeCB: function(cb) {
              if (whatif.inProgress()) whatif.quit(true);
              cb();
            }
          }),
          detachedStep({
            title: "Scenario Settings Page",
            content:
              "<p>Good work. Now that you've exited what-if mode, you'll notice your changes were reverted. Your base budget will never be affected by what you do in a scenario.</p>" +
              "<p>Next, head over to the Settings page and click the 'Scenarios' tab.</p>",
            closeCB: function(cb) {
              whatif.closeSummaryModal();
              $state.go("students").then(function() {
                sessionService.mode.tuitionView = "rates";
                $timeout(cb, 500);
              });
            }
          }),
          detachedStep({
            title: "Scenario Actions",
            content:
              "<p>Each scenario has four actions:</p>" +
              "<ul><li>REPORT: View the changes you've made in this scenario.</li>" +
              "<li>EDIT: Load this scenario and make additional changes.</li>" +
              "<li>APPLY: Merge the changes you've made in this scenario into your main budget and remove the scenario.</li>" +
              "<li>X: Delete this scenario.</li></ul>" +
              "<p>Apply the scenario to merge into your budget to finish this tutorial.</p>",
            lastStep: true
          })
        ],
        onStart: function() {
          inspectService.exports.preventDeselect = true;
        },
        onEnd: function() {
          $document[0].getElementsByClassName(
            "hopscotch-bubble"
          )[0].style.display =
            "none";
          service.showIntroModal();
          inspectService.exports.preventDeselect = false;
        }
      };
    }

    function getTuitionTour() {
      return {
        id: "tuition-tour",
        steps: [
          detachedStep({
            title: "Tutorial Goals",
            content:
              "<p>Welcome to the tuition planning tutorial.<p> <p>The tuition tab has four tabs that cover all enrollment-driven planning. We'll go through them left to right, starting with Overview. </p>"
          }),
          /*-----------------------------*/
          detachedStep({
            title: "Organizing Grades",
            content:
              "<p>Overview allows you to organize your grades (for example, into Lower School and Upper School).</p> <p>Try this now - Add a category, rename it to 'Middle School', click its <span class='icon-callout'><i class='fa fa-chevron-right'></i> </span> to expand it, then click and drag grades 7-8 into that category from Upper School and Lower School.</p>"
          }),
          /*-----------------------------*/
          detachedStep({
            title: "Next, Enrollment",
            content:
              "<p>Good job! If you click on the 'Lower School' the Inspector will show you a graph of future Lower School tuition income.</p><p> Next, let's look at the numbers that go into this graph on the Enrollment tab.</p>",
            closeCB: function(cb) {
              sessionService.mode.tuitionView = "enrollment";
              $timeout(cb, 500);
            }
          }),
          /*-----------------------------*/
          {
            title: "Changing Enrollment",
            content:
              "<p>On the Enrollment page, you can see your current and future enrollment numbers.</p> <p>Try adding some students to next year's 6th grade section (maybe up by 18 students?)</p>",
            target: function() {
              var rows = document.getElementsByClassName("enrollment-row");
              return rows[7];
            },
            placement: "top"
          },
          /*-----------------------------*/
          {
            title: "Carry-Through Editing",
            content:
              "<p>You'll notice that, once you increased the number of students by 18, the changes carried through future years of the current 6th grade class.</p> <p>Any time you click on a cell you'll see other cells highlighted dark green. These cells will be changed when you make edits to the current cell.</p> <p>This feature is enabled by default, but can be turned off with this checkbox. </p> <p>Next up, we'll head to the rates page.</p>",
            target: "smart-edit-toggle",
            placement: "bottom",
            onNext: function() {
              sessionService.mode.tuitionView = "rates";
            }
          },
          /*-----------------------------*/
          detachedStep({
            title: "Tuition Rates",
            content:
              "<p>The rates page lets you set current rates and growth assumptions on a per grade and per year basis.</p><p>Try lowering Kindergarten Tuition to $6,000</p>"
          }),
          /*-----------------------------*/
          {
            title: "Rate Lookahead",
            content:
              "<p>Easy, right? To see the effects that had on future kindergarten grade tuition values, click the next cell to the right.</p><p>A new row with all future values just appeared! If you want to see that row for all years, toggle 'Show All Rates' above this table.</p>",
            target: function() {
              var rows = document.getElementsByClassName("rate-row");
              return rows[1];
            },
            placement: "bottom",
            onNext: function() {
              sessionService.mode.tuitionView = "rates";
            }
          },
          /*-----------------------------*/
          {
            title: "Carry-Through: Rates",
            content:
              "<p>In addition to future rates row, you'll notice that more cells were highlighted green when you clicked on the growth cell. Similar to the enrollment page, your edits will affect all highlighted cells. The 'Carry Down' and 'Carry over' toggles will enable / disable this functionality.</p><p> Try changing that cell to 3.5% to see the effects on future grades.</p>",
            target: "smart-edit-toggle",
            placement: "bottom"
          },
          /*-----------------------------*/
          detachedStep({
            title: "Carry-Through Success",
            content:
              "<p>All grades below and beyond just changed to 3.5%. Try doing that in Excel!</p>",
            onNext: function() {
              sessionService.mode.tuitionView = "reductions";
            }
          }),
          /*-----------------------------*/
          detachedStep({
            title: "Reductions",
            content:
              "<p>The reductions tab behaves the same as any budget tabs elsewhere in Helme. They will be subtracted from your tuition income to calculate net income on your dashboard.</p>"
          }),
          /*-----------------------------*/
          detachedStep({
            title: "Congrats!",
            content:
              "<p>You've finished the tuition planning tutorial. You now know how to edit your tuition and enrollment to plan effectively for the future.</p><p> Again, feel free to explore the interface until you feel comfortable. Once you're ready, click 'Finish' to end this tutorial.</p>",
            lastStep: true
          })
        ],
        onEnd: function() {
          $document[0].getElementsByClassName(
            "hopscotch-bubble"
          )[0].style.display =
            "none";
          service.showIntroModal();
          inspectService.exports.preventDeselect = false;
        }
      };
    }

    function getBudgetTour() {
      var faculty, compensation, salaries, LS;
      return {
        id: "budget-planning",
        steps: [
          {
            title: "Collapse / Expanding Categories",
            content:
              "Helme allows you to see your budget in a way you probably haven't experienced before: in a collapsible tree. You can expand and collapse budget categories to drill in and out of your budget. <br /><br />Try that now; click the <span class='icon-callout'><i class='fa fa-chevron-right'></i> </span> on the left side of 'Compensation' to see the items under it. Expand 'Salaries and Wages' and 'Faculty' too.",
            target: function() {
              return document.getElementsByClassName("budget-member")[0];
            },
            placement: "right",
            onNext: function() {
              compensation = dataService.getItem("personnel_0").children[0];
              salaries = compensation.children[0];
              faculty = salaries.children[0];
              expandService.show(compensation);
              expandService.show(salaries);
              expandService.show(faculty);

              LS = faculty.children[0];
              expandService.show(LS);
            }
          },
          /*-----------------------------*/
          detachedStep({
            title: "Create your first item",
            content:
              "<p>Creating and editing new line items in Helme is easy. To show this, we're going to plan for a future hire: a math teacher who starts in " +
              ENV.prettyYears[1] +
              " with a starting salary of $30,000 growing at 4% in the following years.</p><p> To get started, hover over the <span class='icon-callout'><i class='fa fa-ellipsis-v'></i> </span> on the 'LS' item and click 'Create new LS item'.</p>"
          }),
          /*-----------------------------*/
          {
            title: "Item Name and Cost",
            content:
              "Great job! Next, click the name of 'New Item' and change it to 'Math Teacher'. <br /> <br />Do the same for the salary field on the right; change that to $30,000. Don't worry about the commas and dollar sign - you can type in 30000 and we'll format it for you.",
            delay: 100,
            target: function() {
              return document.getElementsByClassName("budget-item")[0];
            },
            onNext: function() {
              document.getElementsByClassName("budget-item")[0].click();
            },
            placement: "right"
          },
          /*-----------------------------*/
          {
            title: "Undo-Redo",
            content:
              "Don't feel nervous changing things; you can undo and redo changes indefinitely so there's nothing you can do that's irreversible. If you ever mess up, just click the undo button up here. Helme's got your back!<br /> <br /> If you want to see a history of your changes, just click the button that looks like a clock.",
            xOffset: "center",
            arrowOffset: "center",
            target: "undo-redo",
            placement: "bottom"
          },
          /*-----------------------------*/
          {
            title: "Inspector",
            content:
              "We call this section the Inspector. It shows you a detailed view of any item that you click. In it, you can change start and end dates of items, add tags to them to categorize them further (more on this later), and set growth assumptions.",
            target: function() {
              return document.getElementById("inspector-head");
            },
            placement: "left"
          },
          /*-----------------------------*/
          {
            title: "Future Items",
            content:
              "Our new teacher will start in the " +
              ENV.prettyYears[1] +
              "-" +
              ENV.prettyYears[2] +
              " school year. Set the start date to " +
              ENV.prettyYears[1] +
              ' to reflect this. Note that we use "' +
              ENV.prettyYears[1] +
              '" to mean the ' +
              ENV.prettyYears[1] +
              "-" +
              ENV.prettyYears[2] +
              " school year - this is consistent throughout Helme.",
            target: function() {
              return document.getElementById("start-year");
            },
            placement: "left"
          },
          /*-----------------------------*/
          detachedStep({
            title: "Growth Assumptions",
            content:
              "<p>Good work. Next up, we'll change the growth assumptions of this new teacher.</p>" +
              '<p>By default, line items inherit the growth assumptions of the categories they\'re in. For example, if the Salaries category has a 4.5% projected annual growth, each item under Salaries will by default have a 4.5% annual growth rate. We call this the "Same as parent" option, as you can see to the right.</p>'
          }),
          /*-----------------------------*/
          {
            title: "Salary Growth",
            content:
              "Now click the Growth dropdown and change it 'Percent Increase'.<br /><br />" +
              "Next, change the field that appears from 3% to 4%.",
            target: function() {
              return document.getElementById("growth-section");
            },
            placement: "left"
          },
          /*-----------------------------*/
          detachedStep({
            title: "Other Forecast Methods",
            content:
              "<p>Nice job! The graph at the top of the inspector should reflect your changes.</p>" +
              "<p>For future reference, there are two other forecast methods: 'Dollar increase' (e.g. $1000 increase per year) and 'manually set' (set exact values for each year). We won't use them for now, but a combination of these three growth models should cover most scenarios you'll need to model.</p>"
          }),
          /*-----------------------------*/
          detachedStep({
            title: "Now you're ready to start budgeting!",
            content:
              "<p> You've completed the tutorial for budget item planning. As you can see, Helme gives you complete control over each line item in your budget so you can plan well.</p>" +
              "<p> We encourage you to continue experimenting with the interface; nothing you do here will be saved. When you're done, hit 'Finish' to go back to the tutorial list.</p>",
            lastStep: true
          })
        ],
        onStart: function() {
          console.info("Starting Intro Tour");
          inspectService.exports.preventDeselect = true;
        },
        onEnd: function() {
          $document[0].getElementsByClassName(
            "hopscotch-bubble"
          )[0].style.display =
            "none";
          service.showIntroModal();
          inspectService.exports.preventDeselect = false;
        }
      };
    }

    function detachedStep(config) {
      return {
        title: "hi",
        content: "hi",
        target: function() {
          var el = $document[0].getElementById("dashboard-tab");
          return el;
        },
        placement: "right",
        onShow: function() {
          config.content = $sce.trustAsHtml(config.content);
          service.showDetachedStep(config);
        },
        onNext: function() {
          if (config.onNext) config.onNext();
        }
      };
    }

    var introOpen = false;
    service.showIntroModal = function() {
      if (!introOpen) {
        introOpen = true;
        var currentModal = $modal.open({
          controller: "introTourCtrl",
          templateUrl: "./intro.html",
          windowClass: "helme-modal tour-modal",
          keyboard: false
        });
        currentModal.result.then(function() {
          introOpen = false;
        });
      }
    };

    var stepOpen = false;
    service.showDetachedStep = function(config) {
      if (!stepOpen) {
        $document[0].getElementsByClassName(
          "hopscotch-bubble"
        )[0].style.display =
          "none";
        stepOpen = true;
        var stepModal = $modal.open({
          controller: "tourStepCtrl",
          templateUrl: "./tour-step.html",
          backdrop: false,
          windowClass: "helme-modal tour-step-modal",
          keyboard: false,
          resolve: {
            config: function() {
              return config;
            }
          }
        });
        stepModal.result.then(function() {
          var el = $document[0].getElementsByClassName("hopscotch-bubble")[0];
          el.style["display"] = "block";
          stepOpen = false;
          hopscotch.nextStep();
        });
      }
    };

    service.start = function(t) {
      window.scrollTo(0, 0);
      var tour;
      if (t === "budget-planning") tour = getBudgetTour();
      else if (t === "tuition-planning") tour = getTuitionTour();
      else tour = getScenarioTour();

      hopscotch.startTour(tour);
    };

    $rootScope.$on("start-intro-tour", service.showIntroModal);

    return service;
  }
]);
