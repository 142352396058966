import angular from "angular";

angular.module("helme.header", []).controller("headerCtrl", [
  "$scope",
  "$rootScope",
  "$state",
  "dataService",
  "collectionService",
  "tuitionService",
  "sessionService",
  "saveService",
  "authService",
  "footerService",
  "Session",
  "dashboardService",
  "whatif",
  "exportService",
  "ENV",
  "actualsService",
  function(
    $scope,
    $rootScope,
    $state,
    dataService,
    collectionService,
    tuitionService,
    sessionService,
    saveService,
    authService,
    footerService,
    Session,
    dashboardService,
    whatif,
    exportService,
    ENV,
    actualsService
  ) {
    function setHeader() {
      if (!Session.school) {
        return;
      }
      $scope.schoolName = Session.school.name;
      $scope.type = dataService.tabType({
        key: $state.current.name
      });
      if ($state.current.name === "dashboard") {
        $scope.title = "Edudash";
        $scope.subtitle = Session.school.name;
      } else if ($state.current.name === "analytics") {
        $scope.title = "Forecasted Analytics";
        $scope.subtitle = "";
      } else if ($state.current.name === "school") {
        $scope.title = "Settings & Data";
        $scope.subtitle = "";
      } else if ($state.current.name === "compare") {
        $scope.title = "Comparative Intelligence";
        $scope.subtitle = "";
      } else if ($state.current.name === "enrollment") {
        $scope.title = "Enrollment";
        $scope.subtitle = tuitionService.getEnrollmentHeader();
      } else if ($state.current.name === "settings") {
        $scope.title = "Settings";
        $scope.subtitle = "";
      } else if ($state.current.name === "students") {
        $scope.title = "Students";
        $scope.subtitle = tuitionService.getHeader();
        $scope.root = collectionService.root;
        $scope.enrollment = tuitionService.enrollment;
        $scope.$watch(
          "enrollment.capacity",
          function(val, old) {
            if (!!val && val !== old && val > 0) {
              dataService.state.tuition.capacity[1] = val;
              $rootScope.$broadcast("budget-changed");
            }
          },
          true
        );
      } else {
        $scope.root = collectionService.root;

        $scope.title = dataService.tabTitle({
          key: $state.current.name
        });

        $scope.subtitle = collectionService.tabTotal(sessionService.mode.year);

        $scope.$watch("root.assumptions.rate", function(val, old) {
          if (!!val && val !== old) {
            collectionService.updateRoot($scope.root);
          }
        });

        $scope.yearModes = [
          {
            name: "Current Year",
            key: "current"
          },
          {
            name: "Next Year",
            key: "next"
          }
        ];
      }
    }

    $scope.yearModeChange = function(mode) {
      footerService.banner.visible = mode === "next";
      footerService.banner.text =
        "Editing the " + ENV.prettyYears[1] + " school year.";
      $scope.subtitle = collectionService.tabTotal(mode);
    };

    $scope.yearModeDisplay = function(mode) {
      return mode.name;
    };

    $scope.dashboardDisplay = function(dash) {
      return dash.title;
    };

    $scope.enrollmentDisplay = function(mode) {
      return mode.title;
    };

    $scope.enrollmentModeChange = function(mode) {
      tuitionService.mode.enrollmentView = mode;
      tuitionService.mode.carryOver = mode === "totals";
    };

    $scope.addTile = dashboardService.addTile;
    $scope.dashboard = dashboardService.exports;
    $scope.dashboardFullscreen = dashboardService.goFullscreen;
    $scope.dashboardPrint = dashboardService.print;
    $scope.dashboardChange = dashboardService.dashboardChange;
    $scope.dashboardAddEntry = dashboardService.addEntry;
    $scope.dashboardEdit = dashboardService.dashboardEdit;

    $scope.showActualsList = actualsService.showList;

    $scope.exportBudget = exportService.showModal;

    $scope.addItems = collectionService.bulkAddRoot;
    $scope.addCategory = collectionService.addCategory;

    // Are future items visible?
    $scope.collectionMode = collectionService.mode;
    $scope.collectionMode.futureItemsVisible = true;

    $scope.tuitionMode = tuitionService.mode;
    $scope.enrollment = tuitionService.enrollment;
    $scope.setAllRates = tuitionService.setAllRates;
    $scope.enrollmentModes = [
      {
        title: "Totals",
        key: "totals"
      },
      {
        title: "Annual Change",
        key: "deltas"
      }
    ];

    $scope.sessionMode = sessionService.mode;
    $scope.undo = sessionService.undo;
    $scope.validate = sessionService.undo;
    $scope.redo = sessionService.redo;
    $scope.viewOnly = sessionService.isViewOnly;
    $scope.viewAllDashboards = sessionService.viewAllDashboards;
    $scope.exportCurrent = exportService.exportCurrent;

    $scope.showTagOverview = function() {
      sessionService.mode.analytics.view = "label-overview";
      sessionService.mode.analytics.overviewVisible = true;
    };

    $scope.showQuickbooksTab = function() {
      var accounts = actualsService.getAllAccounts();
      return accounts && accounts.length > 0;
    };

    $scope.showActualsButton = function() {
      return (
        Session.school &&
        (Session.school.sync.qbOnlineStatus ||
          Session.school.sync.qbDesktopStatus)
      );
    };

    $scope.whatIfStart = whatif.start;

    $scope.logout = function() {
      saveService.save();
      authService.logout();
    };

    if (window.Headway) $scope.toggleHeadway = window.Headway.toggle;

    sessionService.ready.promise.then(setHeader);
    $scope.$on("auth-login-success", setHeader);
    $scope.$on("projection-calculated", setHeader);
    $scope.$on("tab-switch", setHeader);
  }
]);
