import _ from "underscore";
import angular from "angular";

angular.module("helme.tags").directive("tagInput", [
  "$rootScope",
  "tagService",
  "$document",
  "formatService",
  "ENV",
  function($rootScope, tagService, $document, formatService, ENV) {
    return {
      restrict: "E",
      replace: true,
      scope: {
        item: "=",
        label: "=",
        createDisabled: "="
      },
      templateUrl: "./tag-input.html",
      link: function(scope, element, attrs) {
        scope.tags = tagService.tags;
        scope.tagItems = {};
        scope.current = "";

        scope.getTags = function(current) {
          var tagsCopy = tagService.tags.slice(0);
          if (
            !scope.createDisabled &&
            current &&
            !_.contains(tagsCopy, current)
          ) {
            tagsCopy.push(current);
          }
          return tagsCopy;
        };

        scope.addTag = function() {
          if (!scope.item.tags) {
            scope.item.tags = [];
          }
          tagService.addTag(scope.current, scope.item);
          scope.current = "";
        };

        scope.removeTag = function(tag) {
          tagService.removeTag(tag, scope.item);
        };
      }
    };
  }
]);
