import _ from "underscore";
import angular from "angular";

angular.module("helme.pages").controller("itemSortCtrl", [
  "$scope",
  "$rootScope",
  "$modalInstance",
  "dataService",
  "sessionService",
  "pages",
  "formatService",
  "api",
  "saveService",
  "items",
  function(
    $scope,
    $rootScope,
    $modalInstance,
    dataService,
    sessionService,
    pages,
    formatService,
    api,
    saveService,
    items
  ) {
    $scope.items = items;

    $scope.categories = {
      income: pages.incomeCollections,
      expense: pages.expenseCollections
    };
    $scope.categories.unknown = $scope.categories.income.concat(
      $scope.categories.expense
    );

    $scope.categoryName = function(category) {
      return dataService.tabTitle({
        key: category
      });
    };

    $scope.format = formatService.formatShortDollarValue;

    function removeItem(item) {
      sorted = _.mapObject(sorted, function(coll, category) {
        return _.filter(coll, function(i) {
          return i.name !== item.name || i.value !== item.value;
        });
      });
    }

    var sorted = {};
    $scope.sortItem = function(item, category) {
      removeItem(item);
      if (!sorted[category]) {
        sorted[category] = [item];
      } else {
        sorted[category].push(item);
      }

      if (items[index].length === 2) items[index].push("");

      if (category !== "skip") {
        if (
          _.some($scope.categories.income, function(c) {
            return c === category;
          })
        )
          items[index][2] = "income";
        else items[index][2] = "expense";
      } else items[index][2] = "skip";

      $scope.nextItem();
    };

    $scope.itemMarker = function(item) {
      if (item.length === 2) return "unassigned-marker";
      else if (item[2] === "skip") return "skip-marker";
      return "assigned-marker";
    };

    var index = 0;

    function loadItem() {
      $scope.current = items[index][1];
      $scope.type = items[index][0];
    }

    $scope.nextItem = function() {
      if (index < items.length - 1) {
        index = (index + 1) % items.length;
        loadItem();
      } else $scope.finished = true;
    };

    $scope.lastItem = function() {
      index = (index - 1) % items.length;
      loadItem();
    };

    $scope.setIndex = function(i) {
      if (i !== items.length) $scope.finished = false;
      index = i;
      loadItem();
    };

    loadItem();

    $scope.submit = function() {
      $scope.loading = true;
      api.budget
        .insert(sorted)
        .then(function(res) {
          dataService.state = res.data;

          dataService.projection = [];
          $rootScope.$broadcast("full-state-change");
          $rootScope.$broadcast("budget-changed");
          dataService.setAllPaths(dataService.state);

          saveService.skipSave();
          $modalInstance.close();
        })
        .catch(function() {
          $scope.loading = false;
        });
    };
  }
]);
