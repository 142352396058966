import _ from "underscore";
import angular from "angular";

angular.module("helme.budgetCollection").controller("BulkAddCtrl", [
  "$scope",
  "$modalInstance",
  function($scope, $modalInstance) {
    $scope.items = [{name: "", value: 0}];

    var completed = 0;

    $scope.itemChange = function(index) {
      return function(value) {
        if (index === $scope.items.length - 1) {
          $scope.items.push({name: "", value: 0});
        }
      };
    };

    $scope.submit = function() {
      var completedItems = _.filter($scope.items, function(item) {
        return item.name !== "";
      });
      $modalInstance.close(completedItems);
    };

    $scope.cancel = function() {
      $modalInstance.dismiss();
    };
  }
]);
