import _ from "underscore";
import angular from "angular";

angular.module("helme.util").service("metrics", [
  "$rootScope",
  "dataService",
  "pages",
  "formatService",
  "ENV",
  "tagService",
  function($rootScope, dataService, pages, formatService, ENV, tagService) {
    var service = {};

    service.reserve = function(d) {
      return d.reserve;
    };

    var debt = function(d) {
      return d.modules.accounts.debt;
    };

    var endowment = function(d) {
      return d.modules.accounts.endowment;
    };

    var sectionTotal = function(section, d) {
      var total = _.reduce(
        d[section].categories,
        function(acc, category) {
          return acc + category.value;
        },
        0
      );
      if (section === "income") {
        total += service.tuitionNet(d);
      }
      return total;
    };

    service.income = sectionTotal.bind(null, "income");

    service.expense = sectionTotal.bind(null, "expense");

    var net = function(d) {
      return service.income(d) - service.expense(d);
    };

    var categoryTotals = function(section) {
      return function(d) {
        return _.reduce(
          d[section].categories,
          function(acc, category) {
            acc[category.name] = category.value;

            return acc;
          },
          {}
        );
      };
    };

    function combineProjectionKeys(projectedMetric) {
      return _.mapObject(projectedMetric[0], function(val, k) {
        return _.pluck(projectedMetric, k);
      });
    }

    function diffMetricObject(before, after) {
      var combined = {};
      _.mapObject(before, function(val, k) {
        if (!_.isEqual(val, after[k])) {
          combined[k] = {
            before: val,
            after: after[k],
            diff: _.map(val, function(v, i) {
              return after[k][i] - val[i];
            })
          };
        }
        return val;
      });
      return combined;
    }

    service.overviewDiff = function(before, after) {
      var reportBefore = service.fullOverview(before.projection);
      var reportAfter = service.fullOverview(after.projection);

      var metrics = diffMetricObject(reportBefore.totals, reportAfter.totals);
      var income = diffMetricObject(reportBefore.income, reportAfter.income);
      var expense = diffMetricObject(reportBefore.expense, reportAfter.expense);

      return {
        "Budget Totals": metrics,
        "Income Categories": income,
        "Expense Categories": expense
      };
    };

    service.fullOverview = function(projection) {
      // income, net, expense, enrollment, hard income,
      // net tuition, gross tuition.
      // income categories, expense categories.
      var overview = service.overview(projection);
      var incomeTotals = combineProjectionKeys(
        _.map(projection, categoryTotals("income"))
      );
      var expenseTotals = combineProjectionKeys(
        _.map(projection, categoryTotals("expense"))
      );
      return {
        totals: {
          Income: overview.income,
          Expense: overview.expense,
          Net: overview.net,
          "Hard Income": _.map(projection, hardIncome),
          Enrollment: _.map(projection, service.enrollmentTotal),
          "Net Tuition": _.map(projection, service.tuitionNet),
          "Gross Tuition": _.map(projection, service.tuitionGross),
          "Avg. Tuition Rate": _.map(projection, tuitionAverage),
          "Avg. Discounted Rate": _.map(projection, tuitionAverageDiscounted),
          "Avg. Cost per Student": _.map(projection, service.costPerStudent)
        },
        income: incomeTotals,
        expense: expenseTotals
      };
    };

    service.overview = function(projection) {
      return {
        income: _.map(projection, service.income),
        expense: _.map(projection, service.expense),
        net: _.map(projection, net)
      };
    };

    function pnlCalc(d, k) {
      var a = {
        actual: {
          income: [d.modules.pandl.actual[k].income],
          expense: [d.modules.pandl.actual[k].expense],
          net: [
            d.modules.pandl.actual[k].income - d.modules.pandl.actual[k].expense
          ]
        },
        budgeted: {
          income: [d.modules.pandl.budgeted[k].income],
          expense: [d.modules.pandl.budgeted[k].expense],
          net: [
            d.modules.pandl.budgeted[k].income -
              d.modules.pandl.budgeted[k].expense
          ]
        }
      };
      a.diff = {
        income: a.budgeted.income - a.actual.income,
        expense: a.budgeted.expense - a.actual.expense,
        net: a.budgeted.net - a.actual.net
      };
      return a;
    }

    function monthlyPnl(d) {
      return pnlCalc(d, "month");
    }

    function ytdPnl(d) {
      return pnlCalc(d, "ytd");
    }

    service.enrollmentTotal = function(d) {
      return d.tuition["enrollment-total"];
    };

    service.tuitionGross = function(d) {
      return d.tuition.gross;
    };

    service.tuitionNet = function(d) {
      return d.tuition.gross - d.tuition.reductions.value;
    };

    var aidTuitionRatio = function(d) {
      return d.tuition.reductions.value / d.tuition.gross;
    };

    var aidTotalRatio = function(d) {
      return d.tuition.reductions.value / service.expense(d);
    };

    var tuitionAverage = function(d) {
      return service.tuitionGross(d) / service.enrollmentTotal(d);
    };

    var tuitionAverageDiscounted = function(d) {
      return service.tuitionNet(d) / service.enrollmentTotal(d);
    };

    service.costPerStudent = function(d) {
      return service.expense(d) / service.enrollmentTotal(d);
    };

    service.enrollmentByGrade = function(d) {
      return {
        series: [
          _.map(d.tuition.classes, function(c) {
            return c.item.students;
          })
        ],
        seriesLabels: ["Enrollment By Grade"],
        axisLabels: _.map(d.tuition.classes, function(c) {
          return c.name;
        })
      };
    };

    service.formatComparisonData = function(
      series,
      seriesLabels,
      axisLabels,
      total
    ) {
      if (!axisLabels)
        axisLabels = _.map(_.range(15, 15 + series[0].length), function(d) {
          return "'" + d;
        });
      if (!seriesLabels) seriesLabels = _.map(series, "");
      return {
        series: series,
        legendLabels: seriesLabels,
        axisLabels: axisLabels,
        total: total
      };
    };

    service.enrollmentGrowth = function() {
      var growth = _.map(dataService.projection, function(budget, i) {
        if (i < dataService.projection.length - 1) {
          var delta =
            service.enrollmentTotal(dataService.projection[i + 1]) -
            service.enrollmentTotal(budget);
          return delta / service.enrollmentTotal(budget);
        }
        return 0;
      });
      return growth.slice(0, growth.length - 1);
    };

    var enrollmentVsCapacity = function(d) {
      var data = [
        _.map(dataService.projection, function(budget) {
          return budget.tuition.capacity[1];
        }),
        _.map(dataService.projection, service.enrollmentTotal)
      ];
      return data;
    };

    var hardIncome = function(d) {
      return (
        (service.income(d) - d.income.categories.development.value) /
        service.expense(d)
      );
    };

    var cashAccounts = function(d) {
      var accounts = _.sortBy(d.modules.accounts.cash, "value").reverse();
      var total = 0;
      if (accounts.length > 1) {
        total = _.reduce(
          _.pluck(accounts, "value"),
          function(a, v) {
            return a + v;
          },
          0
        );
      }
      return {
        series: [_.pluck(accounts, "value")],
        total: total,
        axisLabels: _.pluck(accounts, "name"),
        seriesLabels: "amount"
      };
    };

    var development = function(d, id) {
      var drives = _.filter(d.modules.fundraising, function(drive) {
        return drive.id === id;
      });
      return {
        series: [
          _.pluck(drives, "goal"),
          _.map(drives, function(drive) {
            return drive.pledged + drive.given;
          }),
          _.pluck(drives, "given")
        ],
        axisLabels: [" "], //_.pluck(drives, "name"),
        seriesLabels: ["goal", "given+pledged", "given"]
      };
    };

    /* Types:
     series - line, bar
     */
    service.library = [
      {
        key: "reserve",
        fn: service.reserve,
        name: "Reserve",
        type: "series",
        unit: "$"
      },
      {
        key: "income",
        fn: service.income,
        name: "Total Income",
        type: "series",
        unit: "$"
      },
      {
        key: "expense",
        fn: service.expense,
        name: "Total Expense",
        type: "series",
        unit: "$"
      },
      {
        key: "net",
        fn: net,
        name: "Net Income",
        type: "series",
        unit: "$"
      },
      {
        key: "overview",
        fn: service.overview,
        name: "7 Year Forecast",
        type: "overview",
        unit: "$"
      },
      {
        key: "hard-income",
        fn: hardIncome,
        name: "Hard Income Coverage",
        type: "series",
        unit: "%"
      },
      {
        key: "pnl",
        fn: calcPnl,
        name: "Net P&L",
        type: "labelled-series",
        unit: "$"
      },
      {
        key: "month-pnl",
        fn: monthlyPnl,
        name: "Monthly P&L",
        type: "pnl",
        unit: "$"
      },
      {
        key: "ytd-pnl",
        fn: ytdPnl,
        name: "Year to Date P&L",
        type: "pnl",
        unit: "$"
      },
      {
        key: "tuition-net",
        fn: service.tuitionNet,
        name: "Net Tuition",
        type: "series",
        unit: "$"
      },
      {
        key: "tuition-gross",
        fn: service.tuitionGross,
        name: "Gross Tuition",
        type: "series",
        unit: "$"
      },
      {
        key: "aid-tuition-ratio",
        fn: aidTuitionRatio,
        name: "Aid % of Tuition",
        type: "series",
        unit: "%"
      },
      {
        key: "aid-total-ratio",
        fn: aidTotalRatio,
        name: "Aid % of Budget",
        type: "series",
        unit: "%"
      },
      {
        key: "fundraisers",
        fn: development,
        name: "Development",
        type: "labelled-series",
        unit: "$"
      },
      {
        key: "cash-accounts",
        fn: cashAccounts,
        name: "Cash Accounts",
        type: "labelled-series",
        unit: "$"
      },
      {
        key: "cost-per-student",
        fn: service.costPerStudent,
        name: "Cost per Student",
        type: "series",
        unit: "$"
      },
      {
        key: "enrollment",
        fn: service.enrollmentTotal,
        name: "Enrollment",
        type: "series",
        unit: "students"
      },
      {
        key: "enrollment-by-grade",
        fn: service.enrollmentByGrade,
        name: "Enrollment By Grade",
        type: "labelled-series",
        unit: "students"
      },
      {
        key: "enrollment-vs-capacity",
        fn: enrollmentVsCapacity,
        name: "Enrollment Vs Capacity",
        type: "double-series",
        unit: "students"
      },
      {
        key: "endowment",
        fn: endowment,
        name: "Endowment",
        type: "actual",
        unit: "$"
      },
      {
        key: "debt",
        fn: debt,
        name: "Debt",
        type: "actual",
        unit: "$"
      }
    ];

    service.getMetric = function(k) {
      return _.find(service.library, function(metric) {
        return metric.key === k;
      });
    };

    service.list = _.keys(service.library);

    service.projectFn = function(type, projection) {
      var f = service.getMetric(type).fn;

      if (!projection) projection = dataService.projection;

      return _.map(projection, f);
    };

    service.masterTable = function(projection, doFormat) {
      var income = calcSectionValues(projection, "income");
      var expense = calcSectionValues(projection, "expense");
      var tuition = service.projectFn("tuition-net");
      income.unshift({
        title: "Tuition",
        values: tuition
      });
      var incomeTotal = sectionTotal(income);
      var expenseTotal = sectionTotal(expense);
      var net = _.map(incomeTotal, function(d, i) {
        return incomeTotal[i] - expenseTotal[i];
      });
      if (doFormat) {
        income = formatSection(income);
        expense = formatSection(expense);
        incomeTotal = _.map(incomeTotal, formatService.formatShortDollarValue);
        expenseTotal = _.map(
          expenseTotal,
          formatService.formatShortDollarValue
        );
        net = _.map(net, formatService.formatShortDollarValue);
      }
      return {
        income: income,
        expense: expense,
        incomeTotal: incomeTotal,
        expenseTotal: expenseTotal,
        net: net
      };
    };

    var formatSection = function(section) {
      return _.map(section, function(category) {
        return {
          title: category.title,
          values: _.map(category.values, formatService.formatShortDollarValue)
        };
      });
    };

    var sectionTotal = function(sectionValues) {
      var totals = [];
      for (var x = 0; x < sectionValues[0].values.length; x++) {
        totals.push(
          _.reduce(
            sectionValues,
            function(acc, category) {
              return acc + category.values[x];
            },
            0
          )
        );
      }
      return totals;
    };

    var calcSectionValues = function(projection, type) {
      return _.chain(projection)
        .map(function(budget) {
          return _.chain(budget[type].categories)
            .filter(function(category, key) {
              return _.some(dataService.state[type].tabs, function(tab) {
                return tab.key === key && !tab.hidden;
              });
            })
            .map(function(category) {
              return {
                title: category.name,
                values: [category.value]
              };
            })
            .value();
        })
        .reduce(function(year, acc) {
          var combined = acc;
          _.forEach(year, function(category, i) {
            combined[i].values = category.values.concat(combined[i].values);
          });
          return combined;
        })
        .sortBy(function(category) {
          return -1 * category.values[0];
        })
        .value();
    };

    service.getItemProjectionById = function(id, budget, projection) {
      if (!id) return [];
      if (!budget) {
        budget = dataService.state;
      }
      if (!projection) {
        projection = dataService.projection;
      }
      var item = dataService.getItem(id, budget);
      var values = _.map(projection, function(b, year) {
        if (year < item.start || year > item.end) return 0;
        var i = dataService.getItem(id, b);
        return !!i && i.active ? i.value : 0;
      });
      values = _.map(values, function(v) {
        return _.isNull(v) ? 0 : v;
      });
      return values;
    };

    service.getItemProjection = function(item, budget, projection) {
      return service.getItemProjectionById(item.id);
    };

    service.formatDatumForD3 = function(d, i, type) {
      return {
        value: d,
        type: type,
        year: ENV.currentYear + i
      };
    };

    service.netPnl = function(pnl) {
      return {
        budgeted: {
          month: pnl.budgeted.month.income - pnl.budgeted.month.expense,
          ytd: pnl.budgeted.ytd.income - pnl.budgeted.ytd.expense
        },
        actual: {
          month: pnl.actual.month.income - pnl.actual.month.expense,
          ytd: pnl.actual.ytd.income - pnl.actual.ytd.expense
        }
      };
    };

    function calcPnl(d) {
      var net = service.netPnl(d.modules.pandl);

      return {
        seriesLabels: ["actual", "budgeted"],
        axisLabels: ["monthly", "ytd"],
        series: [
          [net.budgeted.month, net.actual.month],
          [net.budgeted.ytd, net.actual.ytd]
        ]
      };
    }

    service.tagFn = function(tag, initial, fn, budget) {
      var ids = tagService.itemLookup[tag].slice(0);
      var items = _.map(ids, function(id) {
        var budgetItem = dataService.getItem(id);
        if (!budget || !budget.year) return budgetItem;
        else if (
          budget.year < budgetItem.start ||
          budgetItem.end <= budget.year
        )
          return null;
        var item = dataService.getItem(id, budget);
        return item;
      });
      return _.reduce(
        items,
        function(acc, item) {
          return tagFnHelper(acc, item, fn);
        },
        {
          value: initial,
          processed: []
        }
      ).value;
    };

    function tagFnHelper(acc, item, fn) {
      if (!item) {
        return acc;
      }
      if (!!item.children) {
        return _.reduce(
          item.children,
          function(acc, item) {
            return tagFnHelper(acc, item, fn);
          },
          acc
        );
      }

      if (_.contains(acc.processed, item.id)) return acc;

      return {
        value: fn(acc.value, item),
        processed: acc.processed.concat([item.id])
      };
    }

    service.tagLeafCount = function(tag) {
      return service.tagFn(tag, 0, function(acc, item) {
        return acc + 1;
      });
    };

    service.tagOverview = function(tag, projection) {
      if (!projection) projection = dataService.projection;
      var pOverview = _.map(projection, function(budget, year) {
        return service.tagFn(
          tag,
          {
            income: 0,
            expense: 0,
            net: 0
          },
          function(acc, item) {
            if (!!item.link && !item.value) {
              item.value = dataService.getClassValue(budget, item);
            }
            if (!item || year < item.start || item.end < year) return acc;
            var type = dataService.itemTypes[item.id];
            if (type === "expense") {
              return {
                income: acc.income,
                expense: acc.expense + item.value,
                net: acc.net - item.value
              };
            }
            if (
              _.isNaN(acc.income + item.value) ||
              _.isNaN(acc.net + item.value)
            ) {
              console.info("Undefined", item);
            }

            return {
              income: acc.income + item.value,
              expense: acc.expense,
              net: acc.net + item.value
            };
          },
          budget
        );
      });

      return _.reduce(
        pOverview,
        function(acc, overview) {
          return {
            income: acc.income.concat([overview.income]),
            expense: acc.expense.concat([overview.expense]),
            net: acc.net.concat([overview.net])
          };
        },
        {
          income: [],
          expense: [],
          net: []
        }
      );
    };
    return service;
  }
]);
