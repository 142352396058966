import _ from "underscore";
import angular from "angular";

angular.module("helme.pages").directive("tile", [
  "dashboardService",
  "ENV",
  "formatService",
  "metrics",
  "$state",
  "sessionService",
  function(
    dashboardService,
    ENV,
    formatService,
    metrics,
    $state,
    sessionService
  ) {
    return {
      restrict: "E",
      replace: true,
      scope: {
        tile: "=resolvedTile"
      },
      templateUrl: "./tile.html",
      link: function(scope) {
        scope.pnlFormat = function(val, size) {
          if (size === 1 && Math.abs(val) >= 1000000)
            return formatService.formatDollarValue(val, true);
          return formatService.formatDollarValue(val);
        };
        scope.values = _.values;
        scope.startEditing = function(resolvedTile) {
          var tile = dashboardService.findTile(resolvedTile.id);
          dashboardService.editTile(tile);
        };

        scope.removeTile = function(tile) {
          dashboardService.removeTile(tile);
        };

        scope.numberDisplay = function(tile) {
          var val = tile.data.series[0][0];
          var display;
          if (tile.unit === "$")
            display = formatService.formatShortDollarValue(val);
          else if (tile.unit === "%")
            display = formatService.humanNumber(val * 1000) / 10 + "%";
          else if (tile.unit === "students") display = val + " students";
          else display = formatService.humanNumber(val);
          var metric = metrics.getMetric(tile.fn);
          var subtitle =
            !!metric && metric.type === "actual"
              ? "As of " + formatService.formatDate(new Date())
              : ENV.prettyYears[0];
          return {
            value: display,
            subtitle: subtitle
          };
        };

        scope.titleExtra = function(tile) {
          if (tile.data.total)
            return (
              ": " +
              formatService.formatShortDollarValue(tile.data.total) +
              " total"
            );
          return "";
        };

        scope.setupDevelopment = function() {
          sessionService.mode.settingsView = "accounts-drives";
          $state.go("school");
        };
      }
    };
  }
]);
