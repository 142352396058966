import _ from "underscore";
//shhh, ignore this.
import angular from "angular";

angular.module("helme.templates", []);

angular
  .module("helme.models", [])
  .service("dataService", [
    "pages",
    function(pages) {
      var service = {};
      service.state = {};
      service.projection = [];
      service.paths = {};
      service.itemTypes = {};

      service.pageData = function(state, tab) {
        if (_.isEmpty(state)) return null;
        if (tab.key === "tuition") return state["tuition"];
        var type = service.tabType(tab);
        if (!!state[type].categories) return state[type].categories[tab.key];
        return null;
      };

      service.getPageData = function(tab) {
        return service.pageData(service.state, tab);
      };

      service.setStatePageData = function(tab, data, state) {
        var type = service.tabType(tab);
        if (_.isEmpty(state)) return {};
        if (!!state[type].categories) {
          state[type].categories[tab.key] = data;
        }
        return state;
      };

      service.setPageData = function(tab, data) {
        service.setStatePageData(tab, data, service.state);
      };

      service.tabTitle = function(tab) {
        if (!tab) return "";
        var page = service.getPageData(tab);
        return !!page ? page.name : "";
      };

      service.tabType = function(tab) {
        return service.stateType(tab.key);
      };

      service.stateType = function(state) {
        if (
          _.contains(
            ["dashboard", "compare", "analytics", "school", "settings"],
            state
          )
        )
          return "school";
        if (
          _.some(pages.expense, function(t) {
            return t.key === state;
          })
        ) {
          return "expense";
        }
        return "income";
      };

      service.getTab = function(key) {
        if (key === "students")
          return {
            key: "students"
          };
        var result = _.find(pages.expense, function(t) {
          return t.key === key;
        });

        if (!!result) return result;

        return _.find(pages.income, function(t) {
          return t.key === key;
        });
      };

      function allCollections(budget) {
        return _.values(budget.income.categories)
          .concat(_.values(budget.expense.categories))
          .concat([budget.tuition.reductions])
          .concat([budget.tuition.tree]);
      }

      service.itemCategory = function(id) {
        var path = service.paths[id];
        var combined = allCollections(service.state);
        return _.find(combined, function(child) {
          return child.id === path[0];
        });
      };

      function pathFn(id, budget, fn) {
        var path = service.paths[id];

        if (!budget) budget = service.state;

        var combined = allCollections(budget);

        return fn(combined, path);
      }

      service.findItem = function(id, budget) {
        var combined = allCollections(budget);

        return service.findItemHelper(combined, id);
      };

      service.findItemHelper = function(children, id) {
        var item = _.find(children, function(child) {
          return child.id === id;
        });

        if (!!item) return item;

        for (var x = 0; x < children.length; x++) {
          var result = null;
          if (!!children[x].children)
            result = service.findItemHelper(children[x].children, id);
          if (!!result) return result;
        }

        return null;
      };

      service.findItemPath = function(id, budget) {
        var combined = allCollections(budget);

        return service.findItemPathHelper(combined, id, []);
      };

      service.findItemPathHelper = function(children, id, path) {
        var item = _.find(children, function(child) {
          return child.id === id;
        });

        if (!!item) return path.concat([item]);

        for (var x = 0; x < children.length; x++) {
          var result = null;
          if (!!children[x].children)
            result = service.findItemPathHelper(
              children[x].children,
              id,
              path.concat([children[x]])
            );
          if (!!result) return result;
        }

        return null;
      };

      service.setLinkValues = function(budget) {
        var gradeEnrollmentTotals = budget.tuition.classes.reduce(function(
          acc,
          c
        ) {
          acc[c.name] = c.item.students;
          return acc;
        },
        {});

        var sum = 0;
        _.forEach(allCollections(budget), function(item) {
          sum += service.setLinkValuesHelper(
            item,
            budget.year ? budget.year : 0,
            budget.tuition.gross,
            budget.tuition["enrollment-total"],
            gradeEnrollmentTotals
          );
        });
        return sum;
      };

      function setItemLinkingValue(
        item,
        year,
        tuitionGross,
        totalEnrollment,
        gradeEnrollmentTotals
      ) {
        if (item.linking.type === "tuition-gross") {
          item.value = tuitionGross * (item.linking.percents[year] / 100);
        } else if (item.linking.type === "enrollment-total") {
          item.value =
            totalEnrollment *
            item.linking.multiples[year] *
            (item.linking.percents[year] / 100);
        } else if (item.linking.type === "enrollment-grade") {
          item.value =
            gradeEnrollmentTotals[item.linking.grade] *
            item.linking.multiples[year] *
            (item.linking.percents[year] / 100);
        }
      }

      service.setLinkValuesHelper = function(
        item,
        year,
        tuitionGross,
        totalEnrollment,
        gradeEnrollmentTotals
      ) {
        if (item.linking) {
          var before = item.value;
          //TODO move this to linkItemService
          setItemLinkingValue(
            item,
            year,
            tuitionGross,
            totalEnrollment,
            gradeEnrollmentTotals
          );
          return item.value - before;
        }

        var sum = 0;
        if (item.children) {
          for (var x = 0; x < item.children.length; x++) {
            var v = service.setLinkValuesHelper(
              item.children[x],
              year,
              tuitionGross,
              totalEnrollment,
              gradeEnrollmentTotals
            );
            if (v) sum += v;
          }
        }
        item.value += sum;
        return sum;
      };

      service.getItem = function(id, budget) {
        if (!budget) {
          budget = service.state;
        }
        if (!!service.paths[id])
          var item = pathFn(id, budget, service.getItemHelper);
        if (!item) item = service.findItem(id, budget);
        return item;
      };

      service.getItemHelper = function(children, path) {
        var nextNode = _.find(children, function(child) {
          return child.id === path[0];
        });

        if (path.length === 1) {
          return nextNode;
        }

        if (!!nextNode) {
          return service.getItemHelper(nextNode.children, path.slice(1));
        }
        console.error("Error! Could not find " + path[0]);
        return null;
      };

      service.getActiveAssumptions = function(id, budget) {
        return pathFn(id, budget, service.assumptionsHelper);
      };

      service.assumptionsHelper = function(children, path) {
        var nextNode = _.find(children, function(child) {
          return child.id === path[0];
        });
      };

      service.fillClassTree = function(budget, index) {
        if (!index) index = 0;
        _.forEach(budget.tuition.tree.children, function(child) {
          fillClassHelper(child, index);
        });
      };

      function getClassReductionsTotal(budget, classItem) {
        var reductions = budget.tuition.reductions.children;

        var classReductions = findMatchingChildren(reductions, function(item) {
          return (
            item.linking &&
            item.linking.type === "enrollment-grade" &&
            item.linking.grade === classItem.id
          );
        });

        return classReductions.reduce(function(acc, r) {
          return (acc += r.value);
        }, 0);
      }

      function findMatchingChildren(children, fn) {
        var matches = _.filter(children, function(child) {
          return fn(child);
        });

        for (var x = 0; x < children.length; x++) {
          var result = [];
          if (!!children[x].children)
            matches.concat(findMatchingChildren(children[x].children, fn));
        }
        return matches;
      }

      service.getClassValue = function(budget, item) {
        if (!item.children) {
          var linked = _.find(budget.tuition.classes, function(c) {
            return c.name === item.link;
          });
          var reductionTotal = getClassReductionsTotal(budget, item);
          if (!!linked) return linked.value - reductionTotal;
        }
        return 0;
      };

      function fillClassHelper(item, index) {
        if (!item.children) {
          var value = service.getClassValue(service.projection[index], item);
          item.value = value;
          item.active = true;
        } else {
          _.forEach(item.children, function(child) {
            fillClassHelper(child, index);
          });
          item.value = _.reduce(
            item.children,
            function(acc, child) {
              return acc + child.value;
            },
            0
          );
          item.active = true;
        }
      }

      service.setAllPaths = function(budget) {
        var paths = {
          reductions_0: ["reductions_0"],
          "class-root": ["class-root"]
        };
        setChildrenPaths(budget.expense.categories, [], paths, "expense");
        setChildrenPaths(budget.income.categories, [], paths, "income");
        setChildrenPaths(
          budget.tuition.reductions.children,
          ["reductions_0"],
          paths,
          "income"
        );
        setChildrenPaths(
          budget.tuition.tree.children,
          ["class-root"],
          paths,
          "income"
        );

        return paths;
      };

      var setChildrenPaths = function(children, path, paths, type) {
        _.forEach(children, function(child) {
          var newPath = path.concat([child.id]);
          if (!!child.path) delete child["path"];
          paths[child.id] = newPath;
          service.itemTypes[child.id] = type;
          if (!!child.children)
            setChildrenPaths(child.children, newPath, paths, type);
        });
      };

      service.getFutureItems = function() {
        service.futureItems = _.map(_.range(8), function() {
          return [];
        });

        futureItemHelper(service.state.expense.categories, "expense", []);
        futureItemHelper(service.state.income.categories, "income", []);
        futureItemHelper(service.state.tuition.reductions.children, "school", [
          "Reductions"
        ]);
        futureItemHelper(service.state.tuition.tree.children, "income", [
          "Tuition"
        ]);

        service.futureItems = _.map(service.futureItems, function(items, year) {
          return _.groupBy(items, function(item) {
            // Ugly hack to group starting and ending future items.
            return item.path.join(" > ") + (item.op === "start" ? " " : "");
          });
        });
        return service.futureItems;
      };

      var futureItemHelper = function(children, type, path) {
        _.forEach(children, function(child) {
          if (!!child.start && child.start > 0)
            service.futureItems[child.start].push({
              item: child,
              type: type,
              op: "start",
              path: path
            });
          if (!!child.end && child.end < 8)
            service.futureItems[child.end].push({
              item: child,
              type: type,
              op: "end",
              path: path
            });
          else if (!!child.children)
            futureItemHelper(child.children, type, path.concat([child.name]));
        });
      };

      return service;
    }
  ])
  .service("pages", [
    "api",
    "$rootScope",
    "$state",
    function(api, $rootScope, $state) {
      var service = {};

      service.incomeCollections = ["fees", "development", "other-income"];
      service.expenseCollections = [
        "personnel",
        "operations",
        "curricular",
        "co-curricular",
        "advancement",
        "other-expenses"
      ];

      service.expense = [];
      service.income = [];

      service.setTabs = function(budget) {
        service.expense = budget.expense.tabs;
        service.income = budget.income.tabs;
      };

      return service;
    }
  ])
  .service("models", [
    "$state",
    "guid",
    function($state, guid) {
      var service = {};

      service.newItem = function() {
        return {
          name: "New Item",
          value: 10000,
          assumptions: {
            mode: "inherited",
            rate: 3,
            delta: 350,
            granular: [10000, 10350, 10700, 11050, 11400, 11750, 12100]
          },
          id: $state.current.name + "_" + guid.make()
        };
      };

      service.newCategory = function(addChild) {
        var category = {
          name: "New Category",
          children: [],
          assumptions: {
            mode: "inherited",
            rate: 3
          },
          id: $state.current.name + "_" + guid.make()
        };
        if (addChild) category.children.push(service.newItem());
        return category;
      };

      return service;
    }
  ])
  .service("guid", function() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return {
      make: function() {
        return (
          s4() +
          s4() +
          "-" +
          s4() +
          "-" +
          s4() +
          "-" +
          s4() +
          "-" +
          s4() +
          s4() +
          s4()
        );
      }
    };
  });
