import angular from "angular";
import {templates} from "./compile";
import notify from "@cgross/angular-notify";
import sortable from "ng-sortable";
import gridster from "angular-gridster";
import upload from "ng-file-upload";
import helmelib from "../../../helmelib/target/js/helmelib";
import sass from "~/style/sass/main.sass";

angular
  .module("helme", [
    "helme.navigation",
    "helme.session",
    "helme.api",
    "helme.auth",
    "helme.models",
    "helme.budgetCollection",
    "helme.pages",
    "helme.graphs",
    "helme.header",
    "helme.footer",
    "helme.util",
    "helme.tags",
    "helme.input",
    "helme.ledger",
    "helme.inspector",
    "helme.export",
    "helme.wizard",
    "helme.whatif",
    "helme.quickbooks",
    "helme.config",
    "helme.compare",
    "helme.sync",
    "helme.templates",
    "compile.directive",
    // Third party
    "cgNotify",
    "ui.bootstrap",
    "as.sortable",
    "gridster",
    "ngFileUpload"
  ])
  .run([
    "$templateCache",
    $templateCache => {
      templates.forEach(t => {
        $templateCache.put(t.name, t.content);
      });
    }
    // Other templates go here...
  ])
  .run([
    "wizard",
    "sync",
    "tour",
    function(wizard, sync, tour) {
      console.info("App loaded.");
    }
  ]);
/* Vendor */
require("~/js/vendor/ui-bootstrap-custom-0.12.0.min.js");
// require("~/js/vendor/ui-bootstrap-custom-tpls-0.12.0.min.js");
require("~/js/vendor/ui-bootstrap-custom-tpls-0.13.3.min.js");

/*Others*/
require("./root");
require("./compile");
require("~/js/settings/create-user-controller");
require("~/js/settings/settings-controller");
require("~/js/settings/edit-user-controller");
require("~/js/settings/user-service");
require("~/js/budget-collection/link-item-service");
require("~/js/budget-collection/link-item-controller");
require("~/js/budget-collection/simple-directive");
require("~/js/budget-collection/service");
require("~/js/budget-collection/controller");
require("~/js/budget-collection/add-controller");
require("~/js/budget-collection/move-controller");
require("~/js/budget-collection/collection-service");
require("~/js/budget-collection/directive");
require("~/js/data-page/school-controller");
require("~/js/footer/service");
require("~/js/footer/directive");
require("~/js/ledger/replay-service");
require("~/js/ledger/service");
require("~/js/ledger/delorean");
// require("~/js/tuition/tuition-service");
require("~/js/tuition/service");
require("~/js/tuition/student-controller");
require("~/js/tuition/enrollment-controller");
require("~/js/tuition/future-grade-controller");
require("~/js/tuition/tuition-controller");
require("~/js/util/format");
require("~/js/util/metrics");
require("~/js/util/hints");
require("~/js/util/replace");
require("~/js/inspector/service");
require("~/js/inspector/controller");
require("~/js/inspector/directive");
require("~/js/auth/login-controller");
require("~/js/auth/service");
require("~/js/input/confirm-dialog");
require("~/js/input/series-directive");
require("~/js/input/dropdown");
require("~/js/input/confirm-dialog-controller");
require("~/js/input/directive");
require("~/js/wizard/service");
require("~/js/wizard/controller");
require("~/js/navigation/routes");
require("~/js/navigation/directive");
require("~/js/quickbooks/actuals-link-service");
require("~/js/quickbooks/service");
require("~/js/quickbooks/actuals-link-controller");
require("~/js/quickbooks/mock");
require("~/js/quickbooks/link-button");
require("~/js/quickbooks/actuals-bar");
require("~/js/models");
require("~/js/config");
require("~/js/whatif/service");
require("~/js/whatif/summary-controller");
require("~/js/analytics/controller");
require("~/js/tags/service");
require("~/js/tags/directive");
require("~/js/dashboard/tile-directive");
require("~/js/dashboard/service");
require("~/js/dashboard/controller");
require("~/js/dashboard/edit-dashboard-controller");
require("~/js/dashboard/create-dashboard-controller");
require("~/js/dashboard/create-controller");
require("~/js/compare/service");
require("~/js/compare/controller");
require("~/js/tour/controller");
require("~/js/tour/tour");
require("~/js/graphs/line");
require("~/js/graphs/pie");
require("~/js/graphs/service");
require("~/js/graphs/donut");
require("~/js/graphs/grouped-bar");
require("~/js/graphs/controller");
require("~/js/graphs/bullet");
require("~/js/graphs/area");
require("~/js/graphs/overview");
require("~/js/graphs/scatter");
require("~/js/iq/service");
require("~/js/sync/service");
require("~/js/sync/highlight");
require("~/js/export/loading-controller");
require("~/js/export/excel-export-controller");
require("~/js/export/service");
require("~/js/export/export-modal-controller");
require("~/js/export/print-export-controller");
require("~/js/api");
require("~/js/import/excel-import-controller");
require("~/js/import/item-sort-controller");
require("~/js/import/import-controller");
require("~/js/import/import-service");
require("~/js/header/controller");
require("~/js/app");
require("~/js/session/service");
require("~/js/session/save");
require("~/js/session/controller");
require("~/js/session/intercom");
require("~/js/session/directive");

/* Helmlib */

if (module && module.hot) {
  module.hot.accept(() => {});
  // ["ng-sortable", "./budget-collection/directive.js"] // Either a string or an array of strings
}
