import angular from "angular";

angular.module("helme.auth", []).controller("LoginCtrl", [
  "$scope",
  "$modalInstance",
  "authService",
  "ENV",
  "api",
  function($scope, $modalInstance, authService, ENV, api) {
    var failMessages = {
      ":edudash.database/no-such-user": "Email not found.",
      ":edudash.database/wrong-password": "Incorrect password."
    };

    $scope.first = true;
    $scope.loggedIn = false;
    $scope.view = "login";

    $scope.credentials = {
      email: ENV.email,
      pass: ENV.pass
    };

    $scope.login = function(credentials) {
      return authService
        .login(credentials)
        .then(function(res) {
          if (res.data) {
            $modalInstance.close();
          }
          return res;
        })
        .catch(function(err, status) {
          $scope.first = false;
          $scope.failReason = failMessages[err];
          if (status === -1)
            $scope.failReason = "Connection to server couldn't be established.";
        });
    };

    $scope.logoSrc = require("~/img/logo-dark.png");

    $scope.showPasswordReset = function() {
      $scope.view = "show-reset";
    };

    $scope.showLogin = function() {
      $scope.view = "login";
    };

    $scope.resetPassword = function() {
      api.user.resetPassword({name: $scope.credentials.email});
      $scope.view = "email-sent";
    };
  }
]);
