import _ from "underscore";
import angular from "angular";
import c3 from "c3";

angular.module("helme.graphs").directive("scatterChart", [
  "formatService",
  "graphService",
  "$timeout",
  function(formatService, graphService, $timeout) {
    function formatData(x, y) {
      var xBounds = graphService.niceBounds([x.data]);
      var yBounds = graphService.niceBounds([y.data]);

      var xData = [x.label].concat(x.data);
      var yData = [y.label].concat(y.data);

      return {
        bounds: {
          x: xBounds,
          y: yBounds
        },
        data: {
          columns: [xData, yData],
          x: x.label,
          type: "scatter"
        }
      };
    }

    function go(scope, element) {
      var chartInfo = formatData(scope.data.x, scope.data.y);
      return c3.generate({
        bindto: element[0],
        data: chartInfo.data,
        legend: {
          show: !scope.minimal && !scope.hideLegend
        },
        axis: {
          y: {
            tick: {
              format:
                scope.type === "$$"
                  ? formatService.formatShortDollarValue
                  : formatService.formatFn(scope.type),
              values: [chartInfo.bounds.y[0], chartInfo.bounds.y[4]]
            },
            show: !scope.minimal,
            min: _.first(chartInfo.bounds.y[0]),
            max: _.last(chartInfo.bounds.y)
          },
          x: {
            show: !scope.minimal
          }
        },
        grid: {
          y: {
            lines: [
              {
                value: 0,
                text: ""
              }
            ]
          }
        },
        padding: {
          right: 22,
          left: 50
        }
      });
    }

    function link(scope, element, attribs) {
      var chart;
      $timeout(function() {
        if (!!scope.data) chart = go(scope, element);
      }, 100);
      scope.$on("tile-resize", function() {
        if (!!scope.data) {
          if (!chart) chart = go(scope, element);
          chart.resize();
        }
      });
      scope.$watch(
        "data",
        function(val, old) {
          if (val !== old) {
            if (!chart) {
              chart = go(scope, element);
            } else {
              chart.load({
                columns: formatData(scope.data.x, scope.data.y).data.columns
              });
            }
          }
        },
        true
      );
    }

    return {
      restrict: "E",
      replace: true,
      link: link,
      scope: {
        data: "=",
        type: "=?",
        minimal: "@",
        hideLegend: "@"
      },
      template: "<div class='c3-chart'> </div>"
    };
  }
]);
