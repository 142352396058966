import _ from "underscore";
import angular from "angular";
import c3 from "c3";

angular.module("helme.graphs").directive("groupedBarChart", [
  "sessionService",
  "graphService",
  "formatService",
  "$timeout",
  function(sessionService, graphService, formatService, $timeout) {
    function formatData(data) {
      var bounds = graphService.niceBounds(data.series, {min: 0});
      var cSeries = _.map(data.series, function(d, i) {
        return [data.legendLabels[i]].concat(d);
      });
      var axisLabels = ["x"].concat(data.axisLabels);

      var columns = cSeries.concat([axisLabels]);

      var c = ["#009688", "#0277bd", "#fb8c00"];
      // check if it's a development tile.
      if (
        "goal" === data.legendLabels[0] &&
        "given+pledged" === data.legendLabels[1] &&
        "given" === data.legendLabels[2]
      ) {
        c = ["#b2dfdb", "#26a69a", "#004d40"];
      }

      var colors = {};
      _.forEach(columns, function(column, i) {
        if (i < columns.length - 1) {
          colors[column[0]] = c[i];
        }
      });

      return {
        bounds: {
          y: bounds
        },
        data: {
          columns: columns,
          colors: colors,
          x: "x",
          type: "bar"
        }
      };
    }

    var containerWidth;

    function go(scope, element) {
      containerWidth = element[0].clientWidth;
      if (!scope.data) return {};
      var chartInfo = formatData(scope.data);
      return c3.generate({
        bindto: element[0],
        data: chartInfo.data,
        axis: {
          rotated: !!scope.rotated,
          x: {
            type: "category",
            tick: {
              count: 0
            }
          },
          y: {
            tick: {
              format: formatService.formatFn(scope.unit),
              values: chartInfo.bounds.y
            },
            min: _.first(chartInfo.bounds.y[0]),
            max: _.last(chartInfo.bounds.y)
          }
        },
        legend: {
          show: chartInfo.data.columns.length > 2
        },
        grid: {
          y: {
            lines: [
              {
                value: 0,
                text: ""
              }
            ]
          }
        },
        padding: {
          top: 5,
          right: 15
        }
      });
    }

    function link(scope, element, attribs) {
      var chart;
      $timeout(function() {
        chart = go(scope, element);
      }, 100);
      scope.$watch("data", function(val, old) {
        if (val !== old) {
          chart = go(scope, element);
        }
      });
      scope.$on("tile-resize", function() {
        if (chart) $timeout(chart.flush, 150);
      });
    }

    return {
      restrict: "E",
      replace: true,
      link: link,
      scope: {
        data: "=",
        unit: "=",
        config: "=",
        rotated: "@?"
      },
      template: "<div class='c3-chart'> <svg></svg> </div>"
    };
  }
]);
