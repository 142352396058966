import _ from "underscore";
import angular from "angular";

angular.module("helme.inspector").directive("inspector", [
  "$rootScope",
  "$state",
  "inspectService",
  "dataService",
  "sessionService",
  "tuitionService",
  "formatService",
  "collectionService",
  "ENV",
  "$modal",
  "ledger",
  "actualsService",
  "linkItemService",
  function(
    $rootScope,
    $state,
    inspectService,
    dataService,
    sessionService,
    tuitionService,
    formatService,
    collectionService,
    ENV,
    $modal,
    ledger,
    actualsService,
    linkItemService
  ) {
    function makeSelectRanges(start, end) {
      var startRange = _.map(_.range(0, end), function(i) {
        return {
          display: ENV.prettyYears[i],
          index: i
        };
      });
      startRange[0].display = "Current";

      var endRange = _.map(_.range(start + 1, 9), function(i) {
        return {
          display: ENV.prettyYears[i],
          index: i
        };
      });
      endRange[endRange.length - 1].display = "None";
      return {
        start: startRange,
        end: endRange
      };
    }

    function budgetChanged() {
      $rootScope.$broadcast("budget-changed");
    }

    function fill(coll, val) {
      for (var x = 0; x < coll.length; x++) coll[x] = val;
    }

    function setEndAssumptions(item, value) {
      var end = !!item.end ? item.end : 8;
      if (value > end) {
        var l = [];
        l.length = value - end;
        var f = item.assumptions.granular[item.assumptions.granular.length - 1];
        if (_.isUndefined(f)) f = item.value;
        fill(l, f);
        item.assumptions.granular = item.assumptions.granular.concat(l);
      } else if (value < end) {
        item.assumptions.granular = item.assumptions.granular.slice(
          0,
          value - 1
        );
      }
    }

    function setStartAssumptions(item, value) {
      var start = !!item.start ? item.start : 0;
      if (value < start) {
        var l = [];
        l.length = start - value;
        var f = item.assumptions.granular[0];
        if (_.isUndefined(f)) f = item.value;
        fill(l, f);
        item.assumptions.granular = l.concat(item.assumptions.granular);
      } else if (value > start) {
        item.assumptions.granular = item.assumptions.granular.slice(
          value - start,
          item.assumptions.granular.length
        );
      }
    }

    var growthOptions = [
      {
        id: "inherited",
        name: "Same as parent"
      },
      {
        id: "rate",
        name: "Percent Increase"
      }
    ];
    var itemGrowthOptions = [
      {
        id: "delta",
        name: "Dollar Increase"
      },
      {
        id: "granular",
        name: "Manually set"
      }
    ];

    return {
      link: function(scope, element, attribs) {
        scope.currentYear = ENV.currentYear;
        scope.prettyYears = ENV.prettyYears;

        function setRanges() {
          var start = !!scope.item.start ? scope.item.start : 0;
          var end = !!scope.item.end ? scope.item.end : 8;
          scope.ranges = makeSelectRanges(start, end);
          scope.startYear = scope.ranges.start[start];
          scope.endYear = scope.ranges.end[end - start - 1];
        }

        function show() {
          scope.viewOnly =
            $state.current.name === "students" &&
            tuitionService.mode.view === "tree";
          scope.item = inspectService.exports.selectedItem;
          scope.data = {
            series: [inspectService.exports.itemData],
            labels: [scope.item.name]
          };

          scope.actualsReport = actualsService.itemActualsReport(scope.item.id);
          scope.monthlyVisible = false;
          scope.showActuals = actualsService.isLinked(scope.item.id);

          scope.growthOptions = growthOptions;
          if (!scope.item.children)
            scope.growthOptions = scope.growthOptions.concat(itemGrowthOptions);
          scope.type = collectionService.type;
          setRanges();
        }

        scope.formatDollar = formatService.formatDollarValue;
        scope.monthName = function(index) {
          return [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "November",
            "December"
          ][index % 12];
        };

        scope.monthlyVisible = false;
        scope.showMonthlyActuals = function() {
          scope.monthlyVisible = true;
        };

        scope.yearRange = function(offset) {
          if (!!scope.item && scope.setRange) {
            var start = !!scope.item.start ? scope.item.start : 0;
            return ENV.prettyYears[start + offset];
          }
          return ENV.prettyYears;
        };

        scope.itemLinked = function(item) {
          return (
            item &&
            (item.link || (item.linking && item.linking.type !== "none"))
          );
        };
        scope.prettyLinkingType = linkItemService.prettyLinkingType;

        scope.showLinkModal = linkItemService.showLinkModal;

        scope.setNextChange = function() {
          if (!scope.item.next) {
            scope.item.next = dataService.getItem(
              scope.item.id,
              dataService.projection[1]
            ).value;
          } else {
            scope.item.next = null;
          }
        };

        scope.displayValue = inspectService.displayValue;

        scope.nameChange = function(item) {
          return function(value, old) {
            if (value !== old) {
              ledger.events.item.name.log(item, value, old);
              budgetChanged();
            }
          };
        };
        scope.valueChange = function(item) {
          return function(value, old) {
            if (value !== old) {
              ledger.events.item.value.current.log(item, value, old);
              budgetChanged();
            }
          };
        };
        scope.nextValueChange = function(item) {
          return function(value, old) {
            if (value !== old) {
              ledger.events.item.value.next.log(item, value, old);
              budgetChanged();
            }
          };
        };

        function showDateConfirmModal(item, field) {
          return $modal.open({
            templateUrl: "./date-confirm.html",
            controller: "DateConfirmCtrl",
            resolve: {
              item: function() {
                return item;
              },
              field: function() {
                return field;
              }
            },
            windowClass: "edudash-modal"
          });
        }

        scope.yearDisplay = function(year) {
          return year.display;
        };

        function doStartChange(item, secondTime) {
          if (!item.children) setStartAssumptions(item, scope.startYear.index);

          if (scope.startYear.index > 0) item.start = scope.startYear.index;
          else delete item["start"];

          if (!item.start || item.start > 0) delete item["next"];

          setRanges();

          if (!!item.children) {
            _.forEach(item.children, function(child) {
              doStartChange(child, false);
            });
          }

          if (!secondTime) doEndChange(item, true);
        }

        scope.startChange = function(value) {
          var old = angular.copy(scope.startYear);
          scope.startYear = value;
          if (!!scope.item.children) {
            var modalInstance = showDateConfirmModal(scope.item, "start");
            modalInstance.result.then(
              function() {
                doStartChange(scope.item, false);
                scope.inspectorClick();
                ledger.events.item.year.start.log(scope.item, value, old);
                $rootScope.$broadcast("push-state");
              },
              function() {
                scope.inspectorClick();
              }
            );
          } else {
            doStartChange(scope.item, false);
            ledger.events.item.year.start.log(scope.item, value, old);
            $rootScope.$broadcast("push-state");
          }
          budgetChanged();
        };

        function doEndChange(item, secondTime) {
          if (!item.children) setEndAssumptions(item, scope.endYear.index);

          if (scope.endYear.index < 8) item.end = scope.endYear.index;
          else delete item["end"];
          setRanges();

          if (!!item.children) {
            _.forEach(item.children, function(child) {
              doEndChange(child, false);
            });
          }

          if (!secondTime) doStartChange(item, true);
        }

        scope.endChange = function(value) {
          var old = angular.copy(scope.endYear);
          scope.endYear = value;
          if (!!scope.item.children) {
            var modalInstance = showDateConfirmModal(scope.item, "end");
            modalInstance.result.then(
              function() {
                doEndChange(scope.item, false);
                scope.inspectorClick();
                ledger.events.item.year.end.log(scope.item, value, old);
                $rootScope.$broadcast("push-state");
              },
              function() {
                scope.inspectorClick();
              }
            );
          } else {
            doEndChange(scope.item, false);
            ledger.events.item.year.end.log(scope.item, value, old);
            $rootScope.$broadcast("push-state");
          }
          budgetChanged();
        };

        scope.assumptionModeChange = function(value) {
          var item = scope.item;
          var oldMode = item.assumptions.mode.slice(0);
          item.assumptions.mode = value;
          if (item.assumptions.mode === "granular") {
            var start = !!item.start ? item.start : 0;
            var end = !!item.end ? item.end : 8;
            if (!item.assumptions.granular || start !== 0 || end !== 8)
              item.assumptions.granular = new Array(end - start - 1);
            item.assumptions.granular[0] = item.value;
            for (var x = 1; x < item.assumptions.granular.length; x++)
              if (oldMode === "delta")
                item.assumptions.granular[x] =
                  item.assumptions.granular[x - 1] + item.assumptions.delta;
              else
                item.assumptions.granular[x] =
                  item.assumptions.granular[x - 1] *
                  (1 + item.assumptions.rate / 100);
          } else if (
            item.assumptions.mode === "rate" &&
            !item.assumptions.rate
          ) {
            item.assumptions.rate = 3;
          }

          if (oldMode !== value) {
            ledger.events.item.assumptions.mode.log(scope.item, value, oldMode);
            budgetChanged();
          }
        };

        scope.growthDisplay = function(option) {
          return option.name;
        };

        scope.futureRangeStr = function() {
          return (
            ENV.prettyYears[(!!scope.item.start ? scope.item.start : 0) + 1] +
            " to " +
            ENV.prettyYears[!!scope.item.end ? scope.item.end : 8]
          );
        };

        scope.getStartYear = function() {
          return ENV.prettyYears[
            1 + (!!scope.item.start ? scope.item.start : 0)
          ];
        };

        scope.hide = function() {
          inspectService.deselect();
          scope.data = undefined;
        };

        scope.addNote = function(item) {
          item.note = "";
        };
        scope.removeNote = function(item) {
          delete item["note"];
        };

        scope.inspectorClick = inspectService.inspectorClick;
        scope.viewOnly = sessionService.isViewOnly();
        scope.format = function(value) {
          return formatService.formatDollarValue(value, false);
        };

        scope.$watch(
          function() {
            return inspectService.exports.itemData;
          },
          function(val, old) {
            if (val !== old && !!scope.item) {
              scope.data = {
                series: [inspectService.exports.itemData],
                labels: [scope.item.name]
              };
            }
          },
          true
        );
        scope.granularChange = function(index) {
          return function(val, old) {
            if (val !== old) {
              ledger.events.item.assumptions.granular.log(
                scope.item,
                val,
                old,
                index
              );
              budgetChanged();
            }
          };
        };

        scope.assumptionChange = function() {
          return function(val, old) {
            if (val !== old) {
              ledger.events.item.assumptions[scope.item.assumptions.mode].log(
                scope.item,
                val,
                old
              );
              budgetChanged();
            }
          };
        };

        scope.$on("item-select", show);
        scope.$on("$stateChangeStart", scope.hide);
        scope.$on("tab-switch", scope.hide);
      },
      restrict: "E",
      replace: true,
      templateUrl: "./inspector.html"
    };
  }
]);
