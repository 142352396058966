import _ from "underscore";
import angular from "angular";
import c3 from "c3";

angular.module("helme.graphs").directive("overviewChart", [
  "formatService",
  "graphService",
  "$timeout",
  "ENV",
  function(formatService, graphService, $timeout, ENV) {
    function isNonZero(coll) {
      return _.some(_.rest(coll), function(item) {
        return item !== 0;
      });
    }

    function formatData(income, expense, net) {
      var nExpense = _.map(expense, function(d) {
        return -1 * d;
      });
      var bounds = graphService.niceBounds([income, nExpense, net]);
      var cIncome = ["Income"].concat(income);
      var cExpense = ["Expense"].concat(nExpense);
      var cNet = ["Net"].concat(net);
      var years = ["years"].concat(
        _.range(
          ENV.currentYear % 2000,
          (ENV.currentYear + income.length) % 2000
        )
      );
      var columns = [cIncome, cExpense, cNet, years];

      if (!isNonZero(cIncome) && isNonZero(cExpense)) {
        bounds = graphService.niceBounds([expense]);
        var interval = bounds[bounds.length - 1] / 5;
        bounds = _.range(0, bounds[bounds.length - 1] + interval, interval);
        columns = [["Expense"].concat(expense), years];
      } else if (!isNonZero(cExpense) && isNonZero(cIncome)) {
        columns = [cIncome, years];
      } else if (!isNonZero(cExpense) && !isNonZero(cIncome)) {
        columns = [years];
      }

      return {
        bounds: {
          y: bounds
        },
        data: {
          columns: columns,
          x: "years",
          type: "bar",
          types: {
            Net: "line"
          },
          colors: {
            Income: "#0277bd",
            Expense: "#fb8c00",
            Net: "#009688"
          },
          groups: [["Expense", "Income"]]
        }
      };
    }

    function go(scope, element) {
      var chartInfo = formatData(
        scope.data.income,
        scope.data.expense,
        scope.data.net
      );
      return c3.generate({
        bindto: element[0],
        data: chartInfo.data,
        legend: {
          show: !scope.hideLegend
        },
        axis: {
          x: {
            show: !scope.hideAxes
          },
          y: {
            show: !scope.hideAxes,
            tick: {
              format: formatService.formatShortDollarValue,
              values: chartInfo.bounds.y
            },
            min: _.first(chartInfo.bounds.y[0]),
            max: _.last(chartInfo.bounds.y)
          },
          y2: {
            show: false,
            tick: {
              format: formatService.formatShortDollarValue,
              values: chartInfo.bounds.y
            },
            label: {
              text: "Net Income",
              position: "outer-middle"
            }
          }
        },
        grid: {
          y: {
            lines: [
              {
                value: 0,
                text: ""
              }
            ]
          }
        },
        padding: {
          top: 5
        }
      });
    }

    function link(scope, element, attribs) {
      var chart;
      $timeout(function() {
        if (!!scope.data) chart = go(scope, element);
      }, 100);
      scope.$on("tile-resize", function() {
        if (chart) $timeout(chart.flush, 150);
      });
      scope.$watch(
        "data",
        function(val, old) {
          if (val !== old && !!chart) {
            chart = go(scope, element);
          }
        },
        true
      );
    }

    return {
      restrict: "E",
      replace: true,
      link: link,
      scope: {
        data: "=",
        hideLegend: "@",
        hideAxes: "@",
        config: "="
      },
      template: "<div class='c3-chart'> </div>"
    };
  }
]);
