import _ from "underscore";
import angular from "angular";

angular.module("helme.pages").service("importService", [
  "$q",
  "api",
  "$modal",
  "$timeout",
  "ENV",
  "Session",
  function($q, api, $modal, $timeout, ENV, Session) {
    var service = {};

    service.exports = {
      onboarding: false
    };

    service.testConnection = function(sort) {
      return $q(function(resolve, reject) {
        testConnectionHelper(resolve, reject, sort);
      });
    };
    var testConnectionHelper = function(resolve, reject, sort) {
      api.intuit.test().then(function(res) {
        if (res.data.connected) {
          if (sort) {
            api.intuit.budget().then(
              function(res) {
                var m = service.sortItems(service.combineCategories(res.data));
                resolve(m);
              },
              function() {
                reject();
              }
            );
          } else {
            resolve();
          }
        } else {
          $timeout(function() {
            testConnectionHelper(resolve, reject, sort);
          }, 1000);
        }
      });
    };

    service.getIntuitConfig = function() {
      return {
        grantUrl:
          ENV.endpoint +
          "/" +
          Session.school._id +
          "/qbo/request-token?session-id=" +
          Session.id +
          "&close-url=https%3A%2F%2Fapp.helme.io%2Fclose.html",
        datasources: {
          quickbooks: true
        }
      };
    };

    service.setupButton = function(config, button) {
      if (window.intuit) {
        intuit.ipp.anywhere.controller.connectToIntuit.execute(button);
        intuit.ipp.anywhere.setup(config);
      }
    };

    service.setupIntuit = function() {
      var config = service.getIntuitConfig();

      return api.intuit.test().then(function(res) {
        var button = document.getElementById("intuit-connect");
        if (!res.data.connected) service.setupButton(config, button);
      });
    };

    service.quickbooksUpload = function(file) {
      if (service.excelInvalid(file)) {
        return false;
      }

      return $q(function(resolve, reject) {
        api.import
          .quickbooks(file)
          .then(function(res) {
            var m = service.sortItems(service.combineCategories(res.data));
            resolve(m);
          })
          .catch(function() {
            reject();
          });
      });
    };

    service.sortItems = function(items) {
      var windowClass = "helme-modal import-modal sorting-modal";
      if (service.exports.onboarding) windowClass += " wizard-import-modal";
      var m = $modal.open({
        controller: "itemSortCtrl",
        templateUrl: "./item-sort.html",
        windowClass: windowClass,
        resolve: {
          items: function() {
            return items;
          },
          onboarding: function() {
            return service.exports.onboarding;
          }
        }
      });
      return m;
    };

    service.excelUpload = function(file) {
      return $q(function(resolve, reject) {
        api.import.excel(file).then(function(res) {
          service.excelSort(res.data, resolve, reject);
        });
      });
    };

    service.excelSort = function(res, resolve, reject) {
      var windowClass = "helme-modal excel-import";
      if (service.exports.onboarding) windowClass += " wizard-import-modal";
      var m = $modal.open({
        controller: "excelImportCtrl",
        templateUrl: "./excel-import-modal.html",
        windowClass: windowClass,
        resolve: {
          workbook: function() {
            return res.workbook;
          }
        }
      });
      m.result.then(
        function(results) {
          var sortModal = service.sortItems(
            service.combineCategories({
              unknown: results
            })
          );
          resolve(sortModal);
        },
        function() {
          reject();
        }
      );
    };

    service.combineCategories = function(result) {
      var labelled = _.mapObject(result, function(coll, key) {
        return _.map(coll, function(item) {
          return [key, item];
        });
      });
      return _.reduce(
        _.values(labelled),
        function(acc, val) {
          return acc.concat(val);
        },
        []
      );
    };
    var xlsRegex = /^.*\.(xls|XLS|xlsx|XLSX)$/;
    service.excelInvalid = function(file) {
      return !file || !file.name.match(xlsRegex);
    };

    return service;
  }
]);
