import angular from "angular";

function getTabTotal(
  tabKey,
  tuitionService,
  collectionService,
  sessionService,
  formatService
) {
  if (tabKey === "students") {
    const net = tuitionService.getTotals().net;
    return formatService.formatDollarValue(net, false);
  } else {
    return collectionService.tabTotal(sessionService.mode.year, tabKey + "_0");
  }
}

angular.module("helme.navigation").directive("sideMenu", [
  "dataService",
  "pages",
  "sessionService",
  "authService",
  "Session",
  "saveService",
  "$state",
  "ENV",
  "$rootScope",
  "tuitionService",
  "collectionService",
  "formatService",
  function(
    dataService,
    pages,
    sessionService,
    authService,
    Session,
    saveService,
    $state,
    ENV,
    $rootScope,
    tuitionService,
    collectionService,
    formatService
  ) {
    return {
      restrict: "E",
      replace: true,
      scope: {},
      link: function(scope, element, attribs) {
        scope.tabTitle = dataService.tabTitle;
        scope.type = dataService.tabType;

        scope.expenseHidden = false;
        scope.incomeHidden = false;

        function setTabs() {
          scope.expenseTabs = pages.expense;
          scope.incomeTabs = pages.income;
        }

        scope.isDashboardViewer = function() {
          return !Session.loggedIn || Session.user.type === "viewer";
        };

        scope.isActive = function(stateName) {
          return (
            stateName === $state.current.name && !scope.isDashboardViewer()
          );
        };

        var icons = {
          dashboard: "tachometer",
          students: "users",
          analytics: "compass",
          settings: "cogs",
          school: "table",
          fees: "ticket",
          personnel: "user",
          "co-curricular": "paint-brush",
          curricular: "book",
          "other-income": "usd",
          "other-expenses": "usd",
          development: "building",
          operations: "car",
          advancement: "line-chart"
        };

        scope.tabIcon = function(tabKey) {
          return icons[tabKey];
        };

        setTabs();
        scope.$on("full-state-change", setTabs);

        scope.undo = sessionService.undo;
        scope.redo = sessionService.redo;

        scope.mode = sessionService.mode;

        scope.logout = function() {
          saveService.save();
          authService.logout();
        };

        scope.tabTotal = tab => {
          return getTabTotal(
            tab.key,
            tuitionService,
            collectionService,
            sessionService,
            formatService
          );
        };

        $rootScope.$on("$stateChangeStart", function(event, next) {
          if (window.innerWidth < 1080) scope.mode.drawerVisible = false;
        });
        $rootScope.$on("$stateChangeSuccess", function(event, next) {
          if (window.Headway) Headway.init();
          scope.mode.tabbarVisible = next.name !== "school";
        });
      },
      templateUrl: "./side-menu.html"
    };
  }
]);
