import _ from "underscore";
import angular from "angular";

angular.module("helme.wizard").controller("wizardCtrl", [
  "$scope",
  "wizard",
  "ENV",
  "Session",
  "api",
  "importService",
  function($scope, wizard, ENV, Session, api, importService) {
    $scope.wizard = wizard.exports;
    $scope.rateChange = wizard.rateChange;
    $scope.enrollmentChange = wizard.enrollmentChange;
    $scope.nextStep = function() {
      if ($scope.validateStep()) {
        $scope.wizard.step += 1;
        if ($scope.wizard.step === 3) {
          setupImportStep();
        }
        $scope.message = null;
        wizard.storeProgress();
      }
    };
    function setupImportStep() {
      $scope.qbLoading = false;
      $scope.uploadLoading = false;
      $scope.message = "";
      setupIntuit();
    }
    $scope.lastStep = function() {
      $scope.wizard.step -= 1;
      if ($scope.wizard.step === 3) setupIntuit();
    };
    $scope.import = wizard.import;
    $scope.startOnlineImport = function() {
      $scope.qbLoading = true;
      $scope.message = "";
      wizard.startOnlineImport().then(function(sortModal) {
        $scope.qbLoading = false;
        sortModal.result.then(function() {
          $scope.wizard.step = 4;
        });
      });
    };

    $scope.fileSelect = function(file, type) {
      var promise;
      if (type === "qb-desktop") promise = wizard.desktopUpload(file);
      else promise = wizard.excelUpload(file);

      if (!promise) {
        $scope.message = "Try again, file must be an excel spreadsheet.";
        return;
      }

      $scope.uploadLoading = true;
      $scope.message = "";
      promise.then(
        function(sortModal) {
          sortModal.result.then(
            function() {
              $scope.uploadLoading = false;
              $scope.nextStep();
            },
            function() {
              $scope.uploadLoading = false;
              $scope.message = "Import cancelled.";
            }
          );
        },
        function() {
          $scope.uploadLoading = false;
          if (type === "qb-desktop")
            $scope.message = "Import failed. Email support@helme.io.";
          else $scope.message = "Import cancelled.";
        }
      );
    };

    $scope.submit = function() {
      wizard.submit();
      $scope.$close();
    };

    $scope.validateStep = function() {
      if ($scope.wizard.step === 1) {
        if ($scope.wizard.user.name === "") {
          $scope.message = "Your name can't be empty.";
          return false;
        }
        if ($scope.wizard.user.name === "") {
          $scope.message = "Your title can't be empty.";
          return false;
        }
        if ($scope.wizard.school.name === "") {
          $scope.message = "School name field cannot be empty.";
          return false;
        }
        if (!$scope.wizard.school.year) {
          $scope.message = "Founding year cannot be empty.";
          return false;
        }
        if ($scope.wizard.school.address1 === "") {
          $scope.message = "Address cannot be empty.";
          return false;
        }
      } else if ($scope.wizard.step === 3) {
      } else if ($scope.wizard.step === 4) {
        if (!$scope.wizard.user.license) {
          $scope.message =
            "You must agree with the Terms of Use and Privacy Agreement to continue.";
          return false;
        }
      }

      return true;
    };

    $scope.gradesDisabled = function() {
      return _.some($scope.wizard.enrollment.classes, function(c) {
        return !c.enabled;
      });
    };

    var setupIntuit = importService.setupIntuit;
  }
]);
