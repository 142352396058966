import _ from "underscore";
import angular from "angular";

angular.module("helme.quickbooks").service("actualsService", [
  "$q",
  "api",
  "dataService",
  "sessionService",
  "qboMock",
  "actualsLinkService",
  "formatService",
  "metrics",
  function(
    $q,
    api,
    dataService,
    sessionService,
    qboMock,
    actualsLinkService,
    formatService,
    metrics
  ) {
    var service = {
      ready: $q.defer()
    };

    service.exports = {
      active: null
    };

    var fiscalStart = 17;

    service.getSortedAccounts = function() {
      if (
        !actualsLinkService.exports.accounts ||
        actualsLinkService.exports.accounts.length === 0
      ) {
        return [];
      }
      return _.chain(actualsLinkService.exports.accounts.slice(0))
        .map(function(account, index) {
          var s1 = ["Income", "Expense", "Bank"].indexOf(account.type);
          return {
            account: account,
            sortStr: s1 + " " + account.sublevel + " " + index
          };
        })
        .map(function(item, index, accounts) {
          if (item.account.parent) {
            var parent = _.find(accounts, function(a) {
              return a.account.id === item.account.parent;
            });
            return {
              account: item.account,
              sortStr: parent.sortStr + " " + index
            };
          }
          return item;
        })
        .sortBy(function(item) {
          return item.sortStr;
        })
        .pluck("account")
        .value();
    };

    service.getAllAccounts = function() {
      return actualsLinkService.exports.accounts;
    };

    service.getAccount = function(accountId) {
      return _.find(actualsLinkService.exports.accounts, function(account) {
        return account.id === accountId;
      });
    };

    function getAccountActuals(accountId) {
      // index 0 is January 1st, 2015
      var account = service.getAccount(accountId);
      if (!account) return [];
      return account.monthly;
    }

    service.getAccountName = function(accountId) {
      var account = service.getAccount(accountId);
      if (!account) return "";
      return account.name;
    };

    service.getSummedMonthlyActuals = function(helmeId) {
      var links = actualsLinkService.getHelmeLinks(helmeId);
      var actualsList = _.map(links, function(link) {
        return service.getMonthlyActuals(link.account);
      });
      return _.reduce(actualsList, function(acc, actuals) {
        _.forEach(actuals, function(a) {
          acc[a.index] += a.value;
        });
        return acc;
      });
    };

    service.getSummedYtdActual = function(helmeId) {
      var links = actualsLinkService.getHelmeLinks(helmeId);
      var ytd = _.map(links, function(link) {
        return service.getYtdActual(link.account);
      });
      return _.reduce(
        ytd,
        function(acc, actual) {
          return acc + actual;
        },
        0
      );
    };

    service.getMonthlyActuals = function(accountId) {
      var actuals = getAccountActuals(accountId);

      return _.filter(actuals, function(actual) {
        return actual.index > fiscalStart;
      });
    };

    service.getYtdActual = function(accountId) {
      var monthlyActuals = service.getMonthlyActuals(accountId);
      if (monthlyActuals.length > 0) {
        return _.reduce(
          monthlyActuals,
          function(acc, actual) {
            return acc + actual.value;
          },
          0
        );
      }
      var account = service.getAccount(accountId);
      if (account) {
        return account.type === "Expense" ? -1 * account.value : account.value;
      }
      return 0;
    };

    service.pullActualsSummary = function() {
      return api.intuit.actualsSummary().then(
        function(res) {
          // dataService.state.modules.pandl.actual.ytd = res.data.ytd;
        },
        function(err) {}
      );
    };

    service.itemActualsReport = function(id, type) {
      var links = service.getLinks(id);
      if (!links || links.length === 0) {
        return {
          linked: false
        };
      }

      var accounts = links.map(function(link) {
          return service.getAccount(link.account);
        }),
        accounts = accounts.filter(function(a) {
          return !!a;
        }),
        incomeAccounts = _.filter(accounts, function(a) {
          return a.type === "Income";
        }),
        expenseAccounts = _.filter(accounts, function(a) {
          return a.type === "Expense";
        }),
        ytd = service.getSummedYtdActual(id),
        monthly = service.getSummedMonthlyActuals(id),
        budgeted = 0;
      if (type === "tag") {
        budgeted = metrics.tagOverview(id).net[0];
      } else {
        var item = dataService.getItem(id, dataService.projection[0]);
        budgeted = item ? item.value : 0;
      }
      var percentRaw = Math.abs(ytd) / Math.abs(budgeted),
        percent =
          budgeted === 0 ? "N/A" : formatService.formatPercentage(percentRaw),
        percentPreview =
          percent + " of " + formatService.formatShortDollarValue(budgeted),
        ytdPreview =
          formatService.formatShortDollarValue(ytd) +
          " YTD (" +
          percentPreview +
          ")",
        preview =
          monthly.length > 0
            ? ytdPreview +
              ", " +
              formatService.formatShortDollarValue(_.last(monthly).value) +
              " this month."
            : ytdPreview;

      return {
        linked: true,
        accounts: accounts,
        incomeAccounts: incomeAccounts,
        expenseAccounts: expenseAccounts,
        ytd: ytd,
        budgeted: budgeted,
        monthly: monthly,
        preview: preview,
        percentPreview: percentPreview,
        percent: percent,
        percentRaw: percentRaw * 100,
        ytdPreview: ytdPreview
      };
    };

    //passthrough methods for actualsLinkService
    service.getLinks = actualsLinkService.getHelmeLinks;
    service.showList = actualsLinkService.showList;
    service.linkPossible = actualsLinkService.linkPossible;
    service.fetchAccounts = actualsLinkService.fetchAccounts;
    service.linkAccount = function(itemId, type) {
      actualsLinkService.addActiveLink(itemId, type);
    };
    service.unlinkAccount = function(itemId, type) {
      actualsLinkService.removeActiveLink(itemId, type);
    };

    service.isLinked = function(helmeId) {
      return actualsLinkService.getHelmeLinks(helmeId).length > 0;
    };

    service.linkingActive = function() {
      return actualsLinkService.exports.active;
    };

    function setup() {
      api.intuit.test().then(
        function(res) {
          service.exports.intuitStatus = "connected";
          service.pullActualsSummary();
        },
        function() {
          service.exports.intuitStatus = "unconnected";
        }
      );
      service.ready.resolve();
    }

    sessionService.ready.promise.then(function() {
      actualsLinkService.ready.promise.then(setup);
    });

    return service;
  }
]);
