import angular from "angular";

angular.module("helme.input", []).service("confirmDialog", [
  "$modal",
  "ENV",
  function($modal, ENV) {
    return {
      open: function(config) {
        return $modal.open({
          controller: "ConfirmDialogCtrl",
          templateUrl: "./confirm-dialog.html",
          windowClass: "helme-modal wide-modal",
          keyboard: false,
          resolve: {
            config: function() {
              return config;
            }
          }
        });
      }
    };
  }
]);
