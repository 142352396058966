import _ from "underscore";
import angular from "angular";

angular.module("helme.whatif", []).service("whatif", [
  "$rootScope",
  "sessionService",
  "dataService",
  "ledger",
  "replayService",
  "guid",
  "$modal",
  "ENV",
  "notify",
  "confirmDialog",
  "api",
  "formatService",
  function(
    $rootScope,
    sessionService,
    dataService,
    ledger,
    replayService,
    guid,
    $modal,
    ENV,
    notify,
    confirmDialog,
    api,
    formatService
  ) {
    var service = {
      exports: {
        list: [],
        current: null,
        eventCount: 0
      }
    };

    sessionService.ready.promise.then(function() {
      api.scenario.getList().then(
        function(res) {
          service.exports.list = res.data;
        },
        function() {}
      );
    });

    service.eventCount = function() {
      return ledger.exports.list.length - ledgerStart;
    };

    service.inProgress = function() {
      return !!service.exports.current;
    };

    function makeScenario() {
      return {
        events: [],
        _id: guid.make(),
        name: "New Scenario",
        modified: new Date().getTime(),
        metrics: ["overview"]
      };
    }

    function updateScenario(scenario) {
      var ledgerEnd = ledger.exports.list.length;
      var len = ledgerEnd - ledgerStart;
      var events = ledger.exports.list.slice(0, len);

      scenario.events = events;
      scenario.modified = new Date().getTime();
    }

    var ledgerStart, initialBudget, initialProjection;
    service.start = function() {
      var name = "New Scenario";
      var modal = confirmDialog.open({
        title: "Scenario name?",
        type: "text",
        value: name
      });
      modal.result.then(
        function(val) {
          service.exports.current = makeScenario();
          lastSaved = angular.copy(service.exports.current);
          service.exports.current.name = val;
          ledgerStart = ledger.exports.list.length;
          initialBudget = angular.copy(dataService.state);
          initialProjection = angular.copy(dataService.projection);
          $rootScope.$broadcast("what-if-start");
        },
        function() {}
      );
    };

    service.resume = function(scenario) {
      if (sessionService.mode.whatIf) return;
      service.exports.current = scenario;
      lastSaved = angular.copy(service.exports.current);
      ledgerStart = ledger.exports.list.length;
      initialBudget = angular.copy(dataService.state);
      initialProjection = angular.copy(dataService.projection);
      ledger.exports.list = scenario.events.concat(ledger.exports.list);
      replayService.replay(scenario.events.slice(0).reverse());
      $rootScope.$broadcast("what-if-start");
    };

    service.viewCurrent = function() {
      updateScenario(service.exports.current);
      var states = {
        after: {
          budget: angular.copy(dataService.state),
          projection: angular.copy(dataService.projection)
        },
        before: {
          budget: initialBudget,
          projection: initialProjection
        }
      };
      service.viewSummary(service.exports.current, states);
    };

    service.review = function(scenario) {
      if (sessionService.mode.whatIf) return;
      sessionService.mode.whatIf = true;

      var before = {
        budget: angular.copy(dataService.state),
        projection: angular.copy(dataService.projection)
      };

      replayService.replay(scenario.events.slice(0).reverse());
      var projection = helmelib.core.projectBudget(dataService.state, 7);
      _.map(projection, dataService.fillClassTree);
      _.forEach(projection, function(state, i) {
        state.reserve += dataService.setLinkValues(state);
      });

      var after = {
        budget: angular.copy(dataService.state),
        projection: projection
      };

      var summaryModal = service.viewSummary(
        scenario,
        {
          before: before,
          after: after
        },
        "reviewing"
      );
      if (!!summaryModal) {
        summaryModal.result.then(function() {
          dataService.state = before.budget;
          $rootScope.$broadcast("full-state-change");
          sessionService.mode.whatIf = false;
          $rootScope.$broadcast("budget-changed");
        });
      }
    };

    service.summaryModalOpen = false;
    var summaryModal;
    service.viewSummary = function(scenario, states, status) {
      var changes = scenarioChanges(
        scenario,
        states.before.budget,
        states.after.budget
      );
      //

      service.summaryModalOpen = true;
      summaryModal = $modal.open({
        controller: "SummaryCtrl",
        templateUrl: "./summary-modal.html",
        windowClass: "helme-modal full-modal summary-modal",
        keyboard: false,
        resolve: {
          changes: function() {
            return changes;
          },
          scenario: function() {
            return scenario;
          },
          states: function() {
            return states;
          },
          status: function() {
            return status;
          }
        }
      });
      $rootScope.$broadcast("show-whatif-summary-modal");
      summaryModal.result.then(
        function(res) {
          if (res.reason === "close") {
            if (status === "reviewing") {
              service.save(res.scenario);
            }
          }
          if (res.reason === "discard") {
            service.discard(res.scenario);
            notify({
              message: "Scenario discarded.",
              position: "right",
              duration: 1500
            });
          }
          if (res.reason === "save") {
            var modal = service.save(res.scenario);
          }
          service.summaryModalOpen = false;
        },
        function() {
          service.summaryModalOpen = false;
        }
      );
      return summaryModal;
    };

    service.closeSummaryModal = function() {
      return summaryModal.close();
    };

    function endWhatIf() {
      var count = ledger.exports.list.length - ledgerStart;
      var whatifEvents = JSON.parse(
        JSON.stringify(ledger.exports.list.slice(0, count))
      );
      _.forEach(whatifEvents, function(event) {
        event.inverse = true;
      });
      replayService.replay(whatifEvents);
      if (service.summaryModalOpen) {
        ledger.exports.list = _.last(ledger.exports.list, ledgerStart);
      }
      service.exports.current = null;
      $rootScope.$broadcast("what-if-finish");
    }

    var lastSaved;
    service.quit = function(saveOverride) {
      if (
        lastSaved.events.length === service.exports.current.events.length &&
        lastSaved.name === service.exports.current.name
      ) {
        endWhatIf();
        return;
      }
      if (saveOverride) {
        service.save();
        endWhatIf();
        return;
      }
      var modal = confirmDialog.open({
        title: "You have unsaved changes!",
        bodyText: "Save '" + service.exports.current.name + "' before quiting?",
        type: "bool",
        options: ["Save", "Don't save"]
      });

      modal.result.then(
        function() {
          service.save();
          endWhatIf();
        },
        function() {
          endWhatIf();
        }
      );
    };

    service.save = function(scenario) {
      if (!scenario) {
        scenario = service.exports.current;
        updateScenario(scenario);
      }
      if (
        !_.some(service.exports.list, function(s) {
          return s._id === scenario._id;
        })
      ) {
        service.exports.list.push(scenario);
      } else {
        //
      }
      api.scenario.upsert(scenario);
      //
      lastSaved = angular.copy(scenario);
      notify({
        message: "Scenario saved in School Data -> Scenarios.",
        position: "right",
        duration: 3000
      });
    };

    service.discard = function(scenario) {
      if (sessionService.mode.whatIf) return;
      service.exports.list = _.filter(service.exports.list, function(s) {
        return s._id !== scenario._id;
      });
      api.scenario.remove(scenario);
    };

    service.apply = function(scenario) {
      replayService.replay(scenario.events);
      ledger.exports.list = scenario.events.concat(ledger.exports.list);
      service.exports.list = _.filter(service.exports.list, function(s) {
        return s._id !== scenario._id;
      });
      api.scenario.remove(scenario);
    };

    function eventAdd(coll, itemBefore, itemAfter, event) {
      var diff = itemDiff(itemBefore, itemAfter);
      if (!coll[itemAfter.id]) {
        coll[itemAfter.id] = {
          item: {
            after: itemAfter,
            before: itemBefore
          },
          diff: diff,
          events: [event]
        };
      } else {
        coll[itemAfter.id].events.push(event);
        coll[itemAfter.id].diff = diff;
      }
    }

    /* function itemDiff
       Takes in two items, returns:
     {
       before: [{name: "start", value: null},
                {name: "assumptions", value: {mode: "inherited"}}],
       after: [{name: "start", value: 2018},
               {name: "assumptions", value: {mode: "rate", rate: 3}}]
     }
     */

    function prettyAssumption(assumptions) {
      var v = assumptions[assumptions.mode];
      if (assumptions.mode === "rate") {
        return (
          formatService.formatPercentage(assumptions.rate / 100) + " annually"
        );
      }
      if (assumptions.mode === "granular") {
        return (
          "[" +
          _.map(
            assumptions.granular,
            formatService.formatShortDollarValue
          ).join(", ") +
          "]"
        );
      }
      if (assumptions.mode === "delta") {
        return (
          formatService.formatShortDollarValue(assumptions.delta) + " annually"
        );
      }
      return "Inherited";
    }

    function prettyLinking(linking) {
      if (!linking || linking.type === "none") return "None";
      if (linking.type === "tuition-gross")
        return (
          "Tuition x " +
          _.map(linking.multiples, formatService.formatPercentage).join(", ")
        );
      return _.map(
        linking.multiples,
        formatService.formatShortDollarValue
      ).join(", ");
    }

    var diffFormat = function(key, value) {
      if (key === "value" || key === "next") {
        if (value === undefined) return "N/A";
        return formatService.formatDollarValue(value);
      }
      if (key === "start" || key === "end") {
        if (value === undefined) return "None";
        return ENV.prettyYears[value];
      }
      return value;
    };

    function itemDiff(before, after) {
      var diffKeys,
        beforeDiff,
        afterDiff,
        allKeys = ["value", "name", "start", "end", "next"];

      // If this is an added item, set before to {}
      if (!before) {
        before = {};
      }
      if (!after) {
        return {
          before: [],
          after: []
        };
      }

      diffKeys = _.filter(allKeys, function(currentKey) {
        return !_.isEqual(before[currentKey], after[currentKey]);
      });

      beforeDiff = _.map(diffKeys, function(k) {
        return {
          name: k,
          value: diffFormat(k, before[k])
        };
      });
      afterDiff = _.map(diffKeys, function(k) {
        return {
          name: k,
          value: diffFormat(k, after[k])
        };
      });

      // if they're the same, use that mode as the value. Name is growth.
      //
      if (
        !_.isEmpty(before) &&
        !Object.compare(before.assumptions, after.assumptions)
      ) {
        var beforeAssumptionDiff = {
          name: "Growth",
          value: prettyAssumption(before.assumptions)
        };
        beforeDiff.push(beforeAssumptionDiff);
        var afterAssumptionDiff = {
          name: "Growth",
          value: prettyAssumption(after.assumptions)
        };
        afterDiff.push(afterAssumptionDiff);
      }

      if (!Object.compare(before.linking, after.linking)) {
        var beforeLinkingDiff = {
          name: "Linking",
          value: prettyLinking(before.linking)
        };
        beforeDiff.push(beforeLinkingDiff);
        var afterLinkingDiff = {
          name: "Linking",
          value: prettyLinking(after.linking)
        };
        afterDiff.push(afterLinkingDiff);
      }

      return {
        before: beforeDiff,
        after: afterDiff
      };
    }

    function printEvents(events) {
      return JSON.stringify(events);
      var str = "";
      _.forEach(events, function(event) {
        str += event.id + " --- " + event.params.itemId + "\n";
      });
      return str + "\n";
    }

    function scenarioChanges(scenario, budgetBefore, budgetAfter) {
      var itemBefore,
        itemAfter,
        added = {},
        removed = {},
        changed = {};

      //handle case where item gets added then removed.
      //
      _.forEach(scenario.events.slice(0).reverse(), function(event) {
        if (event.id === "add-item" || event.id === "add-category") {
          itemBefore = dataService.getItem(event.params.itemId, budgetBefore);
          itemAfter = dataService.getItem(event.params.itemId, budgetAfter);
          if (itemAfter) eventAdd(added, itemBefore, itemAfter, event);
        } else if (event.id === "remove-item") {
          if (!!added[event.params.item.id]) {
            delete added[event.params.item.id];
            return;
          }
          if (!!changed[event.params.item.id])
            delete changed[event.params.item.id];
          eventAdd(removed, event.params.item, event.params.item, event);
        } else if (!!event.params.itemId) {
          itemBefore = dataService.getItem(event.params.itemId, budgetBefore);
          itemAfter = dataService.getItem(event.params.itemId, budgetAfter);
          if (itemAfter) {
            if (!!added[itemAfter.id])
              eventAdd(added, itemBefore, itemAfter, event);
            else eventAdd(changed, itemBefore, itemAfter, event);
          }
        } else if (!!event.params.item) {
          if (!!added[itemAfter.id])
            eventAdd(added, itemBefore, itemAfter, event);
          else eventAdd(changed, itemBefore, itemAfter, event);
        }
      });

      return {
        added: added,
        removed: removed,
        changed: changed
      };
    }

    return service;
  }
]);

Object.compare = function(obj1, obj2) {
  //Loop through properties in object 1
  for (var p in obj1) {
    //Check property exists on both objects
    if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

    switch (typeof obj1[p]) {
      //Deep compare objects
      case "object":
        if (!Object.compare(obj1[p], obj2[p])) return false;
        break;
      //Compare function code
      case "function":
        if (
          typeof obj2[p] == "undefined" ||
          (p != "compare" && obj1[p].toString() != obj2[p].toString())
        )
          return false;
        break;
      //Compare values
      default:
        if (obj1[p] != obj2[p]) return false;
    }
  }

  if ((obj1 && !obj2) || (obj2 && !obj1)) return false;
  //Check object 2 for any extra properties
  for (var p in obj2) {
    if (typeof obj1[p] == "undefined") return false;
  }
  return true;
};
