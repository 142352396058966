import _ from "underscore";
import angular from "angular";

angular.module("helme.pages").service("userService", [
  "$rootScope",
  "api",
  "$q",
  "$modal",
  "ENV",
  function($rootScope, api, $q, $modal, ENV) {
    var service = {};

    service.ready = $q.defer();

    service.exports = {
      list: [],
      current: {}
    };

    function setup() {
      api.user.list().then(function(res) {
        service.exports.list = res.data.list;
        service.ready.resolve();
      });
    }

    service.userTypes = [
      {
        name: "Administrator",
        key: "admin"
      },
      {
        name: "Dashboard Viewer",
        key: "viewer"
      }
    ];

    service.verifyEmail = function() {};

    service.create = function() {
      var modalInstance = $modal.open({
        controller: "CreateUserCtrl",
        templateUrl: "./create-user-modal.html",
        windowClass: "helme-modal"
      });
      modalInstance.result.then(
        function(user) {
          if (user) service.exports.list.push(user);
        },
        function() {}
      );
    };

    service.edit = function(user) {
      var modalInstance = $modal.open({
        controller: "EditUserCtrl",
        templateUrl: "./edit-user-modal.html",
        windowClass: "helme-modal",
        resolve: {
          user: function() {
            return user;
          }
        }
      });

      modalInstance.result.then(
        function(edited) {
          user.type = edited.type;
        },
        function() {}
      );
    };

    service.remove = function(user) {
      service.exports.list = _.without(service.exports.list, user);
      api.user.remove(user);
    };

    setup();
    $rootScope.$on("auth-login-success", setup);

    return service;
  }
]);
