import angular from "angular";

angular.module("helme.inspector").controller("DateConfirmCtrl", [
  "$scope",
  "item",
  "field",
  function($scope, item, field) {
    $scope.item = item;
  }
]);
