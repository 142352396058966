import _ from "underscore";
import angular from "angular";

angular.module("helme.whatif").controller("SummaryCtrl", [
  "$timeout",
  "$scope",
  "sessionService",
  "dataService",
  "tuitionService",
  "whatif",
  "tileService",
  "ledger",
  "metrics",
  "formatService",
  "changes",
  "scenario",
  "states",
  "status",
  "ENV",
  "exportService",
  "Session",
  function(
    $timeout,
    $scope,
    sessionService,
    dataService,
    tuitionService,
    whatif,
    tileService,
    ledger,
    metrics,
    formatService,
    changes,
    scenario,
    states,
    status,
    ENV,
    exportService,
    Session
  ) {
    $scope.read = ledger.readEvent;
    $scope.changes = changes;
    $scope.scenario = scenario;
    $scope.values = _.values;
    $scope.view = "line-items";
    $scope.currentYear = ENV.currentYear;
    $scope.prettyYears = ENV.prettyYears;
    $scope.prettyYears = ENV.prettyYears;
    $scope.sessionMode = sessionService.mode;
    $scope.range = _.range;
    $scope.status = status;
    $scope.formatDollar = formatService.formatDollarValue;
    $scope.overviewDiff = metrics.overviewDiff;
    $scope.metricLibrary = metrics.library;
    $scope.school = Session.school;

    $scope.formatPercentage = function(s) {
      return s + "%";
    };
    $scope.formatByMetricKey = function(v, k, full) {
      if (k === "Hard Income") return formatService.formatPercentage(v);
      if (k === "Enrollment") return v;
      return formatService.formatDollarValue(v, !full);
    };

    $scope.downloadReport = function() {
      $scope.creatingReport = true;
      exportService.exportCurrent("summary").then(function() {
        $scope.creatingReport = false;
      });
    };

    $scope.metricDisplay = function(metric) {
      return metric.name;
    };

    var expandedChanges = {};
    $scope.showEvents = function(change) {
      expandedChanges[change._id] = true;
    };
    $scope.hideEvents = function(change) {
      expandedChanges[change._id] = false;
    };
    $scope.eventsVisible = function(change) {
      return expandedChanges[change._id];
    };

    function getPrettyPath(item, version) {
      var budget = states[version].budget;
      var path = dataService.findItemPath(item.id, budget);
      return _.pluck(path, "name").join(" -> ");
    }

    var prettyPaths = {};
    $scope.pathDisplay = function(item, version) {
      if (prettyPaths[item.id]) return prettyPaths[item.id];
      prettyPaths[item.id] = getPrettyPath(item, version);
    };

    $scope.selectView = function(view) {
      $scope.view = view;
    };

    $scope.viewActive = function(view) {
      if ($scope.creatingReport)
        return _.contains(
          ["line-items", "enrollment", "tuition-rates", "overview"],
          view
        );
      return $scope.view === view;
    };

    $scope.enrollment = function(classIndex, yearIndex) {
      return {
        before: tuitionService.getEnrollment(
          classIndex,
          yearIndex,
          states.before.budget
        ),
        after: tuitionService.getEnrollment(
          classIndex,
          yearIndex,
          states.after.budget
        )
      };
    };

    $scope.enrollmentChanged = function(classIndex, yearIndex) {
      var e = $scope.enrollment(classIndex, yearIndex);
      return e.before !== e.after;
    };

    function enrollmentTotals() {
      return {
        before: _.map(states.before.projection, metrics.enrollmentTotal),
        after: _.map(states.after.projection, metrics.enrollmentTotal)
      };
    }

    $scope.noteBlur = function() {};

    $scope.showTab = function(tab) {
      if (tab === "line-items") {
        return (
          _.values($scope.changes.added).length > 0 ||
          _.values($scope.changes.removed).length > 0 ||
          _.values($scope.changes.changed).length > 0
        );
      }
      if (tab === "enrollment") {
        $scope.enrollmentTotals = enrollmentTotals();
        for (
          var classIndex = 0;
          classIndex < $scope.classes.before.length;
          classIndex++
        ) {
          for (var yearIndex = 0; yearIndex < 8; yearIndex++) {
            if (
              tuitionService.getEnrollment(
                classIndex,
                yearIndex,
                states.before.budget
              ) !==
              tuitionService.getEnrollment(
                classIndex,
                yearIndex,
                states.after.budget
              )
            )
              return true;
          }
        }
        return false;
      }
      if (tab === "tuition-rates") {
        for (
          var classIndex = 0;
          classIndex < $scope.classes.before.length;
          classIndex++
        ) {
          for (var yearIndex = 0; yearIndex < 8; yearIndex++) {
            if (
              $scope.classes.before[classIndex].item.rate !==
                $scope.classes.after[classIndex].item.rate ||
              $scope.classes.before[classIndex].assumptions.rate[yearIndex] !==
                $scope.classes.after[classIndex].assumptions.rate[yearIndex]
            )
              return true;
          }
        }
        return false;
      }

      return true;
    };

    function setup() {
      $scope.scenarioOverview = $scope.overviewDiff(
        states.before,
        states.after
      );
      $scope.classes = {
        before: states.before.budget.tuition.classes,
        after: states.after.budget.tuition.classes
      };
      $scope.resolvedTiles = [];

      if (!scenario.notes) scenario.notes = "";
    }
    setup();
  }
]);
