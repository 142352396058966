import _ from "underscore";
/* Manages links between Quickbooks Accounts and Helme items. 

 Provides interface to:
   1. Check if an item is linked.
   2. Get an item's corresponding quickbooks link.
   3. Add a link to a Helme item or tag
   4. Remove a link to a Helme item or tag
   5. List all Quickbook items that are not linked
   6. Check if links have been broken (either by deleted tag, deleted item, or missing quickbooks account)

 Link schema:
   {
     type: [item | tag]
     helme: :id //id of helme item
     account: :id //id of quickbooks account (or name if desktop)
   }
============================================================ */

import angular from "angular";

angular.module("helme.quickbooks", []).service("actualsLinkService", [
  "$q",
  "Session",
  "$modal",
  "ENV",
  "sessionService",
  "dataService",
  "qboMock",
  "tagService",
  "api",
  function(
    $q,
    Session,
    $modal,
    ENV,
    sessionService,
    dataService,
    qboMock,
    tagService,
    api
  ) {
    var service = {
      ready: $q.defer()
    };

    service.exports = {};

    service.getHelmeLinks = function(helmeId) {
      return _.filter(service.exports.links, function(link) {
        return link.helme === helmeId;
      });
    };

    service.getQuickbooksLinks = function(accountId) {
      return _.filter(service.exports.links, function(link) {
        return link.account === accountId;
      });
    };

    //consider changing quickbooks -> outside / provider / external
    function addLink(type, account, helme) {
      service.exports.links.push({
        type: type,
        account: account,
        helme: helme
      });
    }

    service.addTagLink = function(account, helme) {
      addLink("tag", account, helme);
    };

    service.addItemLink = function(account, helme) {
      addLink("item", account, helme);
    };

    service.select = function(account) {
      service.exports.active = account;
    };

    service.addActiveLink = function(helmeId, type) {
      addLink(type, service.exports.active.id, helmeId);
    };

    service.removeLink = function(account, helme) {
      var length = service.exports.links.length;
      service.exports.links = _.filter(service.exports.links, function(link) {
        return link.helme !== helme && link.account !== account;
      });
      return service.exports.links.length < length;
    };

    service.removeActiveLink = function(helmeId) {
      service.removeLink(service.exports.active.id, helmeId);
    };

    service.accountsWithLinks = function() {
      return _.map(service.exports.accounts, function(account) {
        return Object.assign({}, account, {
          links: service.getQuickbooksLinks(account.id)
        });
      });
    };

    service.linkCount = function() {
      return service.exports.links.length;
    };

    // probably want to cache this.
    service.getBrokenLinks = function() {
      return _.filter(function(link) {
        var item, account;

        if (link.type === "item") item = dataService.getItem(link.helme);
        else {
          item = _.find(tagService.tags, function(tag) {
            return tag === link.helme;
          });
        }

        account = _.find(service.exports.accounts, function(account) {
          return account.id == link.account;
        });

        return !(item && account);
      });
    };

    service.showList = function() {
      if (sessionService.mode.linkingActuals) return;
      sessionService.mode.linkingActuals = true;
      return $modal
        .open({
          controller: "actualsAccountLinkCtrl",
          templateUrl: "./actuals-link-panel.html",
          backdrop: false,
          windowClass: "helme-modal actuals-panel-modal",
          keyboard: false,
          resolve: {}
        })
        .result.then(function() {
          sessionService.mode.linkingActuals = false;
        });
    };

    service.linkPossible = function(itemId) {
      var activeAccount = service.exports.active,
        links = service.getHelmeLinks(itemId);
      if (!activeAccount) return false;
      var f = !_.find(links, function(link) {
        return link.account === activeAccount.id;
      });

      return f;
    };

    service.syncBankAccounts = function(accounts) {
      if (!Session.school.sync.syncBank) return;
      var bankAccounts = accounts.filter(function(account) {
        return account.type === "Bank";
      });
      _.forEach(bankAccounts, function(account) {
        var matchedAccount = _.find(
          dataService.state.modules.accounts.cash,
          function(a) {
            return a.synced && a.name === account.name;
          }
        );
        if (matchedAccount) {
          matchedAccount.value = account.value;
        } else {
          dataService.state.modules.accounts.cash.push({
            synced: true,
            name: account.name,
            value: account.total
          });
        }
      });
    };
    function processSubAccounts(parent, accountList) {
      _.forEach(accountList, function(account) {
        if (account.parent === parent.id) {
          if (!parent.children) parent.children = [];
          parent.children.push(account.id);
          account.parent = parent.id;
          account.fullname = account.fullname.substr(
            account.fullname.indexOf(":") + 1
          );
        }
      });
    }

    function receiveAccounts(cb, res) {
      if (res.data.success) {
        dataService.state.modules.actuals.accounts = res.data.accounts;
      }
      service.exports.accounts = dataService.state.modules.actuals.accounts;
      service.syncBankAccounts(service.exports.accounts);
      cb(res.data.success ? "success" : "qb-desktop-failure");
    }

    function receiveOnlineAccounts(cb, res) {
      if (res.data.success) {
        var accounts = res.data.success ? res.data.accounts : [];

        accounts = _.map(accounts, function(account) {
          return {
            id: "QBO-" + account.Id,
            name: account.Name,
            classification: account.Classification,
            active: account.Active,
            value: account.CurrentBalance,
            total: account.CurrentBalance,
            type: account.AccountType,
            fullname: account.FullyQualifiedName,
            parent: account.ParentRef
              ? "QBO-" + account.ParentRef.value
              : undefined
          };
        });
        _.forEach(accounts, function(a) {
          processSubAccounts(a, accounts);
        });
        accounts.sort(function(a, b) {
          return !!a.children - !!b.children;
        });
        dataService.state.modules.actuals.accounts = accounts;
        service.exports.accounts = dataService.state.modules.actuals.accounts;

        service.syncBankAccounts(service.exports.accounts);
      } else
        service.exports.accounts = dataService.state.modules.actuals.accounts;
      cb(res.data.success ? "success" : "qb-desktop-failure");
    }

    service.setup = function() {
      service.fetchAccounts(function(success) {
        service.ready.resolve();
      });
    };

    service.fetchAccounts = function(cb) {
      var wrappedCb = wrapFetchCb(cb);

      service.exports.accounts = dataService.state.modules.actuals.accounts;
      service.exports.links = dataService.state.modules.actuals.links;

      if (Session.school.sync.type === "qb-desktop") {
        return api.accounts
          .get()
          .then(receiveAccounts.bind(null, wrappedCb), function() {
            wrappedCb("qb-desktop-failure");
          });
      } else if (Session.school.sync.type === "qb-online") {
        return api.intuit
          .accounts()
          .then(receiveOnlineAccounts.bind(null, wrappedCb), function() {
            wrappedCb("qb-online-failure");
          });
      } else {
        service.ready.resolve();
      }
    };

    var wrapFetchCb = function(cb) {
      return function(status) {
        var k =
          Session.school.sync.type === "qb-desktop"
            ? "qbDesktopStatus"
            : "qbOnlineStatus";
        Session.school.sync[k] = status === "success";
        if (cb) cb(status);
      };
    };

    sessionService.ready.promise.then(service.setup);

    return service;
  }
]);
