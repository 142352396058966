import _ from "underscore";
import angular from "angular";

angular.module("helme.budgetCollection").directive("collectionTree", [
  "$state",
  "$timeout",
  "$rootScope",
  "dataService",
  "sessionService",
  "formatService",
  "inspectService",
  "expandService",
  "treeService",
  "models",
  "$modal",
  "notify",
  "footerService",
  "dropdownId",
  "Session",
  "collectionService",
  "guid",
  "ENV",
  "tuitionService",
  "tagService",
  "ledger",
  "$window",
  "$document",
  "$interval",
  "actualsService",
  function(
    $state,
    $timeout,
    $rootScope,
    dataService,
    sessionService,
    formatService,
    inspectService,
    expandService,
    treeService,
    models,
    $modal,
    notify,
    footerService,
    dropdownId,
    Session,
    collectionService,
    guid,
    ENV,
    tuitionService,
    tagService,
    ledger,
    $window,
    $document,
    $interval,
    actualsService
  ) {
    function setPath(parent, node) {
      var path = dataService.paths[parent.id].concat(node.id);
      dataService.paths[node.id] = path;

      if (!!node.children)
        _.forEach(node.children, function(child) {
          setPath(node, child);
        });
    }

    return {
      restrict: "E",
      scope: {
        collection: "=",
        collectionType: "=type",
        update: "=?",
        yearMode: "=?",
        viewOnly: "=?"
      },
      link: function(scope, element, attribs) {
        scope.mode = collectionService.mode;
        scope.sessionMode = sessionService.mode;
        scope.currentYear = ENV.currentYear;

        scope.$watch(
          "collection",
          function(newValue, oldValue) {
            scope.classTree =
              $state.current.name === "students" &&
              sessionService.mode.tuitionView === "tree";
            if (newValue !== oldValue && !!scope.update) {
              scope.update(newValue, oldValue);
            }
          },
          true
        );

        var dragExpandItem;

        var dragging = false;
        var scrollDelta = 0;
        var scrolling = false;
        var intervalPromise = null;
        var main = $document[0].getElementById("main-wrapper");

        angular.element($window).on("mousemove", dragScroll);

        function scrollStart() {
          if (!scrolling) {
            scrolling = true;
            intervalPromise = $interval(scroll, 1);
          }
        }

        function scroll() {
          if (scrollDelta != 0) {
            main.scrollTop += scrollDelta;
          } else {
            scrolling = false;
            $interval.cancel(intervalPromise);
          }
        }

        function dragScroll(event) {
          if (dragging) {
            var w = $window,
              d = $document[0],
              e = d.documentElement,
              g = d.getElementsByTagName("body")[0],
              height = w.innerHeight || e.clientHeight || g.clientHeight,
              eventHeight = event.pageY;
            if (eventHeight < 15) {
              scrollDelta = -12;
            } else if (eventHeight < 30) {
              scrollDelta = -6;
            } else if (eventHeight < 45) {
              scrollDelta = -3;
            } else if (eventHeight > height - 15) {
              scrollDelta = 9;
            } else if (eventHeight > height - 45) {
              scrollDelta = 6;
            } else if (eventHeight > height - 60) {
              scrollDelta = 3;
            } else {
              scrollDelta = 0;
            }
            scrollStart();
          }
        }

        var dragNode;
        scope.treeOptions = {
          containerPositioning: "relative",
          dragStart: function(event) {
            dragging = true;
            dragNode =
              event.source.sortableScope.modelValue[event.source.index];
          },
          dragEnd: function(event) {
            dragging = false;
            scrollDelta = 0;
            var node = dragNode;
            var path = dataService.paths[node.id];
            var oldParent = dataService.getItem(path[path.length - 2]);
            var newParent;
            if (_.isUndefined(event.dest.sortableScope.$parent.modelValue)) {
              newParent = dataService.getItem(path[0]);
              dataService.paths[node.id] = [path[0], path[path.length - 1]];
            } else {
              var parent = event.dest.sortableScope.$parent.modelValue;
              newParent = parent;
              setPath(parent, node);
              if (!expandService.isExpanded(parent))
                expandService.toggleChildrenVisibility(parent);
            }
            if (newParent.id !== oldParent.id) {
              ledger.events.item.move.log(node, newParent, oldParent);
              $rootScope.$broadcast("push-state");
            }
            dragExpandItem = null;
          },
          accept: function(sourceItemScope, destScope, destItemScope) {
            return true;
          },
          dragOver: function(sourceItemScope, destItemScope) {},
          type: scope.type
        };

        scope.linkingActive = function() {
          return actualsService.linkingActive();
        };

        scope.actualsLinked = actualsService.isLinked;

        scope.showDropSpace = function(item) {
          return !!dragExpandItem && item.id === dragExpandItem.id;
        };

        scope.isVisible = function(item) {
          return (
            !scope.isFutureItem(item) ||
            (scope.mode.futureItemsVisible &&
              (scope.yearMode === "current" ||
                (scope.yearMode === "next" && item.start === 1)))
          );
        };

        scope.dropdownVisibility = {};
        scope.dropdownVisible = function(item) {
          return item.id === dropdownId;
        };
        scope.hideDropdown = function(item, $event) {
          scope.dropdownVisibility[item.id] = false;
          if (!!$event) $event.stopImmediatePropagation();
        };
        scope.showDropdown = function(item, $event) {
          scope.dropdownVisibility[item.id] = true;
          if (!!$event) $event.stopImmediatePropagation();
        };
        scope.toggleDropdown = function(item, $event) {
          if (dropdownId === item.id) scope.hideDropdown(item, $event);
          else scope.showDropdown(item, $event);
        };

        scope.isFutureItem = function(item) {
          var start = !!item.start ? item.start : 0;
          return (
            (scope.yearMode === "current" && start > 0) ||
            (scope.yearMode === "next" && start > 1)
          );
        };

        scope.itemLinked = function(item) {
          return (
            item &&
            (item.link || (item.linking && item.linking.type !== "none"))
          );
        };

        scope.categoryDisplay = function(item) {
          var start = !!item.start ? item.start : 0;
          if (!scope.isFutureItem(item) && scope.yearMode === "current")
            return scope.formattedValue(item, 0);
          if (0 < item) return scope.formattedValue(item, item);
          return scope.formattedValue(item, 1);
        };

        var hoverTimeout;

        scope.mouseleave = function(item) {
          scope.hideDropdown(item);

          if (!!selected) {
            footerService.useItem(selected);
          } else {
            hoverTimeout = $timeout(footerService.useDefault, 100);
          }
        };
        scope.mouseenter = function(item) {
          if (!!hoverTimeout) $timeout.cancel(hoverTimeout);
          footerService.useItem(item);
        };

        var selected = null;

        scope.selectInspector = function(item) {
          if (scope.yearMode === "current") {
            inspectService.inspectorClick();
            inspectService.select(item, function() {
              selected = null;
            });
            selected = item;
          }
        };

        scope.deselect = function() {
          selected = null;
          $timeout(footerService.useDefault, 0);
        };

        scope.$on("item-deselect", scope.deselect);

        scope.isSelected = function(item) {
          return !!selected && item.id === selected.id;
        };

        scope.isExpanded = expandService.isExpanded;
        scope.toggleChildrenVisibility = function(item, $event) {
          expandService.toggleChildrenVisibility(item);

          $event.stopImmediatePropagation();
        };

        function expand(item) {
          if (!expandService.isExpanded(item))
            expandService.toggleChildrenVisibility(item);
        }

        scope.bulkAdd = function(item) {
          collectionService.bulkAdd(item);
          expand(item);
        };

        scope.addChildItem = function(item) {
          collectionService.addChildItem(item);
          expand(item);
        };
        scope.addChildCategory = function(item) {
          collectionService.addChildCategory(item);
          expand(item);
        };

        scope.tagPreview = function(item) {
          return item.tags ? item.tags.join(", ") : "";
        };

        scope.showPageMoveDialogue = collectionService.showPageMoveDialogue;

        scope.sortChildren = function(item, attribute) {
          var sorted = _.sortBy(item.children, function(child) {
            var a = child[attribute];
            if (attribute === "name") return a.toLowerCase();
            return a;
          });
          if (attribute === "value") sorted.reverse();
          item.children = sorted;
        };

        scope.duplicateItem = function(itemScope, item) {
          collectionService.duplicate(item);
          $rootScope.$broadcast("push-state");
        };

        //must be named remove, as the tree library has a removeItem function which overrides removeItem.
        scope.remove = function(item) {
          collectionService.removeItem(item);
          tagService.removeItemOccurences(item);
          inspectService.deselect();
          footerService.useDefault();
        };

        scope.dateRange = function(item) {
          var start = !!item.start ? item.start : 0;
          var end = !!item.end ? item.end : 8;
          if ((!start || start === 0) && (!end || end === 8)) return "";
          var range = " - ";
          range = "'" + (ENV.currentYear - 2000 + start) + range;
          if (end !== 8) range = range + "'" + (ENV.currentYear - 2000 + end);
          return range;
        };

        scope.value = function(item, index) {
          if (!index) index = 0;
          var retrieved = dataService.getItem(
            item.id,
            dataService.projection[index]
          );
          if (!retrieved) return 0;
          return retrieved.value;
        };

        scope.formattedValue = function(item, index) {
          return formatService.formatDollarValue(
            scope.value(item, index),
            false
          );
        };

        scope.futureValues = function(item) {
          var start = !!item.start ? item.start : 0;
          var end = !!item.end ? item.end : 8;

          var values = [];
          for (var x = 0; x < 8; x++) {
            if (x < start || x > end) values.push("-");
            else {
              var value = dataService.getItem(
                item.id,
                dataService.projection[x]
              ).value;
              var formatFn = formatService.formatDollarValue;
              var width = $window.innerWidth;
              //900 850 780 680 650
              if (
                (value > 1000000 && width < 920) ||
                (value > 100000 && width < 850) ||
                (value > 10000 && width < 780) ||
                (value > 1000 && width < 680)
              )
                formatFn = formatService.formatShortDollarValue;

              values.push(formatFn(value));
            }
          }
          return values;
        };

        scope.removeNext = function(item, $event) {
          if (!scope.viewOnly) delete item["next"];
        };
        scope.setNext = function(item, $event) {
          if (!item.children && !scope.viewOnly)
            item.next = dataService.getItem(
              item.id,
              dataService.projection[1]
            ).value;
          if ($event) $event.stopImmediatePropagation();
        };

        scope.nameChange = function(item) {
          return function(value, old) {
            if (value !== old) ledger.events.item.name.log(item, value, old);
          };
        };
        scope.valueChange = function(item) {
          return function(value, old) {
            if (value !== old)
              ledger.events.item.value.current.log(item, value, old);
          };
        };

        if (!scope.yearMode) scope.yearMode = "current";
        scope.viewOnly = scope.viewOnly || sessionService.isViewOnly();

        scope.prettyYears = ENV.prettyYears;
      },
      templateUrl: "./budget-tree.html"
    };
  }
]);
