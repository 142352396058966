import angular from "angular";

angular.module("helme.pages").controller("EditDashboardCtrl", [
  "$scope",
  "dashboard",
  "dashboardService",
  function($scope, dashboard, dashboardService) {
    $scope.dashboard = dashboard;
    $scope.remove = function(dashboard) {
      dashboardService.removeDashboard(dashboard);
      $scope.$dismiss();
    };
  }
]);
