import angular from "angular";
import fs from "fs";

angular.module("helme.export").service("exportService", [
  "$rootScope",
  "$q",
  "$timeout",
  "$modal",
  "ENV",
  "api",
  "$http",
  function($rootScope, $q, $timeout, $modal, ENV, api, $http) {
    var service = {};

    service.exportCurrent = function(id) {
      var q = $q.defer();
      var exportNode = document.getElementById(id || "export");
      exportNode.style.width = "640px";
      $rootScope.$broadcast("tile-resize");
      setTimeout(function() {
        var p = service.pdf(exportNode.innerHTML);

        var loadingModal = $modal.open({
          controller: "ExportLoadingCtrl",
          templateUrl: "./loading-modal.html",
          windowClass: "helme-modal",
          resolve: {
            status: function() {
              return {
                promise: p
              };
            }
          }
        });

        exportNode.style.width = "100%";
        $rootScope.$broadcast("tile-resize");

        p.then(
          function() {
            q.resolve();
          },
          function() {
            q.reject();
          }
        );
      }, 300);

      return q.promise;
    };

    service.wrapContent = function(html) {
      var yourDOCTYPE = "<!DOCTYPE html>"; // your doctype declaration
      return $http.get("https://app.helme.io/app.css").then(function(res) {
        return $http
          .get("https://app.helme.io/printer.css")
          .then(function(printResponse) {
            var str =
              yourDOCTYPE +
              "<html><head><script src='https://use.fontawesome.com/b688379fb9.js'></script> <style>" +
              res.data +
              "</style><style>" +
              printResponse.data +
              "</style></head><body>" +
              html +
              "</body>   </html>";
            return str;
          });
      });
    };

    function css_text(x) {
      return x.cssText;
    }

    function writeToNewWindow(html) {
      var x = window.open();
      x.document.open();
      x.document.write(html);
      x.document.close();
    }

    service.pdf = function(content) {
      return service
        .wrapContent(content)
        .then(function(html) {
          if (ENV.name === "development") {
            writeToNewWindow(html);
          }
          return api.pdf(html);
        })
        .then(function(res) {
          return ENV.nodeEndpoint + "/reports/" + res.data.filename;
        });
    };

    service.print = function(html) {
      service.wrapContent(html).then(function(content) {
        var myframe = document.createElement("IFRAME");
        myframe.domain = document.domain;
        myframe.style.position = "absolute";
        myframe.style.top = "-10000px";
        document.body.appendChild(myframe);
        myframe.contentDocument.write(content);
        setTimeout(function() {
          myframe.focus();
          myframe.contentWindow.print();
          myframe.parentNode.removeChild(myframe); // remove frame
        }, 500); // wait for images to load inside iframe
        window.focus();
      });
    };

    service.showModal = function() {
      return $modal.open({
        controller: "ExportModalCtrl",
        templateUrl: "./export-modal.html",
        windowClass: "helme-modal wide-modal"
      });
    };

    service.printExport = function() {
      return $modal.open({
        controller: "PrintExportCtrl",
        templateUrl: "./print-export-modal.html",
        windowClass: "helme-modal full-modal export-modal"
      });
    };

    service.excelExport = function() {
      return $modal.open({
        controller: "ExcelExportCtrl",
        templateUrl: "./excel-export-modal.html",
        windowClass: "helme-modal"
      });
    };

    return service;
  }
]);
