import _ from "underscore";
import angular from "angular";

angular.module("helme.budgetCollection").directive("simpleTree", [
  "ENV",
  "inspectService",
  "formatService",
  function(ENV, inspectService, formatService) {
    return {
      restrict: "E",
      scope: {
        collection: "=",
        collectionType: "=type",
        enableInspector: "@"
      },
      link: function(scope, element, attribs) {
        var values = {};
        scope.format = formatService.formatDollarValue;
        scope.formattedValue = function(item) {
          return scope.format(value(item));
        };

        function value(item) {
          if (!!item.value || item.value === 0) {
            return item.value;
          }

          var childSum = _.reduce(
            item.children,
            function(acc, child) {
              return acc + value(child);
            },
            0
          );

          return childSum;
        }

        var expanded = {};

        scope.onClick = function(item) {
          if (scope.enableInspector) inspectService.select(item);
        };

        scope.show = function(item) {
          expanded[item.id] = true;
        };

        scope.hide = function(item) {
          expanded[item.id] = false;
        };

        scope.visible = function(item) {
          return expanded[item.id];
        };

        _.forEach(scope.collection, function(item) {
          scope.show(item);
        });
      },
      templateUrl: "./simple-tree.html"
    };
  }
]);
