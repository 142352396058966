import _ from "underscore";
import angular from "angular";

angular.module("helme.pages").controller("AnalyticsCtrl", [
  "$rootScope",
  "$scope",
  "sessionService",
  "tagService",
  "metrics",
  "dataService",
  "ENV",
  "formatService",
  "actualsService",
  function(
    $rootScope,
    $scope,
    sessionService,
    tagService,
    metrics,
    dataService,
    ENV,
    formatService,
    actualsService
  ) {
    $scope.search = "";
    $scope.selected = null;
    $scope.counts = {};
    $scope.range = _.range;
    $scope.futureVisible = false;
    $scope.sessionMode = sessionService.mode;

    function setup() {
      $scope.tags = tagService.tags;
      _.forEach($scope.tags, function(tag) {
        $scope.counts[tag] = metrics.tagLeafCount(tag);
      });
      $scope.tags = _.sortBy($scope.tags, function(tag) {
        return $scope.counts[tag];
      }).reverse();
      $scope.topTags = _.map($scope.tags, function(tag) {
        return {
          name: tag,
          overview: metrics.tagOverview(tag)
        };
      });

      $scope.fullOverview = metrics.fullOverview(dataService.projection);

      $scope.futureItems = dataService.getFutureItems();
      $scope.accounts = actualsService.getSortedAccounts();
      $scope.currentYear = ENV.currentYear;
      $scope.prettyYears = ENV.prettyYears;
    }

    $scope.formatByMetricName = function(v, k, full) {
      if (k === "Hard Income") return formatService.formatPercentage(v);
      if (k === "Enrollment") return v;
      return formatService.formatDollarValue(v, !full);
    };

    $scope.empty = _.isEmpty;

    $scope.formatDollar = formatService.formatShortDollarValue;

    $scope.hasActuals = function(tag) {
      return actualsService.isLinked(tag);
    };

    $scope.ytdSum = function(tag) {
      return actualsService.getSummedYtdActual(tag);
    };

    function groupItems(items) {
      return _.pairs(
        _.groupBy(items, function(item) {
          var categoryName = dataService.itemCategory(item.id).name;
          if (categoryName === "Classes") return "Tuition";
          else return categoryName;
        })
      );
    }

    function arrayTotal(array) {
      return _.reduce(
        array,
        function(acc, v) {
          return acc + v.value;
        },
        0
      );
    }

    function formattedArrayTotal(array) {
      return formatService.formatShortDollarValue(arrayTotal(array));
    }

    function annotateItemGroups(pair) {
      return {
        collection: pair[1],
        name: pair[0],
        total: formattedArrayTotal(pair[1]),
        rawTotal: arrayTotal(pair[1]),
        type: dataService.itemTypes[pair[1][0].id]
      };
    }

    function groupSortFn(group) {
      return [
        "other income",
        "development",
        "fees",
        "other expenses",
        "advancement",
        "co-curricular",
        "curricular",
        "operations",
        "personnel"
      ].indexOf(group.name.toLowerCase());
    }

    function makeSummary(grouped, type) {
      var typeCategories = _.filter(grouped, function(g) {
        return g.type === type;
      });

      return {
        axisLabels: _.pluck(typeCategories, "name"),
        series: [_.pluck(typeCategories, "rawTotal")]
      };
    }

    $scope.selectTag = function(tag) {
      var overview = metrics.tagOverview(tag),
        ids = tagService.itemLookup[tag],
        items = _.map(ids, function(id) {
          return dataService.getItem(id, dataService.projection[0]);
        }),
        grouped = _.sortBy(
          _.map(groupItems(items), annotateItemGroups),
          groupSortFn
        ),
        expenseTotal = metrics.expense(dataService.projection[0]),
        expenseRatio = overview.expense[0] / expenseTotal,
        incomeTotal = metrics.income(dataService.projection[1]),
        incomeRatio = overview.income[0] / incomeTotal,
        incomeSummary = makeSummary(grouped, "income"),
        expenseSummary = makeSummary(grouped, "expense"),
        actualsReport = actualsService.itemActualsReport(tag, "tag");

      $scope.selected = {
        name: tag,
        pages: grouped,
        summaries: {
          income: incomeSummary,
          expense: expenseSummary
        },
        actuals: actualsReport,
        overview: overview,
        ratio: {
          expense: formatService.formatPercentage(expenseRatio),
          income: formatService.formatPercentage(incomeRatio)
        }
      };

      $scope.futureVisible = false;
      $scope.sessionMode.analytics.overviewVisible = false;
      $scope.sessionMode.analytics.selected = $scope.selected;
    };

    $scope.searchSelect = function(search) {
      var tag = _.first(_.filter($scope.tags, $scope.tagFilter));
      $scope.selectTag(tag);
      $scope.search = "";
    };

    $scope.hiddenTag = function(tag) {
      var hiddenTags = [
        "other income",
        "development",
        "fees",
        "other expenses",
        "advancement",
        "co-curricular",
        "curricular",
        "operations",
        "personnel"
      ];
      return hiddenTags.indexOf(tag) > -1;
    };

    $scope.tagFilter = function(tag) {
      return (
        !$scope.hiddenTag(tag) &&
        ($scope.search === "" || tag.indexOf($scope.search) > -1)
      );
    };

    $scope.accountName = function(accountId) {
      var account = _.find($scope.accounts, function(account) {
        return accountId === account.id;
      });
      if (!account) return "";
      return account.name;
    };

    $scope.range = _.range;

    $scope.actualsFilterFn = function(account) {
      return _.contains(["Income", "Expense", "Bank"], account.type);
    };

    $scope.linkingActive = actualsService.linkingActive;

    $scope.$watch(
      function() {
        return sessionService.mode.analytics.view;
      },
      function(val) {
        if (val === "future") $scope.showCharts = false;
        else {
          $rootScope.$broadcast("tile-resize");
          $scope.showCharts = true;
          $scope.sessionMode.analytics.overviewVisible = true;
        }
      }
    );
    $scope.$watch("sessionMode.analytics.overviewVisible", function(val) {
      setTimeout(function() {
        $rootScope.$broadcast("tile-resize");
      }, 0);
    });

    $rootScope.$on("projection-calculated", function() {
      if (
        !$scope.futureVisible &&
        !$scope.sessionMode.analytics.overviewVisible
      ) {
        var el = document.getElementById("tag-detail");
        var height = el.clientHeight;
        el.style.height = el.clientHeight + "px";
        $scope.selectTag($scope.selected.name);
        setTimeout(function() {
          $rootScope.$broadcast("tile-resize");
          el.style.height = "";
        }, 0);
      }
    });

    tagService.ready.promise.then(function() {
      actualsService.ready.promise.then(setup);
    });
  }
]);
