import _ from "underscore";
import angular from "angular";

angular
  .module("helme.pages")
  .controller("EnrollmentCtrl", [
    "$scope",
    "$rootScope",
    "dataService",
    "sessionService",
    "metrics",
    "tuitionService",
    "ENV",
    "ledger",
    function(
      $scope,
      $rootScope,
      dataService,
      sessionService,
      metrics,
      tuitionService,
      ENV,
      ledger
    ) {
      $scope.viewOnly = sessionService.isViewOnly();

      $scope.range = _.range;
      $scope.years = _.range(1, 8).map(function(y) {
        return ENV.prettyYears[y];
      });
      $scope.enrollment = tuitionService.enrollment;

      $scope.yearRange = function() {
        if (
          sessionService.mode.tuitionView === "rates" ||
          $scope.mode.enrollmentView === "deltas"
        )
          return _.range(0, $scope.numYears);
        return _.range(0, $scope.numYears + 1);
      };

      $scope.className = function(classIndex) {
        if ($scope.enrollment.classes.length <= classIndex) {
          return "";
        }
        if ($scope.mode.enrollmentView === "deltas")
          return $scope.enrollment.classes[classIndex + 1].name;
        return $scope.enrollment.classes[classIndex].name;
      };
      $scope.classRange = function() {
        if ($scope.mode.enrollmentView === "deltas")
          return _.range(0, $scope.enrollment.classes.length - 1);
        return _.range(0, $scope.enrollment.classes.length);
      };

      $scope.granularChange = function(classIndex, yearIndex) {
        return function(value) {
          tuitionService.doGranularChange(classIndex, yearIndex, value);
          $rootScope.$broadcast("budget-changed");
        };
      };

      $scope.incomingChange = function(classIndex, yearIndex) {
        return function(value) {
          tuitionService.doIncomingChange(classIndex, yearIndex, value);
          $rootScope.$broadcast("budget-changed");
        };
      };

      $scope.growthChange = function(classIndex, yearIndex) {
        return function(value) {
          if (selectedGrade === classIndex && selectedYear === yearIndex) {
            tuitionService.rateGrowthChange(
              classIndex,
              yearIndex,
              value,
              $scope.mode.carryOver,
              $scope.mode.carryDown
            );
          }
        };
      };

      $scope.classGrowthChange = function(classIndex) {
        return function(value) {
          var c = tuitionService.enrollment.classes[classIndex];
          c.assumptions.rate = _.map(c.assumptions.rate, function() {
            return parseInt(value);
          });
        };
      };

      var selectedGrade, selectedYear;

      $scope.selectCell = function(classIndex, yearIndex) {
        selectedGrade = classIndex;
        selectedYear = yearIndex;
      };

      $scope.selectHighlight = function(classIndex, yearIndex) {
        if (selectedGrade === -1 && selectedYear === -1) return "";
        if (sessionService.mode.tuitionView === "rates") {
          if (
            (classIndex === selectedGrade && yearIndex == selectedYear) ||
            (classIndex === selectedGrade &&
              yearIndex > selectedYear &&
              $scope.mode.carryOver) ||
            ($scope.mode.carryDown &&
              classIndex > selectedGrade &&
              yearIndex === selectedYear) ||
            ($scope.mode.carryDown &&
              $scope.mode.carryOver &&
              classIndex > selectedGrade &&
              yearIndex > selectedYear)
          )
            return "selected";
          return "";
        }
        if (
          (classIndex == selectedGrade && yearIndex == selectedYear) ||
          ($scope.mode.carryOver &&
            $scope.mode.enrollmentView === "totals" &&
            selectedGrade >= 0 &&
            selectedYear >= 0 &&
            classIndex - selectedGrade === yearIndex - selectedYear &&
            classIndex - selectedGrade >= 0 &&
            yearIndex - selectedYear >= 0)
        )
          return "selected";
        return "";
      };

      $scope.getBackgroundHighlight = function(i1, i2) {
        var i = i1 % 5;
        if (
          sessionService.mode.tuitionView === "enrollment" &&
          $scope.mode.enrollmentView !== "deltas"
        )
          i = (5 + i1 - i2) % 5;
        if (
          sessionService.mode.tuitionView === "rates" &&
          $scope.mode.allRatesVisible
        )
          i = 0;
        return "light-" + i;
      };

      $scope.summaryVisible = function(classIndex) {
        return $scope.mode.allRatesVisible || classIndex === selectedGrade
          ? "collapse-visible"
          : "collapse-hidden";
      };

      var previousRate;
      $scope.rateFocus = function(classIndex) {
        previousRate = angular.copy(
          $scope.enrollment.classes[classIndex].item.rate
        );
      };

      $scope.rateBlur = function(classIndex) {
        var updatedRate = $scope.enrollment.classes[classIndex].item.rate;
        if (updatedRate !== previousRate) {
          var className = getClassName(classIndex);
          ledger.events.tuition.rate.current.log(
            classIndex,
            className,
            previousRate,
            updatedRate
          );
          $rootScope.$broadcast("push-state");
        }
        $scope.selectCell(-1, -1);
      };

      var previousEnrollment;
      $scope.enrollmentFocus = function(classIndex, yearIndex) {
        previousEnrollment = angular.copy(
          tuitionService.getEnrollment(classIndex, yearIndex)
        );
        $scope.selectCell(classIndex, yearIndex);
      };

      function getClassName(classIndex) {
        var className = tuitionService.grades[classIndex] + " grade";
        if (classIndex === 0) className = "Pre-K";
        if (classIndex === 1) className = "Kindergarten";
        return className;
      }

      $scope.enrollmentBlur = function(classIndex, yearIndex) {
        var updatedEnrollment = tuitionService.getEnrollment(
          classIndex,
          yearIndex
        );
        if (updatedEnrollment !== previousEnrollment) {
          var className = getClassName(classIndex);
          var yearName = ENV.prettyYears[yearIndex];
          ledger.events.tuition.enrollment.log(
            yearIndex,
            yearName,
            classIndex,
            className,
            previousEnrollment,
            updatedEnrollment,
            $scope.mode.carryOver
          );
          $rootScope.$broadcast("push-state");
        }
        $scope.selectCell(-1, -1);
      };

      var previousRateGrowth;
      $scope.rateGrowthFocus = function(classIndex, yearIndex) {
        previousRateGrowth =
          $scope.enrollment.classes[classIndex].assumptions.rate[yearIndex];
        $scope.selectCell(classIndex, yearIndex);
      };

      $scope.rateGrowthBlur = function(classIndex, yearIndex) {
        var updatedRateGrowth =
          $scope.enrollment.classes[classIndex].assumptions.rate[yearIndex];
        if (previousRateGrowth !== updatedRateGrowth) {
          var className = getClassName(classIndex);
          var yearName = ENV.prettyYears[yearIndex];
          ledger.events.tuition.rate.growth.log(
            yearIndex,
            yearName,
            classIndex,
            className,
            previousRateGrowth,
            updatedRateGrowth,
            $scope.mode.carryOver,
            $scope.mode.carryDown
          );
          $rootScope.$broadcast("push-state");
        }
        $scope.selectCell(-1, -1);
      };

      $scope.$watch(
        function() {
          return tuitionService.enrollment.classes;
        },
        function(newVal, oldVal) {
          if (newVal !== oldVal) {
            $rootScope.$broadcast("budget-changed");
          }
        },
        true
      );

      function setup() {
        $scope.enrollment = tuitionService.enrollment;
        $scope.enrollmentTotals = metrics.projectFn("enrollment");
        $scope.deltaValue = tuitionService.deltaValue;
        $scope.mode = tuitionService.mode;
        $scope.inIndexX = tuitionService.inIndexX;
        $scope.inIndexY = tuitionService.inIndexY;
        $scope.deltaChange = tuitionService.deltaChange;
        $scope.actualRate = tuitionService.actualRate;
        $scope.numYears = tuitionService.years;
      }

      $scope.$on("projection-calculated", setup);
      $scope.$on("auth-login-success", setup);
      $rootScope.$on("tuition-loaded", setup);
      $rootScope.$on("full-state-change", setup);

      tuitionService.ready.promise.then(setup);
    }
  ])
  .controller("firstDeltaCtrl", [
    "$scope",
    "tuitionService",
    function($scope, tuitionService) {
      $scope.yearRange = function() {
        return _.range(1, 8);
      };

      var highlight = -1;

      $scope.selectCell = function(classIndex, yearIndex) {
        highlight = yearIndex;
        $scope.$parent.$parent.$parent.$parent.selectCell(-1, -1);
      };

      $scope.selectHighlight = function(classIndex, yearIndex) {
        return highlight === yearIndex ? "selected" : "";
      };

      function doIncomingChange(classIndex, yearIndex, d) {
        var x = tuitionService.inIndexX(yearIndex, classIndex);
        var y = tuitionService.inIndexY(yearIndex, classIndex);
        tuitionService.enrollment.incoming[x][y] += d;
        if (
          classIndex < tuitionService.enrollment.classes.length &&
          yearIndex < $scope.years
        )
          doIncomingChange(classIndex + 1, yearIndex + 1, d);
      }

      $scope.incomingChange = function(classIndex, yearIndex) {
        return function(value) {
          var x = tuitionService.inIndexX(yearIndex, classIndex);
          var y = tuitionService.inIndexY(yearIndex, classIndex);
          var d = value - tuitionService.enrollment.incoming[x][y];
          doIncomingChange(classIndex, yearIndex, d);
        };
      };
    }
  ]);
