import _ from "underscore";
import angular from "angular";

angular.module("helme.session", ["ui.router"]).service("delorean", [
  "$rootScope",
  "dataService",
  "sessionService",
  "$state",
  "replayService",
  "ledger",
  "guid",
  function(
    $rootScope,
    dataService,
    sessionService,
    $state,
    replayService,
    ledger,
    guid
  ) {
    var service = {};

    service.stateHistory = [];
    var stateIndex = -1;

    service.pushState = function(event) {
      if (
        !_.isEmpty(dataService.state) &&
        !sessionService.mode.whatIf &&
        !sessionService.mode.replaying
      ) {
        var newState = {
          route: $state.current.name,
          id: event._id
        };
        if (stateIndex < service.stateHistory.length - 1) {
          service.stateHistory = service.stateHistory.slice(0, stateIndex + 1);

          ledger.exports.list.reverse();
          ledger.exports.list = ledger.exports.list
            .slice(0, stateIndex + 1)
            .concat([_.last(ledger.exports.list)]);
          ledger.exports.list.reverse();
        }
        service.stateHistory.push(newState);
        stateIndex = service.stateHistory.length - 1;
      }
    };

    function switchView(stateIndex) {
      if (
        stateIndex !== 0 &&
        !!service.stateHistory[stateIndex] &&
        $state.current.name !== service.stateHistory[stateIndex].route
      )
        $state.go(service.stateHistory[stateIndex].route);
    }

    service.undo = function() {
      if (0 <= stateIndex) {
        switchView(stateIndex);
        ledger.logUndo(service.stateHistory[stateIndex].id);
        stateIndex--;
      }
    };

    service.redo = function() {
      if (
        stateIndex < service.stateHistory.length - 1 &&
        stateIndex < service.stateHistory.length - 1
      ) {
        stateIndex++;
        switchView(stateIndex);
        ledger.logRedo(service.stateHistory[stateIndex].id);
      }
    };

    service.isActive = function(event) {
      if (stateIndex < 0) return event.id === "initial-state";
      return service.stateHistory[stateIndex].id === event._id;
    };

    $rootScope.$on("ledger-event", function(e, event) {
      service.pushState(event);
    });

    $rootScope.$on("auth-logout-success", function() {
      service.stateHistory = [];
    });

    $rootScope.$on("set-state-history", function(event, id) {
      //revert(id);
    });

    return service;
  }
]);
