import angular from "angular";

angular.module("helme.inspector", []).service("inspectService", [
  "$rootScope",
  "$state",
  "sessionService",
  "dataService",
  "formatService",
  "$timeout",
  "$document",
  "metrics",
  function(
    $rootScope,
    $state,
    sessionService,
    dataService,
    formatService,
    $timeout,
    $document,
    metrics
  ) {
    var service = {
      exports: {
        selectedItem: {},
        type: "",
        itemData: [],
        isTopLevel: true
      }
    };

    service.isVisible = function() {
      return sessionService.mode.inspectorVisible;
    };

    var deselectCB;

    service.displayValue = function() {
      if (!service.isVisible || !service.exports.selectedItem) return "";
      var projectedItem = dataService.getItem(
        service.exports.selectedItem.id,
        dataService.projection[0]
      );
      var value = projectedItem ? projectedItem.value : 0;
      return formatService.formatDollarValue(value);
    };

    service.select = function(item, cb) {
      if (!!deselectCB) deselectCB();

      deselectCB = cb;

      service.exports.selectedItem = dataService.getItem(item.id);

      service.exports.itemData = metrics.getItemProjection(
        service.exports.selectedItem
      );

      $timeout(function() {
        sessionService.mode.inspectorVisible = true;
        $rootScope.$broadcast("tile-resize");
      }, 0);

      $rootScope.$broadcast("item-select");
    };

    service.selectFuture = function(item, cb) {
      service.isFutureItem = true;
      service.select(item, cb);
    };

    service.deselect = function() {
      if (service.exports.preventDeselect) return;
      if (!!deselectCB) deselectCB();
      service.exports.selectedItem = null;
      sessionService.mode.inspectorVisible = false;
      service.isFutureItem = false;
      $rootScope.$broadcast("item-deselect");
    };

    //inspector deselection magic.
    var inspectorClicked = false;
    $document.bind("click", function() {
      if (!inspectorClicked && sessionService.mode.inspectorVisible)
        service.deselect();
      $rootScope.$apply();
    });

    service.inspectorClick = function() {
      inspectorClicked = true;
      $timeout(function() {
        inspectorClicked = false;
      }, 0);
    };

    function getItemValues() {
      if (!!service.exports.selectedItem)
        service.exports.itemData = metrics.getItemProjection(
          service.exports.selectedItem
        );
      else service.exports.itemData = [];
    }

    $rootScope.$on("projection-calculated", getItemValues);
    $rootScope.$on("item-removed", service.deselect);

    return service;
  }
]);
