import angular from "angular";

angular.module("helme.export").controller("ExcelExportCtrl", [
  "$scope",
  "api",
  function($scope, api) {
    $scope.view = "loading";
    $scope.export = function() {
      return api.export.pandl().then(
        function(res) {
          $scope.view = "complete";
          $scope.complete = false;
        },
        function(err) {
          $scope.err = err;
          $scope.view = "error";
        }
      );
    };

    $scope.getTitle = function() {
      if ($scope.view === "loading") {
        return "Preparing Excel Export...";
      }
      if ($scope.view === "complete") {
        return "Excel Export Complete";
      }
      return "Excel Export Failure";
    };
    $scope.export();
  }
]);
