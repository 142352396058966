import _ from "underscore";
import angular from "angular";

angular.module("helme.pages").controller("futureGradeEditCtrl", [
  "$scope",
  "ntuitionService",
  "ENV",
  function($scope, tuitionService, ENV) {
    $scope.range = _.range;
    $scope.currentYear = ENV.currentYear;
    $scope.prettyYears = ENV.prettyYears;

    function setup() {
      $scope.mode = tuitionService.mode;

      $scope.tuition = tuitionService.tuition;
    }
    $scope.futureType = function(prop) {
      if (prop === "rate") return "%";
      return "number";
    };
    $scope.currentType = function(prop) {
      if (prop === "rate") return "$";
      return "number";
    };

    $scope.futureGradeChange = tuitionService.futureGradeChange;
    $scope.gradeHighlight = function(grade) {
      return (
        tuitionService.mode.future.grade &&
        grade.id === tuitionService.mode.future.grade.id
      );
    };

    $scope.carryHighlight = function(gradeIndex, yearIndex) {
      if (!tuitionService.mode.future.carry)
        return (
          tuitionService.mode.future.gradeIndex === gradeIndex &&
          tuitionService.mode.future.yearIndex === yearIndex
        );

      var d1 =
        tuitionService.mode.future.gradeIndex -
        tuitionService.mode.future.yearIndex;
      var d2 = gradeIndex - yearIndex;
      if (tuitionService.mode.future.prop === "sections" && d2 > d1)
        return false;
      return (
        tuitionService.mode.future.gradeIndex !== -1 &&
        tuitionService.mode.future.gradeIndex <= gradeIndex &&
        tuitionService.mode.future.yearIndex <= yearIndex
      );
    };

    $scope.blurCb = function(gradeIndex, yearIndex) {
      return function() {
        tuitionService.mode.future.gradeIndex = -1;
        tuitionService.mode.future.yearIndex = -1;
      };
    };

    $scope.getFutureValue = function(gradeIndex, yearIndex) {
      var grade = tuitionService.tuition.grades[gradeIndex];
      if (grade)
        return tuitionService.tuition.grades[gradeIndex].assumptions[
          tuitionService.mode.future.prop
        ][yearIndex];
      return 0;
    };

    $scope.focusCb = function(gradeIndex, yearIndex) {
      tuitionService.mode.future.gradeIndex = gradeIndex;
      tuitionService.mode.future.yearIndex = yearIndex;
    };
    $scope.$watch("mode", function(val) {}, true);
    tuitionService.ready.promise.then(setup);
  }
]);
