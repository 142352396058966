import angular from "angular";

angular.module("helme.footer", []).service("footerService", [
  "$q",
  "$rootScope",
  "metrics",
  function($q, $rootScope, metrics) {
    var service = {};

    service.mode = {
      isVisible: false
    };

    service.banner = {
      visible: false,
      text: ""
    };

    service.item = {};
    service.itemData = [];
    service.itemTitle = "";
    service.useItemSelection = false;

    service.tabData = [];

    service.useTab = function(root) {
      service.tab = root;
      $rootScope.$broadcast("footer-data-change");
    };

    service.refreshTabData = function() {
      service.tabData = metrics.getItemProjection(service.tab);
      return service.tabData;
    };

    service.useItem = function(item) {
      service.item = item;
      service.itemTitle = item.name;
      service.useItemSelection = true;
      $rootScope.$broadcast("footer-data-change");
    };

    service.refreshItemData = function() {
      service.itemData = metrics.getItemProjection(service.item);
      return service.itemData;
    };

    service.useDefault = function() {
      service.useItemSelection = false;
      $rootScope.$broadcast("footer-data-change");
    };

    return service;
  }
]);
