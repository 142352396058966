import _ from "underscore";
import angular from "angular";

angular.module("helme.pages").controller("TuitionCtrl", [
  "$scope",
  "guid",
  "sessionService",
  "formatService",
  "ntuitionService",
  function($scope, guid, sessionService, formatService, tuitionService) {
    $scope.studentGradeCount = tuitionService.studentGradeCount;
    $scope.discountStudentCount = tuitionService.discountStudentCount;

    $scope.addDiscount = tuitionService.addDiscount;
    $scope.removeDiscount = tuitionService.removeDiscount;

    $scope.pushBreakdown = tuitionService.addBreakdown;
    $scope.removeBreakdown = tuitionService.removeBreakdown;

    $scope.collapseAll = tuitionService.collapseAll;
    $scope.expandAll = tuitionService.expandAll;
    $scope.toggleCollapse = tuitionService.toggleCollapse;
    $scope.isCollapsed = tuitionService.isCollapsed;
    $scope.anyCollapsed = tuitionService.anyCollapsed;

    $scope.range = _.range;

    $scope.effectiveRate = function(grade, cohort, breakIndex) {
      var breakdown = cohort.breakdowns[breakIndex];
      var discount = $scope.tuition.discounts[breakdown.value];
      if (discount) {
        var discountVal = discount.value;
        if (discount.mode === "rate")
          discountVal = (discount.value * grade.rate) / 100;
        var rate = Math.max(grade.rate - discountVal, 0);
      } else {
        var rate = grade.rate;
      }
      return formatService.formatDollarValue(rate);
    };

    function setup() {
      $scope.tuition = tuitionService.tuition;

      $scope.refreshDiscounts();
    }

    $scope.refreshDiscounts = function() {
      $scope.discountOptions = makeDiscountOptions();
    };

    $scope.discountDisplay = function(discount) {
      return discount.name;
    };

    $scope.discountModeChange = function(id) {
      return function(val) {
        $scope.tuition.cohorts[id].mode = val.key;
      };
    };

    $scope.discountToInputType = function(discount) {
      if (discount.mode === "target") return "$";
      return "%";
    };

    $scope.discountModes = [
      {
        name: "Dollar Discount",
        key: "target"
      },
      {
        name: "Percent Increase",
        key: "rate"
      }
    ];

    $scope.discountChange = function(cohort, index) {
      return function(discount) {
        if (discount.key === "none") cohort.breakdowns[index].mode = null;
        else {
          cohort.breakdowns[index].mode = "discount";
          cohort.breakdowns[index].value = discount.key;
        }
      };
    };

    $scope.editFuture = tuitionService.editFuture;

    function makeDiscountOptions() {
      var discounts = _.map($scope.tuition.discounts, function(val, key) {
        return {
          key: key,
          name: val.name
        };
      });
      return discounts.concat([
        {
          name: "No Discount",
          key: "none"
        }
      ]);
    }
    $scope.getDiscountOptions = function() {
      return $scope.discountOptions;
    };
    sessionService.ready.promise.then(function() {
      tuitionService.ready.promise.then(setup);
    });
    $scope.$watch(
      "tuition",
      function(val) {
        if (val) console.info(helmelib.model.tuition.annotate(val));
      },
      true
    );
  }
]);
