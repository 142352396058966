import _ from "underscore";
import angular from "angular";

angular.module("helme.quickbooks").service("qboMock", function() {
  return {
    generate: function(n) {
      var accounts = _.chain(n)
        .range()
        .map(function(num) {
          return {
            id: "quickbooks-" + num,
            name: "Quickbooks Account " + (num + 1)
          };
        })
        .map(function(account, index) {
          var a = ((7 * index) % 5) * (3 / 8) * 1000;
          var b = ((51 * index) % 27) * 870;

          var monthly = _.map(_.range(0, 24), function(x) {
            var g = Math.pow(1.03, Math.floor(x / 12));
            var c = a * (x % 12) + b;

            return {
              index: x,
              value: c * g
            };
          });

          return {
            id: account.id,
            name: account.name,
            monthly: monthly
          };
        })
        .value();

      return accounts;
    }
  };
});
