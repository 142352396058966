import angular from "angular";

angular.module("helme.quickbooks").directive("actualsBar", [
  "actualsService",
  "ENV",
  "formatService",
  function(actualsService, ENV, formatService) {
    return {
      restrict: "E",
      /* optional */
      replace: true,
      scope: {
        id: "=",
        budgetedValue: "=",
        type: "=?",
        orientation: "@?"
      },
      link: function(scope, el, attrs) {
        scope.fillPercentage = 0;
        scope.markerHeight = 0;
        scope.cappedFillPercentage = 0;

        function fill() {
          var fillPercentage = 0,
            markerHeight = 100;
          var report = actualsService.itemActualsReport(scope.id);
          scope.monthly = report.monthly;
          scope.type = scope.type ? scope.type : "expense";
          scope.fillPercentage = report.percentRaw;
          scope.cappedFillPercentage = Math.min(fillPercentage, 100);
          scope.markerHeight =
            report.percentRaw > 100 ? (100 / report.percentRaw) * 100 : 100;
          scope.previewStr = report.preview;
        }

        scope.$watch(
          function() {
            return [
              scope.budgetedValue,
              actualsService.getSummedYtdActual(scope.id)
            ];
          },
          function(old, val) {
            fill();
          },
          true
        );
      },
      templateUrl: "./actuals-template.html"
    };
  }
]);
