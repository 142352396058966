import _ from "underscore";
import angular from "angular";

angular.module("helme.wizard", []).service("wizard", [
  "$modal",
  "ENV",
  "dataService",
  "tuitionService",
  "defaultClassTree",
  "$rootScope",
  "Session",
  "api",
  "importService",
  "dashboardService",
  function(
    $modal,
    ENV,
    dataService,
    tuitionService,
    defaultClassTree,
    $rootScope,
    Session,
    api,
    importService,
    dashboardService
  ) {
    var service = {};

    var currentModal;

    var startState = {
      step: 0,
      enrollment: {
        classes: [],
        capacity: 0
      },
      school: {
        name: "",
        description: "",
        year: "",
        address1: "",
        address2: "",
        created: new Date().getTime(),
        "comparison-opt-in": false
      },
      user: {
        fullname: "",
        title: "",
        license: false
      },
      finance: {
        reserve: 0,
        debt: 0,
        endowment: 0
      }
    };

    function getStoredProgress() {
      var progressJSON = localStorage.getItem("wizard-progress");
      if (!!progressJSON) {
        return JSON.parse(progressJSON);
      }
      return null;
    }

    service.storeProgress = function() {
      var progressJSON = JSON.stringify(service.exports);
      localStorage.setItem("wizard-progress", progressJSON);
    };

    function clearProgress() {
      localStorage.removeItem("wizard-progress");
    }

    var makeClass = function(name) {
      return {
        name: name,
        enrollment: 0,
        rate: 5000,
        enabled: true
      };
    };

    function setup() {
      var progress = getStoredProgress();
      if (!!progress) service.exports = progress;
      else {
        service.exports = startState;
        service.exports.enrollment.classes = _.map(
          [
            "Pre-K",
            "Kindergarten",
            "1st",
            "2nd",
            "3rd",
            "4th",
            "5th",
            "6th",
            "7th",
            "8th",
            "9th",
            "10th",
            "11th",
            "12th"
          ],
          makeClass
        );
        tuitionService.insertTree();
        service.exports.school.name = Session.school.name;
      }
    }

    service.begin = function() {
      setup();
      if (!currentModal) {
        currentModal = $modal.open({
          controller: "wizardCtrl",
          templateUrl: "./wizard.html",
          windowClass: "helme-modal wizard-modal",
          keyboard: false
        });
      }
    };

    function procedeOnImportSuccess(promise) {
      promise.then(
        function(sortModal) {
          sortModal.result.then(function() {
            importService.exports.onboarding = false;
          });
        },
        function() {
          console.info("Import modal dismissed");
        }
      );
    }

    service.startOnlineImport = function() {
      importService.exports.onboarding = true;
      var promise = importService.testConnection();
      procedeOnImportSuccess(promise);
      return promise;
    };

    service.desktopUpload = function(file) {
      importService.exports.onboarding = true;
      var promise = importService.quickbooksUpload(file);
      procedeOnImportSuccess(promise);
      return promise;
    };

    service.excelUpload = function(file) {
      importService.exports.onboarding = true;
      var promise = importService.excelUpload(file);
      procedeOnImportSuccess(promise);
      return promise;
    };

    service.enrollmentSubmit = function() {
      tuitionService.mode.carryOver = true;
      tuitionService.mode.carryDown = true;
      tuitionService.enrollment.classes = dataService.state.tuition.classes;
      tuitionService.enrollment.incoming =
        dataService.state.tuition["incoming-classes"];
      tuitionService.years =
        dataService.state.tuition["incoming-classes"].length;
      //enter the provided rate and enrollment numbers
      _.forEach(service.exports.enrollment.classes, function(c, i) {
        if (c.enabled) {
          tuitionService.enrollmentChange(i, 0, c.enrollment, true);
          tuitionService.enrollment.classes[i].item.rate = c.rate;
        }
      });

      //remove disabled classes from model
      tuitionService.enrollment.classes = _.filter(
        tuitionService.enrollment.classes,
        function(c, i) {
          return service.exports.enrollment.classes[i].enabled;
        }
      );

      dataService.state.tuition.classes = tuitionService.enrollment.classes;

      //set all incoming classes to the first grade's enrollment.
      for (var x = 1; x < 8; x++) {
        tuitionService.enrollmentChange(
          0,
          x,
          service.exports.enrollment.classes[0].enrollment,
          true
        );
      }
      tuitionService.enrollment.capacity = service.exports.enrollment.capacity;
      tuitionService.insertTree();
      tuitionService.createTree();
    };

    service.rateChange = function(classIndex) {
      return function(value) {
        if (value !== service.exports.enrollment.classes[classIndex].rate) {
          for (
            var x = classIndex;
            x < service.exports.enrollment.classes.length;
            x++
          ) {
            if (service.exports.enrollment.classes[x].enabled)
              service.exports.enrollment.classes[x].rate = value;
          }
        }
      };
    };
    service.enrollmentChange = function(classIndex) {
      return function(value) {
        if (
          value !== service.exports.enrollment.classes[classIndex].enrollment
        ) {
          for (
            var x = classIndex;
            x < service.exports.enrollment.classes.length;
            x++
          ) {
            if (service.exports.enrollment.classes[x].enabled)
              service.exports.enrollment.classes[x].enrollment = value;
          }
        }
      };
    };

    var submitting = false;
    service.submit = function() {
      if (!submitting) {
        submitting = true;
        service.enrollmentSubmit();
        dataService.state.modules.accounts.debt = service.exports.finance.debt;
        dataService.state.modules.accounts.endowment =
          service.exports.finance.endowment;
        service.exports.school.onboarded = true;
        Session.school.onboarded = true;
        api.client.update(service.exports.school);
        api.user.update(Session.user._id, service.exports.user);
        clearProgress();
        dashboardService.resetAll().then(
          function() {
            $rootScope.$broadcast("finish-wizard");
            currentModal.close();
          },
          function() {}
        );
      }
    };

    $rootScope.$on("start-wizard", service.begin);

    return service;
  }
]);
