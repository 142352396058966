import _ from "underscore";
import angular from "angular";

angular
  .module("helme.sync", [])
  .service("sync", [
    "sessionService",
    "$rootScope",
    "socketManager",
    "Session",
    "guid",
    "ledger",
    "replayService",
    "highlightService",
    function(
      sessionService,
      $rootScope,
      socketManager,
      Session,
      guid,
      ledger,
      replayService,
      highlightService
    ) {
      var dataStream;

      var logging = false;

      var collection = [],
        offlineEvents = [];

      function process(action) {
        collection.push(JSON.parse(action));
      }

      var service = {
        connected: false,
        collection: collection,
        clientId: guid.make(),
        offlineEvents: []
      };

      service.setup = function() {
        service.connected = true;
        socketManager.socket.emit("set-room", Session.school._id);
        socketManager.socket.on("event", function(event) {
          if (
            event.client !== service.clientId &&
            !sessionService.mode.offline
          ) {
            if (event.data.interface) {
              if (logging) console.info("recieved", event.data);
              highlightService.process(event.data);
            } else {
              if (logging) console.info(service.clientId);
              var ledgerEvent = event.data;
              ledgerEvent.recieved = true;
              replayService.replay([ledgerEvent]);
              ledger.exports.list.unshift(ledgerEvent);
              ledger.exports.current = ledgerEvent;
            }
          }
        });
        socketManager.socket.on("connect", service.reconnect);
      };

      service.reconnect = function() {
        var promise = sessionService.getBudget();
        promise.then(
          function(res) {
            if (logging) console.info("reconnected", res);
            if (service.offlineEvents.length > 0) {
              var errors = replayService.replay(service.offlineEvents);
              var successful = _.filter(service.offlineEvents, function(e) {
                return !_.some(errors, function(failedEvent) {
                  return failedEvent._id === e._id;
                });
              });
              if (errors.length > 0) {
                alert("Error syncing state!");
                if (logging) console.info(errors);
              }
              service.offlineEvents.length = 0;
              _.forEach(successful, function(event) {
                service.emit(event);
              });
            }
          },
          function(b) {
            if (("Failed Reconnection.", logging)) console.info(b);
          }
        );
      };

      window.reconnect = service.reconnect;
      window.mode = sessionService.mode;

      service.emit = function(event) {
        if (socketManager.connected && !sessionService.mode.offline) {
          if (logging) console.info("emitting: ", service.clientId);
          socketManager.socket.emit("event", {
            data: event,
            school: Session.school._id,
            client: service.clientId
          });
        } else {
          if (!event.interface) {
            service.offlineEvents.push(event);
            if (logging)
              console.info("Offline Events: ", service.offlineEvents);
          }
        }
      };

      $rootScope.$on("ledger-event", function(event, ledgerEvent) {
        service.emit(ledgerEvent);
      });

      $rootScope.$on("ledger-hidden-event", function(event, ledgerEvent) {
        replayService.replay([ledgerEvent]);
        service.emit(ledgerEvent);
      });

      $rootScope.$on("interface-event", function(event, interfaceEvent) {
        service.emit(interfaceEvent);
      });

      sessionService.ready.promise.then(function() {
        socketManager.ready.promise.then(function() {
          service.setup();
        });
      });
      return service;
    }
  ])
  .service("socketManager", [
    "$q",
    "ENV",
    function($q, ENV) {
      var service = {};
      service.ready = $q.defer();
      if (window.io) {
        service.socket = io.connect(ENV.nodeEndpoint);

        service.socket.on("error", function(err) {
          console.info(err);
        });

        service.socket.on("connect", function() {
          service.ready.resolve();
          service.connected = true;
          console.info("connected");
        });
        service.socket.on("disconnect", function() {
          service.connected = false;
          console.info("disconnected");
        });
      }
      return service;
    }
  ]);
