import angular from "angular";

angular.module("helme.export", []).controller("ExportLoadingCtrl", [
  "$scope",
  "status",
  function($scope, status) {
    $scope.view = "loading";
    status.promise.then(
      function(filename) {
        $scope.pdfFilename = filename;
        $scope.view = "success";
      },
      function(err) {
        $scope.view = "error";
        $scope.error = err;
      }
    );
  }
]);
