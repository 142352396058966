import angular from "angular";

angular.module("helme.pages").controller("importCtrl", [
  "$scope",
  "importService",
  "$timeout",
  "api",
  "$modal",
  "ENV",
  "type",
  "onboarding",
  function(
    $scope,
    importService,
    $timeout,
    api,
    $modal,
    ENV,
    importType,
    onboarding
  ) {
    $scope.view = importType;

    importService.exports.onboarding = onboarding;

    $scope.message = null;

    if ($scope.view === "qb-online") {
      var promise = importService.testConnection(true);
      promise.then(function(sortModal) {
        sortModal.result.then(function() {
          $scope.$close();
        });
      });
    }

    $scope.qbSelect = function(file) {
      var promise = importService.quickbooksUpload(file);

      if (!promise) {
        $scope.message = "Try again, file must be an excel spreadsheet.";
        return;
      }

      $scope.loading = true;
      promise.then(
        function(sortModal) {
          sortModal.result.then(
            function() {
              $scope.$close();
            },
            function() {
              $scope.loading = false;
              $scope.message = "Import cancelled.";
            }
          );
        },
        function() {
          $scope.message =
            "Import failed. Email support@helme.io with your Quickbooks version and your file.";
          $scope.loading = false;
        }
      );
    };

    $scope.excelSelect = function(file) {
      var promise = importService.excelUpload(file);
      if (!promise) {
        $scope.message = "Try again, file must be an excel spreadsheet.";
        return;
      }

      $scope.loading = true;
      promise.then(
        function(sortModal) {
          sortModal.result.then(
            function() {
              $scope.$close();
            },
            function() {
              $scope.loading = false;
              $scope.message = "Import cancelled";
            }
          );
        },
        function() {
          $scope.message = "Processing failed.";
          $scope.loading = false;
        }
      );
    };
  }
]);
