import _ from "underscore";
import angular from "angular";

angular.module("helme.quickbooks").directive("linkButton", [
  "actualsService",
  "ENV",
  function(actualsService, ENV) {
    return {
      restrict: "E",
      replace: true,
      scope: {
        id: "=",
        type: "@?"
      },
      templateUrl: "./link-button.html",
      link: function(scope, el, attrs) {
        scope.type = scope.type ? scope.type : "item";
        scope.getAccountName = actualsService.getAccountName;
        var actualsLinks = {};

        scope.getLinks = function(itemId) {
          if (!actualsLinks[itemId])
            actualsLinks[itemId] = actualsService.getLinks(itemId);
          return actualsLinks[itemId];
        };
        scope.actualsLinked = function() {
          actualsService.isLinked(scope.id);
        };

        scope.linkPossible = function() {
          return actualsService.linkPossible(scope.id);
        };

        scope.linkString = function() {
          var accountNames = _.map(scope.getLinks(scope.id), function(link) {
            return scope.getAccountName(link.account);
          });
          if (accountNames.length > 0)
            return "Linked with " + accountNames.join(", ");
          return "No links found";
        };

        scope.unlinkAccount = function($event) {
          actualsService.unlinkAccount(scope.id, scope.type);
          actualsLinks = {};
          $event.stopPropagation();
        };

        scope.linkAccount = function($event) {
          actualsService.linkAccount(scope.id, scope.type);
          actualsLinks = {};
          $event.stopPropagation();
        };
      }
    };
  }
]);
