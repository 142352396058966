import angular from "angular";

angular.module("helme.input").controller("ConfirmDialogCtrl", [
  "$scope",
  "config",
  function($scope, config) {
    $scope.config = config;
    if (!!config.options) {
      $scope.confirmText = config.options[0];
      $scope.cancelText = config.options[1];
    } else {
      $scope.confirmText = "Confirm";
      $scope.cancelText = "Cancel";
    }

    $scope.submit = function() {
      $scope.$close($scope.config.value);
    };
  }
]);
