import angular from "angular";

angular.module("helme.pages").controller("EditUserCtrl", [
  "userService",
  "api",
  "$scope",
  "user",
  function(userService, api, $scope, user) {
    $scope.user = angular.copy(user);
    $scope.userTypes = userService.userTypes;
    $scope.password = {
      first: "",
      confirm: ""
    };

    $scope.changePassword = function(user) {
      api.user.resetPassword(user);
      $scope.resetEmailSent = true;
    };

    $scope.userTypes = userService.userTypes;

    $scope.typeDisplay = function(u) {
      return u.name;
    };

    $scope.selectType = function(t) {
      if (t === "viewer" && !$scope.user.dashboard)
        $scope.user.dashboard = "all";
      $scope.user.type = t;
    };

    $scope.validUser = function(user) {
      return user.pass !== "";
    };

    $scope.verifyEmail = function() {
      api.user.verify(user);
      $scope.emailSent = true;
    };

    $scope.shorten = function(email) {
      if (email.length > 20) return email.slice(0, 17) + "...";
      return email;
    };

    $scope.submit = function() {
      api.user.update($scope.user).then(function() {
        $scope.$close($scope.user);
      });
    };
  }
]);
