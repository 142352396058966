import _ from "underscore";
import angular from "angular";

angular.module("helme.pages").controller("SchoolCtrl", [
  "$scope",
  "$rootScope",
  "dataService",
  "sessionService",
  "$modal",
  "ENV",
  "formatService",
  "guid",
  "metrics",
  "Session",
  "api",
  "whatif",
  "importService",
  "exportService",
  "confirmDialog",
  "actualsService",
  "wizard",
  function(
    $scope,
    $rootScope,
    dataService,
    sessionService,
    $modal,
    ENV,
    formatService,
    guid,
    metrics,
    Session,
    api,
    whatif,
    importService,
    exportService,
    confirmDialog,
    actualsService,
    wizard
  ) {
    $scope.viewOnly = sessionService.isViewOnly();
    $scope.sessionMode = sessionService.mode;
    $scope.session = Session;

    $scope.pages = [
      {
        key: "account",
        name: "Account"
      },
      {
        key: "pnl",
        name: "Profit & Loss"
      },
      {
        key: "accounts-drives",
        name: "Cash Accounts & Drives"
      },
      {
        key: "quickbooks",
        name: "Quickbooks"
      },
      {
        key: "account-list",
        name: "Users"
      },
      {
        key: "scenarios",
        name: "What-if Scenarios"
      },
      {
        key: "import",
        name: "Import"
      },
      {
        key: "tab-select",
        name: "Tabs"
      },
      {
        key: "payments",
        name: "Payments"
      }
    ];

    function setup() {
      if (!_.isEmpty(dataService.state)) {
        $scope.reserve = dataService.state.reserve;
        $scope.accounts = dataService.state.modules.accounts;
        $scope.fundraising = dataService.state.modules.fundraising;
        $scope.modules = dataService.state.modules;
      }
      $scope.scenarios = whatif.exports.list;
      setupWatchers();
      setupIntuit();
      $scope.school = Session.school;
      $scope.intuitStatus = "loading";
      fetchAccounts();
    }

    function initIntuitButtons(config) {
      var buttons = document.getElementsByClassName("connect-button");
      if (buttons.length === 0) {
        setTimeout(initIntuitButtons.bind(null, config), 500);
      } else {
        _.forEach(buttons, function(b) {
          importService.setupButton(config, b);
        });
      }
    }

    var setupIntuit = function() {
      api.intuit.test().then(
        function(res) {
          $scope.intuitStatus = !res.data.connected
            ? "unconnected"
            : "connected";
          if (res.data.connected !== Session.school.sync.qbOnlineStatus) {
            Session.school.sync.qbOnlineStatus = res.data.connected;
            api.client.update({
              sync: Session.school.sync
            });
          }

          var config = importService.getIntuitConfig();
          initIntuitButtons(config);
        },
        function() {
          $scope.intuitStatus = "unconnected";
        }
      );
    };

    $scope.disconnectIntuit = function() {
      api.intuit.disconnect().then(function() {
        $scope.intuitStatus = "unconnected";
      });
    };

    $scope.connect = function() {
      importService.testConnection().then(
        function() {
          $scope.intuitStatus = "connected";
        },
        function() {
          $scope.intuitStatus = "unconnected";
        }
      );
    };

    $scope.show = function(page) {
      $scope.sessionMode.settingsView = page;
    };

    $scope.showWizard = function() {
      return Session.user.name === "headmaster.ida@gmail.com";
    };

    $scope.startWizard = function() {
      wizard.begin();
    };

    $scope.addFundraiser = function() {
      $scope.fundraising.push({
        name: "New Fundraiser",
        goal: 10000,
        pledged: 0,
        given: 0,
        id: guid.make()
      });
    };

    $scope.removeFundraiser = function(fundraiser) {
      $scope.fundraising = _.without($scope.fundraising, fundraiser);
    };

    $scope.addCashAccount = function() {
      $scope.accounts.cash.push({
        name: "New Account",
        value: 10000
      });
    };

    $scope.removeCashAccount = function(account) {
      $scope.accounts.cash = _.without($scope.accounts.cash, account);
    };

    $scope.age = function() {
      //localStorage.setItem("budget", dataService.state);
      confirmDialog
        .open({
          title: "Roll budget forward?",
          bodyText:
            "This will bring your budget into the " +
            ENV.prettyYears[0] +
            " school year by applying all growth assumptions one year forward. This action is not reversible.",
          options: ["Roll forward", "Cancel"]
        })
        .result.then(
          function() {
            dataService.state = helmelib.core.advance(dataService.state, 7);
            Session.school.aged = new Date().getFullYear();
            api.client.update({
              aged: Session.school.aged
            });
            alert("Budget rolled forward");
            $rootScope.$broadcast("budget-changed");
          },
          function() {}
        );
    };

    $scope.formatMonth = function(index) {
      var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
      var str = months[index - 1];
      if (index < 10) str += " (0" + index + ")";
      else str += " (" + index + ")";
      return str;
    };

    if (window.StripeCheckout)
      var stripeHandler = window.StripeCheckout.configure({
        key: ENV.stripe,
        token: function(token) {
          submitCreditUpdate(token.id);
        },
        closed: function() {
          $scope.updatingStripe = false;
        }
      });

    $scope.changeCreditCard = function() {
      if (window.StripeCheckout) {
        $scope.updatingStripe = true;
        stripeHandler.open({
          name: "Helme",
          panelLabel: "Update Payment Info",
          label: "Update",
          amount: 100 * 50,
          email: Session.user.name,
          allowRememberMe: false
        });
      } else alert("Stripe not found. Reload Helme and try again.");
    };

    var submitCreditUpdate = function(token) {
      return api.stripe
        .update(token)
        .then(function(res) {
          $scope.updatingStripe = false;
          api.session.getDetails().then(function(res) {
            Session.school = res.data.client;
          });
        })
        .catch(function(d) {
          $scope.updatingStripe = false;
          alert("Failure!");
        });
    };

    $scope.whatif = whatif.exports;
    $scope.showSummary = whatif.review;
    $scope.createScenario = whatif.start;
    $scope.removeScenario = whatif.discard;
    $scope.apply = whatif.apply;
    $scope.resume = whatif.resume;
    $scope.lastModified = function(scenario) {
      var seconds = (new Date().getTime() - parseInt(scenario.modified)) / 1000;
      if (seconds < 1 * 60) return "Just now";
      if (seconds < 2 * 60) return "A minute ago";
      if (seconds < 60 * 60) return Math.floor(seconds / 60) + " minutes ago";
      if (seconds < 24 * 60 * 60)
        return Math.floor(seconds / (60 * 60)) + " hours ago";
      if (seconds < 2 * 24 * 60 * 60) return "A day ago";
      if (seconds < 7 * 24 * 60 * 60)
        return Math.floor(seconds / (24 * 60 * 60)) + " days ago";
      if (seconds < 14 * 24 * 60 * 60) return "A week ago";
      if (seconds < 30 * 24 * 60 * 60)
        return Math.floor(seconds / (7 * 24 * 60 * 60)) + " weeks ago";
      if (seconds < 60 * 24 * 60 * 60) return "A month ago";
      return Math.floor(seconds / (30 * 24 * 60 * 60)) + " months ago";
    };

    function checkUnique(coll, key, val) {
      return (
        !val ||
        !_.some(coll, function(item) {
          return val.toLowerCase() === item[key].toLowerCase();
        })
      );
    }

    $scope.format = formatService.formatDollarValue;

    function calcNetPnl(pnl) {
      if (!pnl) return;
      $scope.netPnl = metrics.netPnl(pnl);
    }

    $scope.import = function(importType) {
      return $modal.open({
        controller: "importCtrl",
        templateUrl: "./import.html",
        windowClass: "helme-modal height-limit",
        resolve: {
          type: function() {
            return importType;
          },
          onboarding: function() {
            return false;
          }
        }
      });
    };

    $scope.pullQbPnl = function() {
      $scope.actualsLoading = true;
      api.intuit.actualsSummary().then(function(res) {
        $scope.actualsLoading = false;
        sessionService.mode.settingsView = "pnl";
        // $scope.modules.pandl.actual.ytd = res.data.ytd;
      });
    };

    $scope.showAging = function() {
      return (
        Session.user &&
        Session.user.name &&
        Session.school &&
        (!Session.school.aged ||
          Number(Session.school.aged) !== new Date().getFullYear())
      );
    };

    $scope.reserveChanged = function(val, old) {
      dataService.state.reserve = val;
      $scope.reserve = val;
      $rootScope.$broadcast("budget-changed");
    };

    var fetchAccounts = function() {
      actualsService.fetchAccounts(function(status) {
        console.info("Account fetch successful?", status);
        if (status === "success") {
          $scope.message = "Successfully pulled latest actuals.";
        } else if (status === "qb-desktop-failure") {
          $scope.message =
            "In order to sync with Quickbooks Desktop, you must configure your connection with the Helme team. Please contact us at team@helme.io.";
        } else if (status === "qb-online-failure") {
          $scope.message =
            "Please connect Quickbooks online. For help, contact us at team@helme.io";
        }
      });
    };

    $scope.syncList = [
      {
        name: "None",
        id: "no-sync"
      },
      {
        name: "Quickbooks Online",
        id: "qb-online"
      },
      {
        name: "Quickbooks Desktop",
        id: "qb-desktop"
      }
    ];
    $scope.syncOptionDisplay = function(o) {
      return o.name;
    };
    $scope.selectSyncType = function(o) {
      Session.school.sync.type = o;
      api.client.update({
        sync: Session.school.sync
      });
      fetchAccounts();
    };
    $scope.pullCashOptions = [
      {
        name: "Yes",
        id: true
      },
      {
        name: "No",
        id: false
      }
    ];
    $scope.pullCashDisplay = function(o) {
      return o.name;
    };

    //TODO sync bank accounts on change.
    $scope.pullCashChange = function(o) {
      $scope.pullCash = o;
      Session.school.sync.syncBank = o;
      api.client.update({
        sync: Session.school.sync
      });
    };

    function setupWatchers() {
      $scope.$watch("modules.pandl", calcNetPnl, true);

      $scope.$watch(
        "[accounts, fundraising]",
        function(newVal, oldVal) {
          if (newVal !== oldVal) {
            dataService.state.accounts = newVal[0];
            dataService.state.modules.fundraising = newVal[1];
            $rootScope.$broadcast("budget-changed");
          }
        },
        true
      );

      $scope.$watch(
        function() {
          return (
            sessionService.mode.settingsView === "import-export" ||
            sessionService.mode.settingsView === "account" ||
            sessionService.mode.settingsView === "quickbooks"
          );
        },
        function() {
          setupIntuit();
        }
      );
    }

    sessionService.ready.promise.then(setup);
  }
]);
