import _ from "underscore";
import angular from "angular";

angular.module("helme.pages").controller("DashCtrl", [
  "$rootScope",
  "$scope",
  "$timeout",
  "sessionService",
  "dashboardService",
  "tileService",
  "tagService",
  function(
    $rootScope,
    $scope,
    $timeout,
    sessionService,
    dashboardService,
    tileService,
    tagService
  ) {
    $scope.yearRange = _.range(0, 8);

    $scope.yearIndex = 0;

    $scope.selectYear = function(year) {
      $scope.yearIndex = year;
    };

    $scope.seriesConfig = {
      height: 150
    };

    var startPosition = {};
    var startSize = {};
    $scope.gridsterConfig = {
      minRows: 1,
      maxRows: 50,
      columns: 3,
      rowHeight: "225",
      margins: [25, 5],
      defaultSizeX: 1,
      defaultSizeY: 1,
      resizable: {
        enabled: true,
        handles: ["e", "w"],
        start: function(event, $element, size) {
          startSize = angular.copy(size);
        },
        resize: function(event, $element, size) {
          $rootScope.$broadcast("tile-resize");
        },
        stop: function(event, $element, size) {
          if (size.sizeX !== startSize.sizeX) {
            dashboardService.saveDashboard();
          }
          $timeout(function() {
            $rootScope.$broadcast("tile-resize");
          }, 300);
        }
      },
      draggable: {
        enabled: true,
        handle: ".drag-handle",
        start: function(event, $element, position) {
          startPosition = angular.copy(position);
        },
        stop: function(event, $element, endPosition) {
          if (
            startPosition.col !== endPosition.col ||
            startPosition.row !== endPosition.row
          ) {
            dashboardService.saveDashboard();
          }
        }
      }
    };

    $scope.visibility = {
      income: false,
      expense: false
    };

    function setup() {
      /*dashboardService.getComparisonData()
            .then(function() {
                $scope.comparisons = dashboardService.comparisons;
            });;*/
      $scope.sessionMode = sessionService.mode;
      $scope.dashboard = dashboardService.exports;
      $scope.metricLibrary = dashboardService.metricLibrary;

      dashboardService.refreshAllTiles();
      dashboardService.calcMaster();
      $scope.master = dashboardService.master;
      $rootScope.$broadcast("tile-resize");
      window.addEventListener("resize", function() {
        $rootScope.$broadcast("tile-resize");
      });
    }

    $scope.metricChange = tileService.metricChange;
    $scope.getChartTypes = tileService.getChartTypes;
    $scope.findTile = dashboardService.findTile;

    $scope.vizTypeChange = tileService.vizTypeChange;

    function doSetup() {
      $scope.isDashboardViewer = sessionService.isViewOnly();
      sessionService.ready.promise.then(function() {
        dashboardService.ready.promise.then(function() {
          setup();
        });
      });
    }

    $scope.$on("full-state-change", doSetup);
    $scope.$on("budget-changed", function() {
      tagService.ready.promise.then(dashboardService.refreshAllTiles);
    });
    doSetup();
  }
]);
