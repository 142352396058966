import _ from "underscore";
import angular from "angular";

angular.module("helme.compare", []).service("compareService", [
  "$q",
  "sessionService",
  "Session",
  "metrics",
  "dataService",
  "api",
  function($q, sessionService, Session, metrics, dataService, api) {
    var service = {
      exports: {
        overview: {},
        tags: {},
        list: []
      },
      ready: $q.defer()
    };

    var configList = [
      {
        name: "Average Tuition Rate",
        key: "average-tuition",
        type: "$$"
      },
      {
        name: "Cost per Student",
        key: "cost-per-student",
        type: "$$"
      },
      {
        name: "Hard Income",
        key: "hard-income",
        type: "%"
      },
      {
        name: "Reserve",
        key: "reserve",
        type: "$$"
      },
      {
        name: "Reserve : Total Expenses",
        key: "reserve-expense-ratio",
        type: "%"
      }
    ];

    function ratio(k1, k2) {
      var m1 = resolveMetric(k1);
      var m2 = resolveMetric(k2);
      self = m2.self === 0 ? m1.self : m1.self / m2.self;
      others: _.map(m1.others, function(v, i) {
        return v / m2.others[i];
      });
      return {
        self: self,
        others: _.map(m1.others, function(v, i) {
          return v / m2.others[i];
        })
      };
    }

    function netTuition() {
      var tuition = resolveMetric("gross-tuition");
      var reductions = resolveMetric("reductions");
      return {
        self: tuition.self - reductions.self,
        others: _.map(tuition.others, function(v, i) {
          return v - reductions.others[i];
        })
      };
    }

    function resolveMetric(metricKey) {
      if (metricKey === "net-tuition") {
        return netTuition();
      } else if (metricKey === "cost-per-student") {
        return ratio("expense", "enrollment-total");
      } else if (metricKey === "average-tuition") {
        return ratio("net-tuition", "enrollment-total");
      } else if (metricKey === "hard-income") {
        return ratio("net-tuition", "expense");
      } else if (metricKey === "reserve-expense-ratio") {
        return ratio("reserve", "expense");
      }
      return {
        self: service.exports.overview.self.overview[metricKey],
        others: _.map(service.exports.overview.others, function(school) {
          return school.overview[metricKey];
        })
      };
    }

    function calcComparison(config) {
      var data = resolveMetric(config.key);
      var chartData = chartAgainstEnrollment(data.others, config.name);
      return {
        name: config.name,
        self: data.self,
        others: data.others,
        type: config.type,
        chart: chartData
      };
    }

    var chartAgainstEnrollment = function(data, label) {
      var enrollment = resolveMetric("enrollment-total");
      var removeList = [];
      _.forEach(enrollment.others, function(d, i) {
        if (!isFinite(data[i])) {
          removeList.push(i);
        }
      });
      for (var x = removeList.length - 1; x >= 0; x--) {
        enrollment.others.splice(removeList[x], 1);
        data.splice(removeList[x], 1);
      }
      return {
        x: {
          data: enrollment.others,
          label: "Enrollment"
        },
        y: {
          data: data,
          label: label
        }
      };
    };

    var incomeSummary = function() {
      var categories = ["tuition", "development", "fees", "other income"];
      return tagComparison(categories, "income");
    };
    var expenseSummary = function() {
      var categories = [
        "personnel",
        "operations",
        "curricular",
        "co-curricular",
        "advancement"
      ];
      return tagComparison(categories, "expense");
    };

    var sum = function(coll) {
      return _.reduce(
        coll,
        function(acc, v, i) {
          return acc + v;
        },
        0
      );
    };

    var tagComparison = function(tags, type) {
      var self = [];
      var others = [];
      var totals = resolveMetric(type);
      var otherSum = sum(totals.others);
      _.forEach(tags, function(category) {
        if (category === "tuition") {
          var tuition = resolveMetric("net-tuition");
          self.push(tuition.self / totals.self);
          others.push(sum(tuition.others) / otherSum);
        } else {
          self.push(
            service.exports.overview.self.tags[category][type] / totals.self
          );
          others.push(
            _.reduce(
              service.exports.overview.others,
              function(acc, school, i) {
                return acc + school.tags[category][type] / otherSum;
              },
              0
            )
          );
        }
      });
      return {
        axisLabels: tags,
        series: [self, others],
        legendLabels: [Session.school.name, "Others"]
      };
    };

    function setup() {
      api.compare().then(function(res) {
        service.exports.overview = res.data;
        service.exports.incomeSummary = incomeSummary();
        service.exports.expenseSummary = expenseSummary();
        service.exports.list = _.map(configList, calcComparison);
      });
      service.ready.resolve();
    }

    sessionService.ready.promise.then(setup);

    return service;
  }
]);
