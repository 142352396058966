import _ from "underscore";
import angular from "angular";
import c3 from "c3";

angular.module("helme.graphs", []).directive("lineChart", [
  "sessionService",
  "graphService",
  "formatService",
  "$timeout",
  "ENV",
  function(sessionService, graphService, formatService, $timeout, ENV) {
    function formatData(data) {
      var bounds = graphService.niceBounds(data.series);
      var labels = !!data.labels ? data.labels : [""];
      var cSeries = _.map(data.series, function(d, i) {
        return [labels[i]].concat(d);
      });
      var years = ["years"].concat(
        _.range(
          ENV.currentYear % 2000,
          (ENV.currentYear + data.series[0].length) % 2000
        )
      );

      var columns = cSeries.concat([years]);
      return {
        bounds: {
          y: bounds
        },
        data: {
          columns: columns,
          x: "years",
          type: "line"
        }
      };
    }

    function go(scope, element) {
      if (!scope.data) return;
      var chartInfo = formatData(scope.data);
      var config = {
        bindto: element[0],
        data: chartInfo.data,
        axis: {
          y: {
            tick: {
              format: formatService.formatFn(scope.unit),
              values: chartInfo.bounds.y
            },
            min: _.first(chartInfo.bounds.y),
            max: _.last(chartInfo.bounds.y)
          }
        },
        legend: {
          show: chartInfo.data.columns.length > 2
        },
        padding: {
          top: 5,
          right: 15
        }
      };
      return c3.generate(config);
    }

    function link(scope, element, attribs) {
      var chart;
      $timeout(function() {
        if (scope.data) chart = go(scope, element);
      }, 100);
      scope.$watch("data", function(val, old) {
        if (val !== old) {
          chart = go(scope, element);
        }
      });
      scope.$on("tile-resize", function() {
        if (chart) $timeout(chart.flush, 150);
      });
    }

    return {
      restrict: "E",
      replace: true,
      link: link,
      scope: {
        data: "=",
        unit: "=",
        config: "="
      },
      template: "<div class='c3-chart'> </div>"
    };
  }
]);
