import angular from "angular";

angular.module("helme.session").controller("DataCtrl", [
  "$scope",
  "$rootScope",
  "$document",
  "dataService",
  "sessionService",
  "saveService",
  "inspectService",
  "footerService",
  "ENV",
  function(
    $scope,
    $rootScope,
    $document,
    dataService,
    sessionService,
    saveService,
    inspectService,
    footerService,
    ENV
  ) {
    $scope.mode = sessionService.mode;
    $scope.footerMode = footerService.mode;

    $scope.mode = sessionService.mode;
    $scope.viewOnly = sessionService.isViewOnly;

    $scope.toggleDrawer = function() {
      if (!sessionService.isViewOnly())
        $scope.mode.drawerVisible = !$scope.mode.drawerVisible;
    };

    $scope.$on("push-state", function() {
      setTimeout(sessionService.pushState, 5);
    });

    function bindKeydown() {
      //Listen for ctrl+z or cmd-z
      $document.bind("keydown", function(event) {
        if (event.ctrlKey || event.metaKey) {
          if (event.keyCode == 90) sessionService.undo();
          else if (event.keyCode == 89) sessionService.redo();
          else if (event.keyCode == 83) {
            if (saveService.hasChanged()) {
              sessionService.save();
            }
            event.preventDefault();
          }
        }
      });
    }

    var leavingPageText = "You'll lose your changes if you leave";

    window.onbeforeunload = function() {
      saveService.save();
    };

    $scope.$on("$stateChangeSuccess", function(event, state) {
      sessionService.ready.promise.then(function() {
        $scope.tabType = dataService.stateType(state.name);
      });
    });

    $scope.$on("$destroy", function() {
      window.onbeforeunload = undefined;
    });

    $scope.$on("unbind-keydown", bindKeydown);
  }
]);
