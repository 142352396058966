import angular from "angular";

angular.module("helme.session").service("intercomService", [
  "Session",
  function(Session) {
    var service = {
      loggedIn: false
    };
    service.login = function() {
      if (!service.loggedIn) {
        service.intercomSettings = {
          app_id: "yehs8x3f",
          email: Session.user.name,
          created_at:
            Session.user.created_at || Math.round(new Date().getTime() / 1000),
          name: Session.user.fullname,
          user_id: Session.user._id,
          user_title: Session.user.title,
          company: {
            id: Session.school._id,
            name: Session.school.name,
            created_at:
              Session.school.created_at ||
              Math.round(new Date().getTime() / 1000),
            monthly_spend: 49,
            founded: Session.school.year,
            description: Session.school.description
          }
        };
        service.loggedIn = true;
        console.info("Booting Intercom", service.intercomSettings);
        // window.Intercom("boot", service.intercomSettings);
      }
    };

    service.update = function() {
      if (service.loggedIn) window.Intercom("update", service.intercomSettings);
    };
    return service;
  }
]);
