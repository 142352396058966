import angular from "angular";

angular
  .module("helme.navigation")
  .directive("undoRedo", [
    "sessionService",
    "ENV",
    "ledger",
    "delorean",
    function(sessionService, ENV, ledger, delorean) {
      return {
        restrict: "E",
        replace: true,
        scope: {},
        link: function(scope, element, attribs) {
          scope.mode = sessionService.mode;
          scope.undo = delorean.undo;
          scope.redo = delorean.redo;
          scope.events = ledger.exports;
          scope.read = ledger.readEvent;
          scope.revert = ledger.revert;
          scope.isActive = delorean.isActive;
        },
        templateUrl: "./undo-redo.html"
      };
    }
  ])
  .directive("loadingMessage", [
    "sessionService",
    "authService",
    "Session",
    "$document",
    "ENV",
    function(sessionService, authService, Session, $document, ENV) {
      return {
        restrict: "E",
        replace: true,
        scope: {},
        link: function(scope, element, attribs) {
          scope.message = "Loading Helme...";

          function show() {
            document.getElementById("loading").style.display = "flex";
          }

          function hide() {
            document.getElementById("loading").style.display = "none";
          }

          scope.$on("auth-login-begin", function() {
            scope.message = "Loading Helme...";
            show();
          });

          scope.$on("auth-session-expired", hide);
          scope.$on("projection-calculated", hide);
          scope.$on("wizard-finish", hide);

          scope.$on("application-offline", function() {
            scope.message = "Server can't be reached, try again later";
          });
        },
        templateUrl: "./loading.html"
      };
    }
  ]);
