import _ from "underscore";
import angular from "angular";
import * as d3 from "d3";

angular.module("helme.graphs").directive("donutChart", [
  "formatService",
  "$timeout",
  function(formatService, $timeout) {
    var margins = [15, 25, 15, 25]; // margins
    var chartWidth = 550 - margins[1] - margins[3]; // width
    var chartHeight = 200 - margins[0] - margins[2]; // height
    var radius = Math.min(chartWidth, chartHeight) / 2;

    function initChart(el) {
      var selection = d3.select(el);
      var containerWidth = selection[0][0].clientWidth;
      if (containerWidth > 0) {
        chartWidth = containerWidth - margins[1] - margins[3];
        chartHeight = containerWidth / 2 - margins[0] - margins[2];
        radius = Math.min(chartWidth, chartHeight) / 3;
      }

      var svg = d3
        .select(el)
        .select("svg")
        .attr("width", chartWidth + margins[1] + margins[3])
        .attr("height", chartHeight + margins[0] + margins[2])
        .append("g")
        .attr(
          "transform",
          "translate(" + 0.66 * chartWidth + "," + chartHeight / 2 + ")"
        );
    }

    function wrapEllipsis(width) {
      return function() {
        var self = d3.select(this),
          textLength = self.node().getComputedTextLength(),
          text = self.text();
        while (textLength > width && text.length > 0) {
          text = text.slice(0, -1);
          self.text(text + "...");
          textLength = self.node().getComputedTextLength();
        }
      };
    }

    // Computes the angle of an arc, converting from radians to degrees.
    function angle(d) {
      var a = ((d.startAngle + d.endAngle) * 90) / Math.PI - 90;
      return a > 90 ? a - 180 : a;
    }

    function draw(data, el) {
      var color = d3.scale
        .ordinal()
        .range(["#6EB6E5", "#44A0DC", "#1F8ED6", "#0780CF", "#05588F"]);

      var pie = d3.layout.pie().sort(null);

      var arc = d3.svg
        .arc()
        .innerRadius(radius - 30)
        .outerRadius(radius);

      var svg = d3
        .select(el)
        .select("svg")
        .attr("width", chartWidth)
        .attr("height", chartHeight)
        .select("g");

      var slices = svg
        .selectAll("g.slice")
        .data(
          pie(
            _.map(data, function(d) {
              return d[1];
            })
          )
        )
        .enter()
        .append("svg:g")
        .attr("class", "slice");

      var path = slices
        .append("path")
        .attr("fill", function(d, i) {
          return color(i);
        })
        .attr("d", arc);

      var text = svg
        .selectAll("g.label")
        .data(
          pie(
            _.map(data, function(d) {
              return d[1];
            })
          )
        )
        .enter()
        .append("svg:g")
        .attr("class", "label");

      text
        .append("svg:text")
        .attr("transform", function(d) {
          //set the label's origin to the center of the arc
          //we have to make sure to set these before calling arc.centroid
          d.outerRadius = radius + 30; // Set Outer Coordinate
          d.innerRadius = radius - 30; // Set Inner Coordinate
          return "translate(" + arc.centroid(d) + ")";
        })
        .attr("text-anchor", "middle") //center the text on it's origin
        .style("fill", "black")
        .text(function(d) {
          return formatService.formatDollarValue(d[1], true);
        });

      if (svg.select(".legend").empty()) {
        var names = _.map(data, function(d) {
          return d[0];
        });

        var start = ((-1 * (data.length - 1)) / 2) * 10;

        var legend = svg
          .selectAll(".legend")
          .data(names)
          .enter()
          .append("g")
          .attr("class", "legend")
          .attr("transform", function(d, i) {
            return "translate(0," + (i * 20 - (data.length / 2) * 20) + ")";
          });

        legend
          .append("rect")
          .attr("x", -chartWidth / 3)
          .attr("width", 18)
          .attr("height", 18)
          .style("fill", function(d, i) {
            return color(i);
          });

        legend
          .append("text")
          .attr("x", -chartWidth / 3 - 6)
          .attr("y", 9)
          .attr("dy", ".35em")
          .style("text-anchor", "end")
          .text(function(d) {
            return d;
          })
          .each(wrapEllipsis(120));
      }
    }

    function link(scope, element, attribs) {
      initChart(element[0]);

      if (!!scope.data) draw(scope.data, element[0]);

      scope.$watch("data", function(previous, next) {
        if (previous !== next) {
          draw(scope.data, element[0]);
        }
      });
    }

    return {
      restrict: "E",
      replace: true,
      link: link,
      scope: {
        data: "="
      },
      template: " <div class='donut-chart'> <svg></svg> </div>"
    };
  }
]);
