import _ from "underscore";
import angular from "angular";
import angularUiRouter from "@uirouter/angularjs";
import {AUTH_EVENTS} from "../auth/service";
import LogRocket from "logrocket";

var prettyYear = function(year) {
  return year + "-" + (year + 1);
};

var getYearArray = function(startYear) {
  var yearArray = [];
  for (var x = 0; x < 15; x++) {
    yearArray.push(prettyYear(startYear + x));
  }
  return yearArray;
};

angular.module("helme.session").service("sessionService", [
  "$rootScope",
  "$timeout",
  "$q",
  "$http",
  "dataService",
  "notify",
  "Session",
  "api",
  "pages",
  "$state",
  "$window",
  "$modal",
  "ENV",
  "guid",
  "intercomService",
  function(
    $rootScope,
    $timeout,
    $q,
    $http,
    dataService,
    notify,
    Session,
    api,
    pages,
    $state,
    $window,
    $modal,
    ENV,
    guid,
    intercomService
  ) {
    var service = {};

    service.loaded = false;
    service.ready = $q.defer();

    service.projectionEnabled = true;

    service.isViewOnly = function() {
      return !!Session.user && Session.user.type === "viewer";
    };

    service.viewAllDashboards = function() {
      return (
        !!Session.user &&
        Session.user.type === "viewer" &&
        Session.user.dashboard === "all"
      );
    };

    service.mode = {
      year: "current",
      dashboardView: "overview",
      analytics: {
        view: "label-overview",
        analyticsVisible: true
      },
      drawerVisible:
        document.documentElement.clientWidth > 1200 && !service.isViewOnly(),
      inspectorVisible: false,
      linkingActuals: false,
      tabbarVisible: $state.name !== "school",
      whatIf: false,
      replaying: false,
      tuitionView: "rates",
      settingsView: "account"
    };

    function addBudgetData(budget) {
      if (!budget.modules.actuals) {
        budget.modules.actuals = {
          links: [],
          accounts: []
        };
      }
      if (!Session.school.sync) {
        Session.school.sync = {
          type: "none",
          syncBank: false,
          qbOnlineStatus: false,
          qbDesktopStatus: false
        };
        api.client.update({
          sync: Session.school.sync
        });
      }
    }

    function budgetReceived(budget) {
      service.mode.offline = false;
      const budgetYear = Session.school.aged || budget.aged || 2018;
      ENV.currentYear = Math.max(budgetYear, 2018);
      ENV.prettyYears = getYearArray(ENV.currentYear % 2000);
      addBudgetData(budget);
      pages.setTabs(budget);
      dataService.state = budget;
      service.mode.drawerVisible =
        document.documentElement.clientWidth > 1200 && !service.isViewOnly();
      service.loaded = true;

      if (!Session.school.onboarded) window.startWizard();

      var tourDisable = false;
      if (
        Session.school._id === "demo-academy_0" &&
        Session.user.type !== "viewer" &&
        !tourDisable
      ) {
        $rootScope.$broadcast("start-intro-tour");
      }

      intercomService.login();

      if (window.Headway) setTimeout(window.Headway.init(), 500);

      if (process.env.NODE_ENV !== "development") {
        LogRocket.init("arubfk/helme");
        LogRocket.identify(Session.user.email, {
          name: Session.user.name,
          email: Session.user.email,
          school: Session.school.name
        });
      }

      console.info("Budget loaded");
      $rootScope.$broadcast("budget-changed");
      $rootScope.$broadcast("full-state-change");
      $rootScope.$broadcast("push-state");
      return budget;
    }

    //Load initial data from server on app start.
    service.getBudget = function() {
      if (!Session.initialized && !!localStorage["budget"]) {
        var storedBudget = JSON.parse(localStorage["budget"]);
        Session.initialized = true;
      }
      storedBudget = null;
      if (
        !_.isUndefined(Session.school) &&
        !_.isUndefined(Session.school._id) &&
        !_.isNull(Session.school._id)
      )
        return api.budget.get().then(
          function(res) {
            var budget = res.data;
            /*if (!!storedBudget && ((!budget.modified ||
                                  budget.modified < storedBudget.modified) ||
                              (!budget.modified ||
                                  budget.modified < dataService.state.modified))) {
                          budget = storedBudget;
                      }*/
            return budgetReceived(budget);
          },
          function() {}
        );
    };

    function calcLinkValues() {
      _.forEach(dataService.projection, function(state, i) {
        state.reserve += dataService.setLinkValues(state);
      });
    }

    function projectCategory(s) {
      var cat = dataService.getPageData({
        key: s
      });

      var before = _.map(dataService.projection, function(budget) {
        return dataService.pageData(budget, {
          key: s
        }).value;
      });

      var catProj = helmelib.core.projectCategory(cat, 7);

      var after = _.map(catProj, function(category) {
        return category.value;
      });

      _.forEach(dataService.projection, function(state, i) {
        dataService.setStatePageData(
          {
            key: s
          },
          catProj[i],
          state
        );
        state.reserve += after[i] - before[i];
      });
    }

    function projectTuition() {
      var before = _.map(dataService.projection, function(budget) {
        return budget.tuition.gross - budget.tuition.reductions.value;
      });

      var tuitionProjection = helmelib.core.projectTuition(
        dataService.state.tuition,
        7
      );
      _.forEach(dataService.projection, function(state, i) {
        state.tuition = tuitionProjection[i];
      });

      var after = _.map(tuitionProjection, function(tuition) {
        return tuition.gross - tuition.reductions.value;
      });

      _.forEach(dataService.projection, function(state, i) {
        state.tuition = tuitionProjection[i];
        calcLinkValues();
        dataService.fillClassTree(state, i);
        state.reserve += after[i] - before[i];
      });
    }

    function setProjectionAccounts() {
      _.forEach(dataService.projection, function(state, i) {
        state.accounts = dataService.state.accounts;
      });
    }

    var calc = function(page) {
      if (service.projectionEnabled && Session.school.onboarded) {
        var s = $state.current.name,
          firstTime = dataService.projection.length === 0;

        dataService.paths = dataService.setAllPaths(dataService.state);

        if (
          firstTime ||
          s === "school" ||
          s === "analytics" ||
          s === "explore"
        ) {
          dataService.projection = helmelib.core.projectBudget(
            dataService.state,
            7
          );
          _.map(dataService.projection, dataService.fillClassTree);
        } else if (s === "students") {
          projectTuition();
        } else if (
          _.contains(pages.expenseCollections, s) ||
          _.contains(pages.incomeCollections, s)
        ) {
          projectCategory(s);
        }

        calcLinkValues();

        service.ready.resolve(dataService.state, dataService.projection);
        console.info("Projection calculated.");
        $rootScope.$broadcast("projection-calculated");
      }
    };

    var window = angular.element($window);

    window.on("focus", function(event) {
      if (!!Session.loggedIn) {
        api.session
          .isValid()
          .then(function(res) {
            if (res.data.valid) api.session.refresh();
            else {
              $rootScope.$broadcast(AUTH_EVENTS.sessionExpired);
            }
          })
          .catch(function(res) {
            $rootScope.$broadcast("request-failure");
          });
      }
    });

    var currentTimeout = null;
    $rootScope.$on("budget-changed", function() {
      if (currentTimeout) {
        $timeout.cancel(currentTimeout);
      }
      currentTimeout = $timeout(calc, 300);
    });

    $rootScope.$on("cancel-projection", function() {
      $timeout.cancel(currentTimeout);
    });

    $rootScope.$on("finish-wizard", function() {
      budgetReceived(dataService.state);
    });

    $rootScope.$on("$stateChangeStart", function() {
      intercomService.update();
    });

    /* Undo / Redo */

    var beating = false;

    function heartbeat(repeat) {
      if (repeat && !beating) {
        api
          .ping()
          .catch(function() {
            beating = false;
            if (service.loaded) {
              service.mode.offline = true;
              beating = true;
              $timeout(heartbeat, 2000);
            } else $rootScope.$broadcast("application-offline");
          })
          .then(function() {
            service.mode.offline = false;
          });
      }
    }

    window.startWizard = function() {
      $rootScope.$broadcast("start-wizard");
    };
    $rootScope.$on("request-failure", heartbeat);

    $rootScope.$on("what-if-start", function() {
      service.mode.whatIf = true;
    });

    $rootScope.$on("what-if-finish", function() {
      service.mode.whatIf = false;
    });

    $rootScope.$on("replay-start", function() {
      service.mode.replaying = true;
    });

    $rootScope.$on("replay-end", function() {
      service.mode.replaying = false;
    });

    $rootScope.$on(AUTH_EVENTS.loginSuccess, service.getBudget);

    return service;
  }
]);
