import angular from "angular";

angular
  .module("helme.util")
  .service("hintService", [
    function() {
      return {
        "next-year":
          "If you know what the value of this item will be next year, enter it here " +
          "and it will be factored into the calculations. This value is optional.",
        "smart-edit":
          "When an enrollment number is set for a year, that value will propogate to " +
          "future years if smart edit mode is enabled.",
        "add-child": "Add a child to this category",
        scenarios:
          "Scenarios allow you to test different courses of action with your budget. ",
        "scenario-duplicate":
          "What previously existing scenario should be used as the basis for " +
          "the newly created scenario?"
      };
    }
  ])
  .directive("hint", [
    "hintService",
    "ENV",
    function(hintService, ENV) {
      return {
        restrict: "E",
        scope: {
          key: "@",
          position: "@?"
        },
        templateUrl: "./hint.html"
      };
    }
  ])
  .directive("hoverTip", [
    "hintService",
    function(hintService) {
      return {
        restrict: "A",
        scope: {
          key: "@",
          position: "@?"
        },
        link: function(scope, element, attribs) {
          scope.text = hintService[scope.key];

          if (!scope.position) scope.position = "top middle";

          // new Tooltip({
          //   target: element[0],
          //   position: scope.position,
          //   content: scope.text
          // });
        }
      };
    }
  ]);
