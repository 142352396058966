import _ from "underscore";
import angular from "angular";

angular.module("helme.footer").directive("footer", [
  "dataService",
  "metrics",
  "formatService",
  "$state",
  "footerService",
  "pages",
  "ENV",
  "tuitionService",
  "sessionService",
  "whatif",
  function(
    dataService,
    metrics,
    formatService,
    $state,
    footerService,
    pages,
    ENV,
    tuitionService,
    sessionService,
    whatif
  ) {
    function link(scope, element, attribs) {
      scope.mode = footerService.mode;
      scope.sessionMode = sessionService.mode;

      scope.visualizations = [
        {
          name: "Reserve",
          value: "reserve",
          type: "dollar"
        },
        {
          name: "Income",
          value: "income",
          type: "dollar"
        },
        {
          name: "Expense",
          value: "expense",
          type: "dollar"
        },
        {
          name: "Net",
          value: "net",
          type: "dollar"
        },
        {
          name: "Enrollment",
          value: "enrollment",
          type: "metric"
        },
        {
          name: "Tuition (Net)",
          value: "tuition-net",
          type: "dollar"
        },
        {
          name: "Hard Income",
          value: "hard-income",
          type: "%"
        },
        {
          name: "Cost per Student",
          value: "cost-per-student",
          type: "dollar"
        }
      ];

      scope.vizSelection = scope.visualizations[0];

      scope.item = {
        title: "",
        data: []
      };

      var setData = function() {
        scope.useItemSelection = footerService.useItemSelection;
        if (scope.useItemSelection) {
          scope.vizData = footerService.refreshItemData();
          scope.vizTitle = footerService.itemTitle;
        } else if (!!scope.vizSelection) {
          if (scope.vizSelection.tab) {
            scope.vizData = footerService.refreshTabData();
          } else scope.vizData = metrics.projectFn(scope.vizSelection.value);
        }
        scope.vizData = _.map(scope.vizData, function(d, i) {
          return metrics.formatDatumForD3(d, i, scope.vizSelection.type);
        });
      };

      var stateName;

      function addPageViz() {
        var pageViz = {
          name: dataService.tabTitle({
            key: stateName
          }),
          value: "page",
          type: "dollar",
          tab: true
        };
        scope.visualizations.unshift(pageViz);
        scope.vizSelection = pageViz;
      }

      function setVisualizationSelect() {
        scope.visualizations = _.filter(scope.visualizations, function(v) {
          return !v.tab;
        });

        if (
          _.contains(pages.expenseCollections, stateName) ||
          _.contains(pages.incomeCollections, stateName)
        ) {
          addPageViz(stateName);
        }

        if (stateName === "students") {
          scope.vizSelection = _.find(scope.visualizations, function(v) {
            return v.value === "tuition-net";
          });
        } else {
          scope.vizSelection = scope.visualizations[0];
        }
      }

      scope.hide = function() {
        scope.mode.isVisible = false;
      };

      scope.show = function() {
        scope.mode.isVisible = true;
      };

      scope.$on("what-if-start", function() {
        scope.whatIfView = whatif.viewCurrent;
        scope.whatIfSave = whatif.save;
        scope.whatIfQuit = whatif.quit;
        scope.eventCount = whatif.eventCount;
        scope.whatIfScenarioName =
          whatif.exports.current && whatif.exports.current.name;
      });

      scope.$on("$stateChangeStart", function(
        event,
        toState,
        toParams,
        fromState
      ) {
        scope.banner.visible = false;
      });

      scope.$on("$stateChangeSuccess", function(
        event,
        toState,
        toParams,
        fromState
      ) {
        stateName = toState.name;
        if (!fromState.abstract) setVisualizationSelect();

        if (dataService.projection.length > 0) {
          setData();
        }

        if (
          _.contains(
            ["dashboard", "school", "compare", "settings"],
            toState.name
          )
        )
          scope.hide();
      });

      scope.$on("footer-data-change", function() {
        setData();
      });

      scope.$watch("vizSelection", function(previous, next) {
        if (previous !== next) setData();
      });

      if (_.contains(["dashboard", "school", "settings"], $state.current.name))
        scope.hide();
      setVisualizationSelect();
      setData();
      scope.banner = footerService.banner;
    }

    return {
      restrict: "E",
      link: link,
      scope: {},
      templateUrl: "./footer.html"
    };
  }
]);
