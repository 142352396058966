import angular from "angular";
import * as d3 from "d3";

angular.module("helme.graphs").directive("bulletChart", [
  "$timeout",
  function($timeout) {
    var m = [10, 0, 10, 10]; // margins
    var w = 550 - m[1] - m[3]; // width
    var h = 60 - m[0] - m[2]; // height
    var containerWidth;
    var chart;

    // Chart design based on the recommendations of Stephen Few. Implementation
    // based on the work of Clint Ivy, Jamie Love, and Jason Davies.
    // http://projects.instantcognition.com/protovis/bulletchart/
    d3.bullet = function() {
      var orient = "left",
        // TODO top & bottom
        reverse = false,
        duration = 0,
        ranges = bulletRanges,
        measures = bulletMeasures,
        width = 380,
        height = 40,
        tickFormat = null;

      // For each small multiple…
      function bullet(g) {
        g.each(function(d, i) {
          var rangez = ranges
              .call(this, d, i)
              .slice()
              .sort(d3.descending),
            measurez = measures
              .call(this, d, i)
              .slice()
              .reverse(),
            g = d3.select(this);

          var tickz = [0].concat(rangez).concat(measurez);

          // Compute the new x-scale.
          var x1 = d3.scale
            .linear()
            .domain([0, Math.max(rangez[0], measurez[0])])
            .range(reverse ? [width, 0] : [0, width]);

          // Retrieve the old x-scale, if this is an update.
          var x0 =
            this.__chart__ ||
            d3.scale
              .linear()
              .domain([0, Infinity])
              .range(x1.range());

          // Stash the new scale.
          this.__chart__ = x1;

          // Derive width-scales from the x-scales.
          var w0 = bulletWidth(x0),
            w1 = bulletWidth(x1);

          // Update the range rects.
          var range = g.selectAll("rect.range").data(rangez);

          range
            .enter()
            .append("rect")
            .attr("class", function(d, i) {
              return "range s" + i;
            })
            .attr("width", w0)
            .attr("height", height)
            .attr("x", reverse ? x0 : 0)
            .transition()
            .duration(duration)
            .attr("width", w1)
            .attr("x", reverse ? x1 : 0);

          range
            .transition()
            .duration(duration)
            .attr("x", reverse ? x1 : 0)
            .attr("width", w1)
            .attr("height", height);

          // Update the measure rects.
          var measure = g.selectAll("rect.measure").data(measurez);

          measure
            .enter()
            .append("rect")
            .attr("class", function(d, i) {
              return "measure s" + i + (d > rangez[0] ? " completed" : "");
            })
            .attr("width", w0)
            .attr("height", function(d, i) {
              return ((i + 3) * height) / 3;
            })
            .attr("x", reverse ? x0 : 0)
            .attr("y", function(d, i) {
              return height / (3 * (i + 1));
            })
            .transition()
            .duration(duration)
            .attr("width", w1)
            .attr("x", reverse ? x1 : 0);

          measure
            .transition()
            .duration(duration)
            .attr("width", w1)
            .attr("height", function(d, i) {
              return ((2 - i) * height) / 3;
            })
            .attr("x", reverse ? x1 : 0)
            .attr("y", function(d, i) {
              return height / (3 * (2 - i));
            });

          // Compute the tick format.
          var format = tickFormat || x1.tickFormat(8);

          // Update the tick groups.
          var tick = g.selectAll("g.tick").data(tickz);

          // Initialize the ticks with the old scale, x0.
          var tickEnter = tick
            .enter()
            .append("g")
            .attr("class", "tick")
            .attr("transform", bulletTranslate(x0))
            .style("opacity", 1e-6);

          tickEnter
            .append("line")
            .attr("y1", height)
            .attr("y2", (height * 7) / 6);

          tickEnter
            .append("text")
            .attr("text-anchor", "middle")
            .attr("dy", "1em")
            .attr("y", (height * 7) / 6)
            .text(function(d) {
              return window.formatDollarValue(d, true);
            });

          // Transition the entering ticks to the new scale, x1.
          tickEnter
            .transition()
            .duration(duration)
            .attr("transform", bulletTranslate(x1))
            .style("opacity", 1);

          // Transition the updating ticks to the new scale, x1.
          var tickUpdate = tick
            .transition()
            .duration(duration)
            .attr("transform", bulletTranslate(x1))
            .style("opacity", 1);

          tickUpdate
            .select("line")
            .attr("y1", height)
            .attr("y2", (height * 7) / 6);

          tickUpdate.select("text").attr("y", (height * 7) / 6);

          // Transition the exiting ticks to the new scale, x1.
          tick
            .exit()
            .transition()
            .duration(duration)
            .attr("transform", bulletTranslate(x1))
            .style("opacity", 1e-6)
            .remove();
        });
        d3.timer.flush();
      }

      // left, right, top, bottom
      bullet.orient = function(x) {
        if (!arguments.length) return orient;
        orient = x;
        reverse = orient == "right" || orient == "bottom";
        return bullet;
      };

      // ranges (bad, satisfactory, good)
      bullet.ranges = function(x) {
        if (!arguments.length) return ranges;
        ranges = x;
        return bullet;
      };

      // measures (actual, forecast)
      bullet.measures = function(x) {
        if (!arguments.length) return measures;
        measures = x;
        return bullet;
      };

      bullet.width = function(x) {
        if (!arguments.length) return width;
        width = x;
        return bullet;
      };

      bullet.height = function(x) {
        if (!arguments.length) return height;
        height = x;
        return bullet;
      };

      bullet.tickFormat = function(x) {
        if (!arguments.length) return tickFormat;
        tickFormat = x;
        return bullet;
      };

      bullet.duration = function(x) {
        if (!arguments.length) return duration;
        duration = x;
        return bullet;
      };

      return bullet;
    };

    function bulletRanges(d) {
      return d.ranges;
    }

    function bulletMeasures(d) {
      return d.measures;
    }

    function bulletTranslate(x) {
      return function(d) {
        return "translate(" + x(d) + ",0)";
      };
    }

    function bulletWidth(x) {
      var x0 = x(0);
      return function(d) {
        return Math.abs(x(d) - x0);
      };
    }

    function draw(data, el) {
      containerWidth = el.clientWidth;
      w = containerWidth - m[1] - m[3];
      chart = d3
        .bullet()
        .width(w)
        .height(h - 10);

      d3.select(el)
        .selectAll("svg")
        .remove();

      var svg = d3.select(el).selectAll("svg");

      svg
        .data(data)
        .enter()
        .append("svg")
        .attr("class", "bullet")
        .attr("width", w + m[0] + m[2])
        .attr("height", h + m[1] + m[3])
        .append("g")
        .attr("transform", "translate(" + m[0] + "," + m[1] + ")")
        .call(chart);
    }

    function link(scope, element, attribs) {
      if (!!scope.data) {
        $timeout(function() {
          draw(scope.data, element[0]);
        }, 50);
      }

      scope.$watch("data", function(previous, next) {
        if (previous !== next) {
          draw(scope.data, element[0]);
        }
      });
      scope.$on("tile-resize", function() {
        if (element[0].clientWidth !== containerWidth)
          draw(scope.data, element[0]);
      });
    }

    return {
      restrict: "E",
      replace: true,
      link: link,
      scope: {
        data: "="
      },
      template: " <div class='bullet'> </div>"
    };
  }
]);
