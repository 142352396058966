import _ from "underscore";
import angular from "angular";

angular
  .module("helme.input")
  .directive("valueInput", [
    "$rootScope",
    "$timeout",
    "$document",
    "formatService",
    "sessionService",
    "ENV",
    "highlightService",
    function(
      $rootScope,
      $timeout,
      $document,
      formatService,
      sessionService,
      ENV,
      highlightService
    ) {
      function fixCaretPosition(
        elem,
        type,
        value,
        display,
        oldDisplay,
        oldPosition
      ) {
        var specialCount = 0;
        for (var x = 0; x < oldPosition; x++) {
          if (oldDisplay[x] === "," || oldDisplay[x] === "$") specialCount++;
        }
        var position = oldPosition - specialCount;
        elem[0].setSelectionRange(position, position);
      }

      function selectAllText(elem, value) {
        elem[0].setSelectionRange(0, value.toString().length);
      }

      function noDash($event) {
        if ($event.keyIdentifier === "U+002D") $event.preventDefault();
      }

      return {
        restrict: "E",
        replace: true,
        scope: {
          value: "=",
          max: "@",
          type: "@",
          enabled: "=?",
          onlyPositive: "=?",
          doubleclick: "=?",
          selectAll: "@?",
          disableProjection: "@?",
          readerEnabled: "@?",
          modelIgnore: "@?",
          syncId: "@?",
          cb: "&?",
          blurCb: "&?",
          focusCb: "&?",
          displayBlurCb: "&?"
        },
        templateUrl: "./valueInput.html",
        link: function(scope, element, attrs) {
          function format(value) {
            if (value !== 0 && !value) return "";
            if (scope.type === "%") return value + "%";
            else if (scope.type === "$")
              return formatService.formatDollarValue(value, false);
            return "" + value;
          }

          if (_.isUndefined(scope.enabled)) scope.enabled = true;

          scope.display = format(scope.value);
          format(3);

          scope.viewOnly = sessionService.isViewOnly() && !scope.readerEnabled;

          if (scope.onlyPositive) scope.keydownValidate = noDash;
          else
            scope.keydownValidate = function() {
              return true;
            };

          scope.$watch("type", function(type) {
            scope.display = format(scope.value);
          });

          scope.$watch("display", function(display, previousDisplay) {
            if (display !== previousDisplay) {
              if (scope.type === "text") {
                if (!scope.modelIgnore) scope.value = display;
                if (!!scope.cb && !!scope.cb()) scope.cb()(scope.value);
              } else {
                var cleaned = scope.display.slice(0).replace(/$|,|\ |%/g, "");
                var parsed = parseFloat(cleaned);
                if (!isNaN(parsed) && parsed !== scope.value) {
                  if (!scope.modelIgnore) scope.value = parsed;
                  if (!!scope.cb && !!scope.cb()) scope.cb()(parsed);
                }
              }
              scope.display = display;

              if (scope.disableProjection)
                $timeout(function() {
                  $rootScope.$broadcast("cancel-projection");
                }, 0);
            }
          });
          scope.$watch("value", function(value, previousValue) {
            if (
              (value !== previousValue && !scope.focused) ||
              scope.display === "" ||
              scope.display === "undefined"
            ) {
              if (!scope.modelIgnore) scope.value = value;
              scope.display = format(value);
            }
          });

          scope.syncFocused = function() {
            if (!scope.syncId) return false;
            return highlightService.hasFocus(scope.syncId);
          };

          scope.focus = function() {
            scope.focused = true;
            highlightService.focus(scope.syncId);
            $timeout(function() {
              var oldDisplay = _.clone(scope.display);
              var el = element.find("input")[0];
              var selectionStart = el.selectionStart;
              var selectionEnd = el.selectionEnd;
              if (typeof scope.display === "string" && scope.type !== "text")
                scope.display = scope.display.replace(/([\$,%])/g, "");
              scope.oldValue = _.clone(scope.value);
              $timeout(function() {
                var el = element.find("input");
                if (scope.selectAll) selectAllText(el, scope.value);
                else if (selectionStart === selectionEnd)
                  fixCaretPosition(
                    el,
                    scope.type,
                    scope.value,
                    scope.display,
                    oldDisplay,
                    selectionStart
                  );
                else el[0].setSelectionRange(selectionStart, selectionEnd);
              }, 0);
            }, 0);
            $document.bind("keydown", function(event) {
              if (event.keyCode === 13) element.find("input")[0].blur();
            });
            if (!!scope.focusCb) scope.focusCb();
          };

          scope.blur = function() {
            scope.focused = false;
            highlightService.blur(scope.syncId);
            if (scope.value !== scope.oldValue) scope.$emit("push-state");
            if (!!scope.displayBlurCb && !!scope.displayBlurCb()) {
              scope.displayBlurCb()(scope.display);
            }
            scope.display = format(scope.value);
            $document.unbind("keydown");
            if (!!scope.blurCb && !!scope.blurCb()) {
              scope.blurCb()(scope.value, scope.oldValue);
            }
          };
        }
      };
    }
  ])
  .directive("focusOn", [
    "$timeout",
    "$rootScope",
    function($timeout, $rootScope) {
      return {
        restrict: "A",
        scope: {
          focusValue: "=focusOn"
        },
        link: function($scope, $element, attrs) {
          $scope.$watch("focusValue", function(currentValue, previousValue) {
            if (currentValue === true && !previousValue) {
              $element[0].focus();
            } else if (currentValue === false && previousValue) {
              $element[0].blur();
            }
          });
        }
      };
    }
  ])
  .directive("ngEnter", function() {
    return function(scope, element, attrs) {
      element.bind("keydown keypress", function(event) {
        if (event.which === 13) {
          scope.$apply(function() {
            scope.$eval(attrs.ngEnter);
          });

          event.preventDefault();
        }
      });
    };
  });
