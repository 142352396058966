/* @flow weak */

import angular from "angular";

angular.module("helme.budgetCollection").controller("CollectionCtrl", [
  "$scope",
  "$rootScope",
  "dataService",
  "collectionService",
  "sessionService",
  "pages",
  "models",
  "treeService",
  "footerService",
  "ENV",
  function(
    $scope,
    $rootScope,
    dataService,
    collectionService,
    sessionService,
    pages,
    models,
    treeService,
    footerService,
    ENV
  ) {
    $scope.root = {
      children: []
    };

    function setup() {
      collectionService.setup();
      $scope.title = collectionService.title;
      $scope.type = collectionService.type;
      $scope.root = collectionService.root;

      $scope.viewOnly = sessionService.isViewOnly();

      $scope.modeName = $scope.viewOnly ? "View" : "Edit";
      $scope.mode = sessionService.mode;

      footerService.bannerText =
        "Editing the " + ENV.prettyYears[1] + " school year.";
      footerService.showBanner = $scope.mode.year === "next";
    }

    sessionService.ready.promise.then(setup);

    $scope.$on("collection-loaded", setup);

    $scope.update = collectionService.updateCollection;
  }
]);
