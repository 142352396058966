import _ from "underscore";
import angular from "angular";

angular.module("helme.budgetCollection").controller("LinkItemCtrl", [
  "$rootScope",
  "dataService",
  "linkItemService",
  "$scope",
  "item",
  "ledger",
  "ENV",
  "metrics",
  function(
    $rootScope,
    dataService,
    linkItemService,
    $scope,
    item,
    ledger,
    ENV,
    metrics
  ) {
    var linkingBefore = null;
    $scope.currentYear = ENV.currentYear;
    $scope.prettyYears = ENV.prettyYears;
    $scope.range = _.range;
    $scope.linkTypes = linkItemService.linkTypes;
    $scope.prettyTotal = linkItemService.prettyTotal;

    $scope.linkDisplay = function(l) {
      return l.name;
    };

    $scope.showEnrollment = function() {
      return (
        linkItemService.exports.linking.type === "enrollment-total" ||
        linkItemService.exports.linking.type === "enrollment-grade"
      );
    };

    $scope.allowSubmit = function() {
      return linkItemService.exports.linking.type !== "none";
    };

    $scope.percentChange = function(year) {
      return function(value) {
        linkItemService.exports.linking.percents[year] = value;
        if (year < 8) $scope.percentChange(year + 1)(value);
      };
    };

    $scope.multipleChange = function(year) {
      return function(value) {
        linkItemService.exports.linking.multiples[year] = value;
        if (year < 8) $scope.multipleChange(year + 1)(value);
      };
    };

    $scope.submit = function() {
      if ($scope.allowSubmit) {
        linkItemService.exports.item.linking = linkItemService.exports.linking;
        $scope.$close(linkItemService.exports.item);
        ledger.events.item.linking.log(
          item,
          linkingBefore,
          angular.copy(linkItemService.exports.item.linking)
        );
        $rootScope.$broadcast("budget-changed");
      }
    };

    $scope.unitHeader = function() {
      return "Per-student budget";
    };

    $scope.enrollmentDisplay = function(year) {
      if (
        linkItemService.exports.linking.type !== "enrollment-grade" &&
        linkItemService.exports.linking.type !== "enrollment-total"
      ) {
      }
      var enrollment = 0;
      if (
        linkItemService.exports.linking.type === "enrollment-grade" &&
        !!linkItemService.exports.linking.grade
      ) {
        enrollment =
          $scope.gradeEnrollmentTotals[year][
            linkItemService.exports.linking.grade
          ];
      }
      if (linkItemService.exports.linking.type === "enrollment-total") {
        enrollment = $scope.enrollmentTotals[year];
      }
      return (
        Math.round(
          (enrollment * linkItemService.exports.linking.percents[year]) / 100
        ) +
        " / " +
        enrollment
      );
    };

    function setup() {
      linkItemService.init(item);
      $scope.enrollmentTotals = linkItemService.enrollmentTotals;
      $scope.gradeEnrollmentTotals = linkItemService.gradeEnrollmentTotals;
      $scope.tuitionTotals = linkItemService.tuitionTotals;
      $scope.grades = linkItemService.grades;

      $scope.startYear = !!item.start ? item.start : 0;
      $scope.endYear = !!item.end ? item.end : 8;

      linkingBefore = angular.copy(item.linking);

      $scope.linkService = linkItemService.exports;
    }
    setup();
  }
]);
