import angular from "angular";

angular.module("helme.pages").controller("StudentCtrl", [
  "$scope",
  "$rootScope",
  "sessionService",
  "collectionService",
  "tuitionService",
  function(
    $scope,
    $rootScope,
    sessionService,
    collectionService,
    tuitionService
  ) {
    $scope.mode = tuitionService.mode;
    $scope.yearMode = "current";
    $scope.setAllValue = 0;
    $scope.viewOnly = sessionService.isViewOnly();
    $scope.sessionMode = sessionService.mode;

    $scope.$watch(
      function() {
        return [tuitionService.enrollment.classes, $scope.root];
      },
      function(newVal, oldVal) {
        if (newVal !== oldVal) {
          $rootScope.$broadcast("budget-changed");
        }
      },
      true
    );

    function setup() {
      console.info("Setting up");
      $scope.enrollment = tuitionService.enrollment;

      collectionService.ready.promise.then(function() {
        console.info("Setting root", collectionService.root);
        $scope.root = collectionService.root;
        $scope.type = collectionService.type;
      });
    }

    $scope.addItem = collectionService.addItem;
    $scope.addCategory = collectionService.addCategory;

    $scope.showEnrollment = function() {
      $scope.mode.view = "enrollment";
    };
    $scope.showRates = function() {
      $scope.mode.view = "rates";
    };

    let initialized = false;
    $scope.$watch(
      function() {
        return sessionService.mode.tuitionView;
      },
      function(val, old) {
        if (val !== old || !initialized) {
          collectionService.setup();
          $scope.root = collectionService.root;
          $rootScope.$broadcast("tab-switch");
          initialized = true;
        }
      }
    );

    tuitionService.ready.promise.then(setup);
  }
]);
