import angular from "angular";

angular.module("helme.input").directive("seriesInput", [
  "$rootScope",
  "$timeout",
  "$document",
  "formatService",
  "sessionService",
  "ENV",
  function(
    $rootScope,
    $timeout,
    $document,
    formatService,
    sessionService,
    ENV
  ) {
    return {
      restrict: "E",
      replace: true,
      scope: {
        series: "=",
        label: "=?",
        cb: "&",
        parsed: "=?",
        setOnBlur: "&?"
      },
      templateUrl: "./series-input.html",
      link: function(scope, element, attrs) {
        scope.strValue = scope.series.join(", ");
        scope.parsed = scope.series;
        scope.$watch("strValue", function(value, old) {
          if (value !== old) {
            var str = value.slice(0);
            str = str.replace(/\ /g, "");
            if (str[str.length - 1] === ",") str = str.slice(0, str.length - 1);
            var series = str.split(",").map(Number);
            scope.parsed = series;
            if (!!scope.cb) scope.cb(series);
          }
        });
        scope.onblur = function() {
          if (scope.setOnBlur) scope.series = scope.parsed;
        };
      }
    };
  }
]);
