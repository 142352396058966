import _ from "underscore";
import angular from "angular";

angular.module("helme.export").controller("PrintExportCtrl", [
  "$scope",
  "dataService",
  "Session",
  "metrics",
  "formatService",
  "ENV",
  "exportService",
  "pages",
  function(
    $scope,
    dataService,
    Session,
    metrics,
    formatService,
    ENV,
    exportService,
    pages
  ) {
    var overview, budget, tuition;
    var breakNodes = [];
    var n = 3;
    var collapsedNodes =
      JSON.parse(localStorage.getItem("export-collapsed")) || {};

    $scope.view = "loading";
    $scope.exporting = false;
    $scope.currentYear = ENV.currentYear;
    $scope.prettyYears = ENV.prettyYears;

    function fullOverview() {
      var o = metrics.overview(dataService.projection);
      var tuitionGross = metrics.projectFn("tuition-gross");
      var tuitionNet = metrics.projectFn("tuition-net");
      o.income = _.map(o.income, function(val, i) {
        return val - tuitionNet[i] + tuitionGross[i];
      });
      o.expense = _.map(o.expense, function(val, i) {
        return val - tuitionGross[i] + tuitionNet[i];
      });
      o["tuition-net"] = tuitionNet;
      o["tuition-gross"] = tuitionGross;
      return o;
    }

    var makeBreakNodes = function(node, index) {
      if (index === 0) {
        breakNodes.push(node.id);
        index = 55;
      } else index--;
      if (!!node && !!node.children) {
        _.forEach(node.children, function(child) {
          index = makeBreakNodes(child, index);
        });
      }

      return index;
    };

    $scope.breakNode = function(nodeId) {
      if ($scope.printing) return _.contains(breakNodes, nodeId);
    };

    $scope.nodeValues = function(itemId, reduction) {
      var values = [];
      if (
        itemId === "income" ||
        itemId === "expense" ||
        itemId === "net" ||
        itemId === "tuition-net" ||
        itemId === "tuition-gross"
      ) {
        values = overview[itemId].slice(0, n);
      } else values = metrics.getItemProjectionById(itemId).slice(0, n);
      return _.map(values, function(value) {
        var val = formatService.formatDollarValue(value);
        if (reduction) val = "(" + val + ")";
        return val;
      });
    };

    $scope.downloadPDF = function() {
      $scope.view = "exporting";
      $scope.exporting = true;
      setTimeout(function() {
        var exportNode = document.getElementById("export");
        var content = exportNode.innerHTML;
        $scope.exporting = false;
        $scope.view = "loading";
        exportService.pdf(content).then(
          function(filename) {
            $scope.pdfFilename = filename;
            $scope.view = "success";
          },
          function(err) {
            $scope.view = "error";
            $scope.error = err;
          }
        );
      }, 50);
    };

    function setup() {
      budget = dataService.projection[0];
      $scope.income = _.filter(_.values(budget.income.categories), function(
        category
      ) {
        var c = _.find(pages.income, function(incomeCat) {
          return incomeCat.key + "_0" === category.id;
        });
        return c && !c.hidden;
      });
      $scope.expense = _.filter(_.values(budget.expense.categories), function(
        category
      ) {
        var c = _.find(pages.expense, function(expenseCat) {
          return expenseCat.key + "_0" === category.id;
        });
        return c && !c.hidden;
      });
      $scope.tuition = [
        {
          name: "Net Tuition",
          id: "tuition-net",
          children: [
            {
              name: "Gross Tuition",
              id: "tuition-gross",
              children: budget.tuition.tree.children
            },
            budget.tuition.reductions
          ]
        }
      ];

      overview = fullOverview();

      var index = 36;
      _.forEach($scope.income, function(cat) {
        index = makeBreakNodes(cat, index);
      });

      //if(includeTuition)
      _.forEach($scope.tuition, function(cat) {
        index = makeBreakNodes(cat, index);
      });

      _.forEach($scope.expense, function(cat) {
        index = makeBreakNodes(cat, index);
      });

      $scope.view = "report";
    }

    $scope.toggleCollapse = function(node) {
      if (collapsedNodes[node.id]) delete collapsedNodes[node.id];
      else collapsedNodes[node.id] = node;
      localStorage.setItem("export-collapsed", JSON.stringify(collapsedNodes));
    };

    $scope.isCollapsed = function(nodeId) {
      return collapsedNodes[nodeId];
    };

    $scope.getCollapsed = function() {
      return _.values(collapsedNodes);
    };

    $scope.clearCollapsed = function() {
      collapsedNodes = {};
      localStorage.setItem("export-collapsed", "{}");
    };

    setup();

    $scope.yearRange = _.range(n);

    $scope.school = Session.school.name;
  }
]);
