import _ from "underscore";
import angular from "angular";

angular.module("helme.input").directive("helmeDrop", [
  "sessionService",
  function(sessionService) {
    return {
      restrict: "E",
      replace: true,
      scope: {
        items: "=",
        onChange: "&",
        model: "=?",
        key: "@",
        display: "&",
        double: "@?",
        bodyAppend: "@?",
        selectTitle: "@?",
        addEntry: "=?"
      },
      templateUrl: "./dropdown.html",
      link: function(scope, element) {
        scope.visible = false;
        if (!scope.display()) scope.display = _.identity;

        scope.topDisplay = function() {
          if (!!scope.model) {
            var items = scope.items;
            if (scope.addEntry) items = items.concat(scope.addEntry);
            var item = _.find(items, scope.isActive);
            if (!!item) {
              return scope.view(item);
            }
          }
          return scope.selectTitle;
        };

        scope.isActive = function(item) {
          if (!!scope.key) return scope.model === item[scope.key];
          return _.isEqual(scope.model, item);
        };

        scope.toggle = function() {
          if (!scope.double) {
            var button = element[0].querySelector(".dropdown-button");
            var menu = element[0].querySelector(".dropdown-menu");
            menu.style.minWidth = button.offsetWidth + "px";
            menu.style.width = "auto";
          }
        };

        scope.view = function(item) {
          if (scope.display()) {
            return scope.display()(item);
          }
          if (_.isObject(item) && (item.display || item.name)) {
            return item.display || item.name;
          }
          return item;
        };

        scope.select = function(item) {
          var value = !!scope.key ? item[scope.key] : item;
          scope.model = value;
          if (!!scope.onChange()) scope.onChange()(value);
        };
        scope.isSelected = function(item) {
          if (!!scope.key)
            return !!scope.current && item[scope.key] === scope.model;
          return scope.model === item;
        };
      }
    };
  }
]);
