import _ from "underscore";
import angular from "angular";

angular.module("helme.iq", []).service("iq", [
  "dataService",
  "metrics",
  function(dataService, metrics) {
    var service = {};

    service.tips = [];

    service.analyze = function() {
      var enrollmentGrowth = metrics.enrollmentGrowth();
      var averageGrowth = _.reduce(
        enrollmentGrowth,
        function(acc, avg) {
          return acc + avg / enrollmentGrowth.length;
        },
        0
      );
    };

    return service;
  }
]);
