import angular from "angular";

angular.module("helme.export").controller("ExportModalCtrl", [
  "$scope",
  "exportService",
  function($scope, exportService) {
    $scope.dashboardPrint = function() {
      var main = document.getElementById("dashboard");
      exportService.print(main.innerHTML);
    };

    $scope.printExport = function() {
      exportService.printExport();
      $scope.$close();
    };
    $scope.excelExport = function() {
      $scope.loading = true;
      exportService.excelExport().result.then(
        function() {
          $scope.$close();
          $scope.loading = false;
        },
        function() {}
      );
    };
  }
]);
