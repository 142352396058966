import angular from "angular";

angular.module("helme.util", []).service("formatService", function() {
  var service = {};

  service.formatPercentage = function(value) {
    var rounded = "" + Math.round(value * 1000) / 10;
    return rounded + "%";
  };

  service.humanNumber = window.humanNumber;
  service.formatDollarValue = window.formatDollarValue;
  service.formatShortDollarValue = value =>
    window.formatDollarValue(value, true);

  service.formatDate = function(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1; //January is 0!

    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return mm + "/" + dd + "/" + yyyy;
  };

  service.formatFn = function(type) {
    if (type === "$") return service.formatShortDollarValue;
    if (type === "$$") return service.formatDollarValue;
    if (type === "%") return service.formatPercentage;
    return service.humanNumber;
  };

  return service;
});

window.humanNumber = function(value, shorten) {
  var rounded = Math.abs(Math.round(value * 100) / 100);
  var str = "" + rounded;
  if (shorten) {
    if (1000 <= rounded && rounded < 1000000) {
      rounded = rounded / 1000;
      str = rounded.toString().substr(0, rounded >= 1000 ? 3 : 4);
      str += "k";
    } else if (1000000 <= rounded) {
      rounded = rounded / 1000000;
      str = rounded.toString().substr(0, rounded >= 100 ? 3 : 4);
      str += "m";
    }
  }
  if (!shorten) {
    var decimal = "";
    if (rounded % 1 > 0) {
      decimal = Math.round((rounded % 1) * 100);
      str = "" + Math.floor(value);
    }
    var rev = str
      .split("")
      .reverse()
      .join("");
    var s = "";
    for (var x = 0; x < rev.length; x++) {
      if (x % 3 === 0 && x != 0) s = "," + s;
      s = rev[x] + s;
    }
    str = s;
    // if (decimal > 0) {
    //   str += ".";
    //   if (decimal < 10) str += "0";
    //   str += decimal;
    // }
  }
  return str;
};

window.formatDollarValue = function(value, shorten) {
  var str = window.humanNumber(Math.abs(value), shorten);
  if (value >= 0) str = "$" + str;
  else str = "($" + str + ")";
  return str;
};
