import _ from "underscore";
import angular from "angular";

angular
  .module("helme.budgetCollection")
  .service("expandService", function() {
    var service = {};

    var expandList = {};

    service.isExpanded = function(item) {
      return item.id in expandList;
    };

    service.toggleChildrenVisibility = function(item) {
      if (!service.isExpanded(item)) expandList[item.id] = true;
      else delete expandList[item.id];
    };

    service.show = function(item) {
      expandList[item.id] = true;
    };

    return service;
  })
  .value("dropdownId", "")
  .value("yearMode", "current")
  .service("treeService", [
    "models",
    "sessionService",
    "dataService",
    "$rootScope",
    "ledger",
    "inspectService",
    function(
      models,
      sessionService,
      dataService,
      $rootScope,
      ledger,
      inspectService
    ) {
      var service = {};

      service.remove = function(collection, item) {
        var count = collection.length;
        var filtered = _.filter(collection, function(child) {
          return child.id !== item.id;
        });
        if (filtered.length < count) return filtered;
        else
          _.forEach(collection, function(child) {
            if (!!child.children)
              child.children = service.remove(child.children, item);
          });
        return collection;
      };

      service.addChildItem = function(parent, id) {
        var child = models.newItem();
        if (!!id) child.id = id;
        dataService.paths[child.id] = dataService.paths[parent.id].concat([
          child.id
        ]);
        dataService.itemTypes[child.id] = service.type;
        parent.children.unshift(child);
        setTimeout(function() {
          if (!sessionService.mode.replaying) inspectService.select(child);
        }, 50);
        ledger.events.item.add.log(child, parent);
        $rootScope.$broadcast("push-state");
        return child;
      };
      service.addChildCategory = function(parent, id) {
        var child = models.newCategory();
        if (!!id) {
          child.id = id;
        }
        dataService.paths[child.id] = dataService.paths[parent.id].concat([
          child.id
        ]);
        dataService.itemTypes[child.id] = service.type;
        parent.children.unshift(child);
        setTimeout(function() {
          if (!sessionService.mode.replaying) inspectService.select(child);
        }, 50);
        ledger.events.category.add.log(child.id, parent);
        $rootScope.$broadcast("push-state");
      };

      service.removeDuplicates = function(collection) {
        var d = {};
        var removeList = [];
        _.forEach(collection, function(item) {
          if (d[item.id]) removeList.push(item);
          else d[item.id] = true;
        });
        _.forEach(removeList, function(item) {
          collection = service.remove(collection, item);
        });
        _.forEach(collection, function(item) {
          if (!!item.children)
            item.children = service.removeDuplicates(item.children);
        });
        return collection;
      };

      return service;
    }
  ]);
