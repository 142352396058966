import angular from "angular";

angular.module("helme.sync").service("highlightService", [
  "$rootScope",
  function($rootScope) {
    var service = {};

    var focused = {};

    service.process = function(event) {
      if (event.id === "focus") {
        focused[event.elementId] = true;
      }
      if (event.id === "blur") {
        delete focused[event.elementId];
      }
    };

    service.hasFocus = function(id) {
      return focused[id];
    };

    service.focus = function(id) {
      service.emit(id, "focus");
    };

    service.blur = function(id) {
      service.emit(id, "blur");
    };

    service.emit = function(id, type) {
      var event = {
        id: type,
        elementId: id,
        interface: true
      };
      $rootScope.$broadcast("interface-event", event);
    };

    return service;
  }
]);
