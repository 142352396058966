import _ from "underscore";
import angular from "angular";

angular
  .module("helme.pages")
  .service("dashboardService", [
    "$q",
    "$timeout",
    "$rootScope",
    "dataService",
    "sessionService",
    "tileService",
    "metrics",
    "api",
    "Session",
    "guid",
    "$modal",
    "ENV",
    "tagService",
    "notify",
    "exportService",
    function(
      $q,
      $timeout,
      $rootScope,
      dataService,
      sessionService,
      tileService,
      metrics,
      api,
      Session,
      guid,
      $modal,
      ENV,
      tagService,
      notify,
      exportService
    ) {
      var service = {};

      service.ready = $q.defer();

      service.getComparisonData = function() {
        return api
          .compare()
          .then(function(res) {
            service.comparisons = _.mapObject(res.data, function(
              categories,
              name
            ) {
              return _.map(categories, function(values, catName) {
                return {
                  name: catName,
                  values: values
                };
              });
            });
            service.comparisonLabels = [Session.school.name, "Average"];
          })
          .catch(function() {
            console.info("Not enough schools to compare.");
          });
      };

      /* Possible Types:
     bullet - one year, multiple metrics
     single-line - multiple year, one metric
     donut - one year, one metric
     grouped-bar - multiple year, two metric
     */

      service.exports = {
        current: null,
        list: [],
        resolved: []
      };

      service.metricLibrary = metrics.library;

      function lastPosition(dashboard) {
        var maxRow = 0;
        var maxCol = 0;
        _.forEach(dashboard.tiles, function(w) {
          if (maxRow <= w.position.row) {
            var row = w.position.row;
            var col = w.position.col;
            if (!!w.position.sizeX) col += w.position.sizeX;
            else col += 1;
            if (col >= 3) {
              col = col % 3;
              row++;
            }
            maxRow = row;
            maxCol = col;
          }
        });
        return {
          row: maxRow,
          col: maxCol
        };
      }

      function showTileModal(isEditing, tile) {
        return $modal.open({
          templateUrl: "./create-modal.html",
          controller: "CreateModalCtrl",
          windowClass: "helme-modal",
          resolve: {
            config: function() {
              return {
                editing: isEditing,
                tile: tile
              };
            }
          }
        });
      }

      service.editTile = function(tile) {
        var modalInstance = showTileModal(true, angular.copy(tile));
        modalInstance.result.then(
          function(tile) {
            service.updateTile(tile);
            service.refreshTile(tile);
            service.saveDashboard();
          },
          function(err) {}
        );
      };

      service.addTile = function() {
        var modalInstance = showTileModal(false);
        modalInstance.result.then(
          function(tile) {
            service.initializeTile(tile);
          },
          function(err) {}
        );
      };

      service.initializeTile = function(tile) {
        tile.id = guid.make();
        tile.position = lastPosition(service.exports.current);

        service.exports.current.tiles.push(tile);

        $timeout(function() {
          var objDiv = document.getElementById("main");
          objDiv.scrollTop = objDiv.scrollHeight;
        }, 150);

        service.refreshAllTiles();

        $timeout(service.saveDashboard, 50);
      };

      service.addEntry = {
        _id: "add",
        title: "Add a new Dashboard"
      };

      service.dashboardChange = function(dashboard) {
        if (dashboard._id === "add") {
          service.dashboardCreate();
        } else {
          service.exports.current = dashboard;
          service.refreshAllTiles();
        }
      };

      service.dashboardCreate = function() {
        if (sessionService.isViewOnly()) {
          notify({
            message: "Sorry, you don't have permission to create a dashboard.",
            position: "right",
            duration: 1500
          });
        }
        var index = _.findIndex(service.exports.list, function(d) {
          return d._id === service.exports.current._id;
        });
        var modalInstance = $modal.open({
          templateUrl: "./create-dashboard-modal.html",
          controller: "CreateDashboardCtrl",
          windowClass: "helme-modal"
        });
        modalInstance.result.then(
          function(dashboard) {},
          function() {
            service.exports.current = service.exports.list[index];
          }
        );
      };

      service.dashboardEdit = function() {
        var modalInstance = $modal.open({
          templateUrl: "./edit-dashboard-modal.html",
          controller: "EditDashboardCtrl",
          windowClass: "helme-modal",
          resolve: {
            dashboard: function() {
              return service.exports.current;
            }
          }
        });
        modalInstance.result.then(function(tile) {
          service.saveDashboard();
        });
      };

      service.removeTile = function(tile) {
        service.exports.current.tiles = _.filter(
          service.exports.current.tiles,
          function(w) {
            return w.id !== tile.id;
          }
        );
        service.exports.resolved = _.filter(service.exports.resolved, function(
          w
        ) {
          return w.id !== tile.id;
        });
        service.saveDashboard();
      };

      service.refreshAllTiles = function() {
        if (!_.isEmpty(dataService.state) && !!service.exports.current) {
          service.exports.resolved = _.map(
            service.exports.current.tiles,
            function(tile) {
              return tileService.resolveTile(tile);
            }
          );
        }
      };
      service.calcMaster = function() {
        service.master = metrics.masterTable(dataService.projection, true);
      };

      service.refreshTile = function(tile) {
        var resolved = tileService.resolveTile(tile);
        var index = _.findIndex(service.exports.resolved, function(
          resolvedTile
        ) {
          return tile.id === resolvedTile.id;
        });
        service.exports.resolved[index] = resolved;
      };

      service.removeDashboard = function(dashboard) {
        service.exports.list = _.filter(service.exports.list, function(d) {
          return d._id !== dashboard._id;
        });
        service.exports.current = service.exports.list[0];
        service.refreshAllTiles();
        return api.dashboard.remove(dashboard._id);
      };

      service.resetAll = function() {
        _.forEach(service.exports.list, function(dashboard) {
          api.dashboard.remove(dashboard._id);
        });
        service.exports.list = [];
        return api.dashboard.create("Head of School Dashboard", false).then(
          function(res) {
            service.exports.list.push(res.data.dashboard);
            service.exports.current = res.data.dashboard;
            service.refreshAllTiles();
          },
          function() {}
        );
      };

      service.saveDashboard = function() {
        return api.dashboard.update(service.exports.current);
      };

      service.findTile = function(id) {
        return _.find(service.exports.current.tiles, function(tile) {
          return tile.id === id;
        });
      };

      service.updateTile = function(tile) {
        var index = _.findIndex(service.exports.current.tiles, function(w) {
          return w.id === tile.id;
        });
        service.exports.current.tiles[index] = tile;
      };

      service.goFullscreen = function() {
        var gridster = document.getElementsByClassName(
          "mdl-layout__content"
        )[0];
        sessionService.mode.drawerVisible = false;
        if (!!BigScreen && BigScreen.enabled) {
          BigScreen.request(gridster, null, function() {
            $timeout(function() {
              $rootScope.$broadcast("tile-resize");
            }, 1000);
          });
          $timeout(function() {
            $rootScope.$broadcast("tile-resize");
          }, 1000);
        }
        BigScreen.onexit = function() {
          sessionService.mode.drawerVisible = true;
        };
      };

      function checkReady() {
        if (!!service.exports.current && service.exports.list.length > 0) {
          service.ready.resolve();
        }
      }

      function clearDashboard(result) {
        api.dashboard.reset().then(function(res) {
          service.exports.list = [res.data.dashboard];
          service.exports.current = _.last(service.exports.list);
          checkReady();
        });
      }
      function filterListByUserType(res) {
        if (
          Session.user.type === "viewer" &&
          Session.user.dashboard !== "all"
        ) {
          return _.filter(res.list, function(d) {
            return d._id === Session.user.dashboard;
          });
        }
        return res.list;
      }

      function getDashboardList() {
        return api.dashboard.list().then(function(res) {
          service.exports.list = filterListByUserType(res.data);
          service.exports.current = _.first(service.exports.list);
          checkReady();
        });
      }

      service.setup = function() {
        getDashboardList();
      };

      sessionService.ready.promise.then(function() {
        tagService.ready.promise.then(service.setup);
      });

      return service;
    }
  ])
  .service("tileService", [
    "dataService",
    "metrics",
    "api",
    function(dataService, metrics, api) {
      var service = {};

      service.metricChange = function(tile) {
        var metric = metrics.getMetric(tile.data.fn);
        var types = service.getChartTypes(tile);

        tile.title = metric.name;
        tile.vizType = types[0];
      };

      service.validChartTypes = {
        series: ["single-line", "grouped-bar", "number"],
        "labelled-series": ["pie", "grouped-bar", "horizontal-bar"],
        overview: ["overview"],
        pnl: ["pnl"],
        "double-series": ["single-line"],
        actual: ["number"],
        tag: ["overview"]
      };

      service.getChartTypes = function(tile) {
        if (!tile || !tile.data) return [];
        var metric = metrics.getMetric(tile.data.fn);
        if (tile.data.fn === "fundraisers") return ["horizontal-bar"];
        if (tile.data.type === "manual" || tile.data.type === "category")
          return service.validChartTypes["series"];
        if (tile.data.type === "tag") return service.validChartTypes["tag"];
        return service.validChartTypes[metric.type];
      };

      service.vizTypeChange = function(tile, toType) {
        var fromType = tile.vizType;
        tile.vizType = toType;
      };

      service.selectTileType = function(tile, type) {
        if (type === tile.data.type) return;

        tile.vizType = "";
        tile.title = "New Tile";

        if (type === "manual") {
          tile.data = {
            type: "manual",
            ids: []
          };
        } else if (type === "metric") {
          tile.data = {
            type: "metric",
            fn: ""
          };
        } else if (type === "category") {
          tile.data = {
            type: "category",
            id: ""
          };
        } else {
          tile.data = {
            type: "tag",
            tag: ""
          };
        }
      };

      function getTileData(tile, budget, projection) {
        var data = [];
        if (tile.data.type === "manual") {
          if (tile.vizType === "grouped-bar") {
            if (!series) return metrics.formatComparisonData([[]], [], []);
            return metrics.formatComparisonData(
              _.pluck(series, "data"),
              _.pluck(series, "label")
            );
          }
          if (!series)
            return {
              series: [[]],
              labels: []
            };
          return {
            series: _.pluck(series, "data"),
            labels: _.pluck(series, "label")
          };
        } else if (tile.data.type === "metric") {
          var metric = metrics.getMetric(tile.data.fn);
          if (metric.type === "labelled-series") {
            if (tile.data.fn === "fundraisers")
              data = metric.fn(budget, tile.data.drive);
            else data = metric.fn(budget);
            return metrics.formatComparisonData(
              data.series,
              data.seriesLabels,
              data.axisLabels,
              data.total
            );
          } else if (metric.type === "double-series") {
            return {
              series: metric.fn(budget),
              labels: ["capacity", "enrollment"]
            };
          } else if (
            _.contains(["horizontal-bar", "pie", "donut", "pnl"], tile.vizType)
          ) {
            return metric.fn(budget);
          } else if (tile.vizType === "overview") {
            return metric.fn(projection);
          } else if (tile.vizType === "grouped-bar") {
            return metrics.formatComparisonData(
              [metrics.projectFn(tile.data.fn, projection)],
              [metric.name],
              null
            );
          } else {
            return {
              series: [metrics.projectFn(tile.data.fn, projection)],
              labels: [metric.name]
            };
          }
          return data;
        } else if (tile.data.type === "category") {
          var series = [
            metrics.getItemProjectionById(tile.data.id, budget, projection)
          ];
          if (tile.vizType === "grouped-bar")
            return metrics.formatComparisonData(series, [tile.title], null);
          else
            return {
              series: series,
              labels: [tile.data.name]
            };
        } else if (tile.data.type === "tag") {
          return metrics.tagOverview(tile.data.tag, projection);
        }
        return [];
      }

      service.selectCategory = function(tile, category) {
        tile.data.id = category.id;
        tile.title = category.name;
        var types = service.getChartTypes(tile);
        if (!_.contains(types, tile.vizType)) tile.vizType = types[0];
      };

      service.selectTag = function(tile, tag) {
        tile.data.tag = tag;
        tile.title = capitalize(tag);
        var types = service.getChartTypes(tile);
        if (!_.contains(types, tile.vizType)) tile.vizType = types[0];
      };

      function capitalize(str) {
        return str.replace(/\w\S*/g, function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
      }

      service.resolveTile = function(tile, budget, projection) {
        if (!budget) {
          budget = dataService.state;
        }
        if (!projection) {
          projection = dataService.projection;
        }
        var unit = "number";
        if (tile.data.type === "metric")
          unit = metrics.getMetric(tile.data.fn).unit;
        else if (tile.data.type === "category" || tile.data.type === "tile")
          unit = "$";
        var data = getTileData(tile, budget, projection);
        return {
          title: tile.title,
          vizType: tile.vizType,
          fn: tile.data.fn,
          id: tile.id,
          data: data,
          position: tile.position,
          unit: unit
        };
      };

      return service;
    }
  ]);
