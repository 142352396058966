import _ from "underscore";
import angular from "angular";

angular
  .module("helme.pages")
  .service("tuitionService", [
    "$rootScope",
    "$q",
    "sessionService",
    "dataService",
    "formatService",
    "metrics",
    "collectionService",
    "treeService",
    "defaultClassTree",
    function(
      $rootScope,
      $q,
      sessionService,
      dataService,
      formatService,
      metrics,
      collectionService,
      treeService,
      defaultClassTree
    ) {
      var service = {};

      service.ready = $q.defer();

      service.mode = {
        carryOver: true,
        carryDown: true,
        view: "rates",
        enrollmentView: "totals",
        allRatesVisible: false
      };

      service.enrollment = {
        classes: [],
        incoming: [],
        capacity: 0,
        tuitionGrowth: 0
      };

      service.setup = function() {
        service.enrollment = {
          classes: dataService.state.tuition.classes,
          incoming: dataService.state.tuition["incoming-classes"],
          capacity: dataService.state.tuition.capacity[1],
          tuitionGrowth: 0
        };
        service.years = dataService.state.tuition["incoming-classes"].length;
        service.createTree();
        service.ready.resolve();

        $rootScope.$broadcast("tuition-loaded");
        $rootScope.$on("full-state-change", service.setup);
      };

      sessionService.ready.promise.then(service.setup);

      service.grades = [
        "pre-k",
        "k",
        "1st",
        "2nd",
        "3rd",
        "4th",
        "5th",
        "6th",
        "7th",
        "8th",
        "9th",
        "10th",
        "11th",
        "12th"
      ];

      service.getEnrollment = function(classIndex, yearIndex, budget) {
        if (!budget) budget = dataService.state;
        if (yearIndex == 0)
          return budget.tuition.classes[classIndex].item.students;
        if (classIndex >= yearIndex)
          return budget.tuition.classes[classIndex - yearIndex].assumptions
            .students[yearIndex - 1];
        return budget.tuition["incoming-classes"][
          service.inIndexX(yearIndex, classIndex)
        ][service.inIndexY(yearIndex, classIndex)];
      };

      service.sortClasses = function(tuition) {
        return _.sortBy(tuition, function(grade) {
          return service.grades.indexOf(grade.name);
        });
      };

      service.inIndexX = function(yearIndex, classIndex) {
        return yearIndex - classIndex - 1;
      };

      service.inIndexY = function(yearIndex, classIndex) {
        if (
          !service.enrollment.incoming[service.inIndexX(yearIndex, classIndex)]
        ) {
          return 0;
        }
        var len =
          service.enrollment.incoming[service.inIndexX(yearIndex, classIndex)]
            .length - 1;
        return Math.abs(Math.abs(yearIndex - service.years) - len);
      };

      service.doGranularChange = function(gradeIndex, yearIndex, value) {
        if (_.isNaN(gradeIndex) || _.isNaN(yearIndex)) return;
        var classIndex = gradeIndex - yearIndex;
        if (yearIndex == 0)
          service.enrollment.classes[classIndex].item.students = value;
        else {
          service.enrollment.classes[classIndex].assumptions.students[
            yearIndex - 1
          ] = value;
        }
        if (
          service.mode.carryOver &&
          gradeIndex < service.enrollment.classes.length &&
          yearIndex < service.years
        )
          service.doGranularChange(gradeIndex + 1, yearIndex + 1, value);
      };

      service.doIncomingChange = function(classIndex, yearIndex, value) {
        var x = service.inIndexX(yearIndex, classIndex);
        var y = service.inIndexY(yearIndex, classIndex);
        service.enrollment.incoming[x][y] = value;
        if (
          service.mode.carryOver &&
          classIndex < service.enrollment.classes.length &&
          yearIndex < service.years
        )
          service.doIncomingChange(classIndex + 1, yearIndex + 1, value);
      };

      service.enrollmentChange = function(classIndex, yearIndex, value, carry) {
        var b = service.mode.carryOver;
        service.mode.carryOver = carry;
        if (yearIndex > classIndex)
          service.doIncomingChange(classIndex, yearIndex, value);
        else service.doGranularChange(classIndex, yearIndex, value);
        service.mode.carryOver = b;
      };

      function sum(coll) {
        return _.reduce(coll, function(acc, c) {
          return acc + c;
        });
      }

      service.averageGrowthRate = function() {
        var n =
          _.reduce(
            service.enrollment.classes,
            function(a, c) {
              return a + sum(c.assumptions.rate);
            },
            0
          ) / service.enrollment.classes.length;
        return Math.round(n * 100) / 800;
      };

      service.setAllRates = function(val) {
        if (!isNaN(parseFloat(val))) {
          _.each(service.enrollment.classes, function(c) {
            c.assumptions.rate = _.map(c.assumptions.rate, function() {
              return val;
            });
          });
        }
      };

      service.getTotals = () => {
        if (dataService.projection && dataService.projection.length > 0) {
          service.enrollment.tuitionGrowth = service.averageGrowthRate();
          var gross = dataService.projection[0].tuition.gross;
          var reductionTotal =
            dataService.projection[0].tuition.reductions.value;
          var net = gross - reductionTotal;

          return {
            gross,
            net,
            reductions: reductionTotal
          };
        } else {
          return {
            gross: 0,
            net: 0
          };
        }
      };

      service.getHeader = function() {
        const {gross, net, reductions} = service.getTotals();
        return (
          metrics.projectFn("enrollment")[0] +
          " students, " +
          formatService.formatDollarValue(net, true) +
          " (" +
          formatService.formatDollarValue(gross, true) +
          " - " +
          formatService.formatDollarValue(reductions, true) +
          ")"
        );
      };

      service.getEnrollmentHeader = function() {
        return metrics.projectFn("enrollment")[0] + " Students";
      };

      service.deltaValue = function(yearIndex, classIndex) {
        var current, next;
        if (yearIndex > classIndex) {
          current =
            service.enrollment.incoming[
              service.inIndexX(yearIndex, classIndex)
            ][service.inIndexY(yearIndex, classIndex)];
          next =
            service.enrollment.incoming[
              service.inIndexX(yearIndex + 1, classIndex + 1)
            ][service.inIndexY(yearIndex + 1, classIndex + 1)];
        } else if (yearIndex === 0) {
          current = service.enrollment.classes[classIndex].item.students;
          next =
            service.enrollment.classes[classIndex - yearIndex].assumptions
              .students[yearIndex];
        } else {
          current =
            service.enrollment.classes[classIndex - yearIndex].assumptions
              .students[yearIndex - 1];
          next =
            service.enrollment.classes[classIndex - yearIndex].assumptions
              .students[yearIndex];
        }
        if (_.isUndefined(next)) next = current;
        return next - current;
      };

      function doDeltaChange(classIndex, yearIndex, delta) {
        if (yearIndex > classIndex) {
          var startX = service.inIndexX(yearIndex + 1, classIndex + 1);
          var startY = service.inIndexY(yearIndex + 1, classIndex + 1);
          for (var i = 0; i < service.years - yearIndex; i++) {
            service.enrollment.incoming[startX][startY + i] += delta;
          }
        } else {
          for (var x = yearIndex; x < 8; x++) {
            service.enrollment.classes[
              classIndex - yearIndex
            ].assumptions.students[x] += delta;
          }
        }
      }

      service.deltaChange = function(classIndex, yearIndex) {
        return function(value) {
          var cleaned = value.slice(0).replace(/$|,|\ |%/g, "");
          value = parseFloat(value);
          var previous = service.deltaValue(yearIndex, classIndex);
          var delta = value - previous;
          doDeltaChange(classIndex, yearIndex, delta);
        };
      };

      service.actualRate = function(classIndex, yearIndex) {
        return formatService.formatDollarValue(
          dataService.projection[yearIndex].tuition.classes[classIndex].item
            .rate
        );
      };

      service.rateChange = function(classIndex, rate) {
        service.enrollment.classes[classIndex].item.rate = rate;
      };

      service.rateGrowthChange = function(
        classIndex,
        yearIndex,
        value,
        carryOver,
        carryDown
      ) {
        var classStart = classIndex;
        var classEnd = classIndex + 1;
        if (carryDown) {
          classEnd = service.enrollment.classes.length;
        }

        var yearStart = yearIndex;
        var yearEnd = yearIndex + 1;
        if (carryOver) {
          yearEnd = service.years + 1;
        }
        for (var c = classStart; c < classEnd; c++) {
          for (var y = yearStart; y < yearEnd; y++) {
            service.enrollment.classes[c].assumptions.rate[y] = value;
          }
        }
      };

      service.insertTree = function() {
        dataService.state.tuition.tree = defaultClassTree;
        dataService.state.tuition.tree.children = _.map(
          dataService.state.tuition.classes,
          function(c) {
            var display = c.name + " Grade";
            if (c.name === "k") display = "Kindergarten";
            else if (c.name === "pre-k") display = "Pre-K";
            return {
              name: display,
              link: c.name,
              id: c.name,
              required: true
            };
          }
        );
      };

      service.createTree = function() {
        var grades = [
          "pre-k",
          "k",
          "1st",
          "2nd",
          "3rd",
          "4th",
          "5th",
          "6th",
          "7th",
          "8th",
          "9th",
          "10th",
          "11th",
          "12th"
        ];
        for (var x = 0; x < grades.length; x++) {
          var enabled = _.some(dataService.state.tuition.classes, function(c) {
            return c.name === grades[x];
          });
          var item = dataService.findItemHelper(
            dataService.state.tuition.tree.children,
            grades[x]
          );
          if (enabled && !item) {
            collectionService.addChildItem(
              dataService.state.tuition.tree,
              grades[x]
            );
          }
          if (!enabled && item) {
            dataService.state.tuition.tree.children = treeService.remove(
              dataService.state.tuition.tree.children,
              item
            );
          }
        }
      };

      $rootScope.$on("$stateChangeSuccess", function(event, toState) {
        if (toState.name === "students") {
          sessionService.ready.promise.then(service.setup);
        }
      });

      return service;
    }
  ])
  .constant("defaultClassTree", {
    name: "Classes",
    id: "class-root",
    assumptions: {
      mode: "rate",
      rate: 3
    },
    children: []
  });
