import _ from "underscore";
import angular from "angular";
import img from "~/img/logo-dark.png";

export const AUTH_EVENTS = {
  loginSuccess: "auth-login-success",
  loginBegin: "auth-login-begin",
  loginFailed: "auth-login-failed",
  logoutSuccess: "auth-logout-success",
  sessionTimeout: "auth-session-timeout",
  notAuthenticated: "auth-not-authenticated",
  notAuthorized: "auth-not-authorized",
  sessionExpired: "auth-session-expired"
};

angular
  .module("helme.auth")
  .factory("authService", [
    "$rootScope",
    "$location",
    "$window",
    "Session",
    "api",
    function($rootScope, $location, $window, Session, api) {
      var authService = {};

      authService.login = function(credentials) {
        return api
          .login(credentials.email, credentials.pass)
          .then(res => {
            $rootScope.$broadcast(AUTH_EVENTS.loginBegin);
            api.setSessionId(res.data.session);
            api.session
              .getDetails()
              .then(function(detailRes) {
                Session.create(
                  res.data.session,
                  detailRes.data.client,
                  detailRes.data.user
                );
              })
              .catch(error => {});
            return res;
          })
          .catch(err => {
            $rootScope.$broadcast(AUTH_EVENTS.loginFailed);
            throw new Error("Invalid credentials");
          });
      };

      function useStoredSessionId(session) {
        api.setSessionId(session);

        api.session
          .getDetails()
          .then(function(res) {
            Session.create(
              localStorage["session"],
              res.data.client,
              res.data.user
            );
          })
          .catch(function(res) {
            $rootScope.$broadcast(AUTH_EVENTS.sessionExpired);
          });
        return true;
      }

      authService.checkSession = function() {
        if (this.initialized) {
          return true;
        }

        var params = $location.search();
        if (params["demo"] === "school") {
          return useStoredSessionId("demo_session_id");
        }

        if (params["logout"] === "true") {
          return localStorage.clear();
        }

        /* Check if there is a sessionId in localStorage */
        if (
          localStorage &&
          localStorage["session-stored"] === "true" &&
          localStorage["session"] !== "null"
        ) {
          return useStoredSessionId(localStorage["session"]);
        }

        return false;
      };

      authService.checkSession();

      authService.logout = function() {
        Session.destroy();
        $rootScope.$broadcast(AUTH_EVENTS.logoutSuccess);
        $window.location.reload();
      };

      authService.isAuthenticated = function() {
        return !_.isNull(Session.id) && Session.id !== "null";
      };

      return authService;
    }
  ])
  .service("Session", [
    "$rootScope",
    "ENV",
    function($rootScope, ENV) {
      this.id = null;
      this.loggedIn = false;
      this.user = null;

      this.create = function(sessionId, client, user) {
        this.id = sessionId;
        this.school = client;
        this.user = user;
        this.loggedIn = true;

        if (localStorage["school-id"] !== this.school._id) {
          localStorage["budget"] = null;
        }

        if (
          ENV.name === "development" ||
          this.school._id !== "demo-academy_0"
        ) {
          localStorage["school-id"] = this.school._id;
          localStorage["session"] = sessionId;
          localStorage["session-stored"] = true;
        }
        Intercom("boot", {
          app_id: "yehs8x3f",
          email: this.user.email,
          name: this.user.name,
          user_id: this.user.email,
          last_request_at: new Date().getTime() / 1000
        });
        heap.identify({
          "School Name": this.school.name,
          "School ID": this.school._id,
          Email: this.user.name
        });
        $rootScope.$broadcast(AUTH_EVENTS.loginSuccess);
      };
      this.destroy = function() {
        this.id = null;
        localStorage.clear();
        this.loggedIn = false;
      };

      return this;
    }
  ])
  .controller("ApplicationCtrl", [
    "$scope",
    "authService",
    "$modal",
    "Session",
    "ENV",
    "$window",
    function($scope, authService, $modal, Session, ENV, $window) {
      $scope.currentUser = null;
      $scope.isAuthenticated = authService.isAuthenticated;
      var currentDialog;

      $scope.setCurrentUser = function(user) {
        $scope.currentUser = user;
      };

      var failMessages = {
        ":edudash.database/no-such-user": "Email not found.",
        ":edudash.database/wrong-password": "Incorrect password."
      };

      function showDialog(errorMessage) {
        if (currentDialog) return currentDialog;

        var dialogOptions = {
          templateUrl: "./login-form.html",
          controller: "LoginCtrl",
          windowClass: "login-modal",
          keyboard: false,
          backdrop: true,
          backdropClass: "login-backdrop"
        };

        return $modal.open(dialogOptions);
      }

      if (!authService.checkSession()) currentDialog = showDialog();

      $scope.$on(AUTH_EVENTS.sessionExpired, function() {
        if (Session.loggedIn) {
          Session.destroy();
          $window.location.reload();
        } else {
          currentDialog = showDialog();
        }
      });
    }
  ])
  .run([
    "$rootScope",
    "$state",
    "Session",
    "authService",
    function($rootScope, $state, Session, authService) {
      $rootScope.$on("$stateChangeStart", function(event, next) {
        if (!authService.isAuthenticated()) {
          // user is not allowed
          $rootScope.$broadcast(AUTH_EVENTS.notAuthenticated);
        } else if (
          Session.user.type === "viewer" &&
          next.name !== "dashboard"
        ) {
          event.preventDefault();
          $state.go("dashboard");
        }
      });
    }
  ]);
