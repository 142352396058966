import _ from "underscore";
import angular from "angular";

angular.module("helme.tags", []).service("tagService", [
  "$rootScope",
  "ledger",
  "$q",
  "sessionService",
  "dataService",
  function($rootScope, ledger, $q, sessionService, dataService) {
    var service = {
      tags: [],
      itemLookup: {},
      ready: $q.defer()
    };

    function setup() {
      createTagLookup(dataService.state);
      service.ready.resolve();
    }

    var createTagLookup = function(budget) {
      service.itemLookup = _.object(
        _.map(service.tags, function(tag) {
          return [tag, []];
        })
      );

      setChildrenTags(budget.expense.categories);
      setChildrenTags(budget.income.categories);
      setChildrenTags(budget.tuition.reductions.children);
      setChildrenTags(budget.tuition.tree.children);
    };

    var setChildrenTags = function(children) {
      _.forEach(children, function(child) {
        _.forEach(child.tags, function(tag) {
          if (!_.contains(service.tags, tag)) {
            service.tags.push(tag);
            service.itemLookup[tag] = [];
          }
          safeTagInsert(tag, child);
        });

        if (!!child.children) setChildrenTags(child.children);
      });
    };

    var safeTagInsert = function(tag, child) {
      var path = dataService.paths[child.id];
      var parentTagged = _.some(path, function(parentId) {
        return _.some(service.itemLookup[tag], function(id) {
          return parentId === id;
        });
      });
      if (!parentTagged) service.itemLookup[tag].push(child.id);
    };

    service.addTag = function(tag, item) {
      item.tags = _.uniq([tag].concat(item.tags));
      ledger.events.tag.add.log(item.id, item.name, tag);
      $rootScope.$broadcast("push-state");
      service.tags = _.uniq([tag].concat(service.tags));
      if (!service.itemLookup[tag]) service.itemLookup[tag] = [];
      safeTagInsert(tag, item);
    };

    service.removeTag = function(tag, item) {
      item.tags = _.without(item.tags, tag);
      ledger.events.tag.remove.log(item.id, item.name, tag);
      $rootScope.$broadcast("push-state");
      service.removeTagItemPair(tag, item);
    };

    service.removeTagItemPair = function(tag, item) {
      service.itemLookup[tag] = _.without(service.itemLookup[tag], item.id);
      if (service.itemLookup[tag].length === 0) {
        service.tags = _.without(service.tags, tag);
        delete service.itemLookup[tag];
      }
    };

    service.removeItemOccurences = function(item) {
      _.forEach(item.tags, function(tag) {
        service.removeTagItemPair(tag, item);
      });
    };

    sessionService.ready.promise.then(setup);
    return service;
  }
]);
