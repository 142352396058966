import _ from "underscore";
import angular from "angular";

angular
  .module("helme.ledger", [])
  .service("replayService", [
    "$rootScope",
    "dataService",
    "collectionService",
    "tagService",
    "tuitionService",
    "eventLookupService",
    function(
      $rootScope,
      dataService,
      collectionService,
      tagService,
      tuitionService,
      eventLookupService
    ) {
      function paramEdit(event, params) {
        var item = dataService.getItem(event.params.itemId);
        if (!item) {
          return false;
        }
        if (!event.inverse) {
          deepEdit(item, params, event.params.updated);
        } else {
          deepEdit(item, params, event.params.previous);
        }
        return true;
      }

      function deepEdit(obj, params, value) {
        if (params.length === 1) obj[_.first(params)] = value;
        else deepEdit(obj[_.first(params)], _.rest(params), value);
      }

      var events = {
        item: {
          move: function(event) {
            if (!event.inverse) {
              return collectionService.moveItem(
                event.params.itemId,
                event.params.previous.id,
                event.params.updated.id
              );
            } else {
              return collectionService.moveItem(
                event.params.itemId,
                event.params.updated.id,
                event.params.previous.id
              );
            }
          },
          remove: function(event) {
            if (!event.inverse) {
              return collectionService.removeItem(event.params.item);
            } else {
              var parent = dataService.getItem(event.params.parentId);
              if (!parent) return false;
              var child = event.params.item;
              dataService.paths[child.id] = dataService.paths[parent.id].concat(
                [child.id]
              );
              dataService.itemTypes[child.id] = collectionService.type;
              parent.children.unshift(child);
              return true;
            }
          },
          add: function(event) {
            if (!event.inverse) {
              var parent = dataService.getItem(event.params.parentId);
              if (!parent) return false;
              var item = collectionService.addChildItem(
                parent,
                event.params.itemId
              );
              item.value = event.params.value;
              item.name = event.params.itemName;
              return true;
            } else {
              var item = {
                name: event.params.itemName,
                value: event.params.value,
                id: event.params.itemId
              };
              return collectionService.removeItem(item);
            }
          },
          duplicate: function(event) {
            console.info("Event: ", event);
            if (!event.inverse) {
              return collectionService.duplicate(
                event.params.item,
                event.params.duplicate.id
              );
            } else {
              return collectionService.removeItem(event.params.duplicate);
            }
          },
          linking: function(event) {
            return paramEdit(event, ["linking"]);
          },
          value: {
            current: function(event) {
              return paramEdit(event, ["value"]);
            },
            next: function(event) {
              return paramEdit(event, ["next"]);
            }
          },
          name: function(event) {
            return paramEdit(event, ["name"]);
          },

          assumptions: {
            mode: function(event) {
              return paramEdit(event, ["assumptions", "mode"]);
            },
            rate: function(event) {
              return paramEdit(event, ["assumptions", "rate"]);
            },
            delta: function(event) {
              return paramEdit(event, ["assumptions", "delta"]);
            },
            granular: function(event) {
              return paramEdit(event, ["assumptions", "granular"]);
            }
          },
          year: {
            start: function(event) {
              return paramEdit(event, ["start"]);
            },
            end: function(event) {
              return paramEdit(event, ["end"]);
            }
          }
        },
        category: {
          add: function(event) {
            if (!event.inverse) {
              var parent = dataService.getItem(event.params.parentId);
              if (!parent) return false;
              var category = collectionService.addChildCategory(
                parent,
                event.params.itemId
              );
              return true;
            } else {
              var category = {
                id: event.params.itemId
              };
              return collectionService.removeItem(category);
            }
          }
        },
        tag: {
          add: function(event) {
            var item = dataService.getItem(event.params.itemId);
            if (!event.inverse) {
              tagService.addTag(event.params.tag, item);
              return true;
            } else {
              tagService.removeTag(event.params.tag, item);
              return true;
            }
          },
          remove: function(event) {
            var item = dataService.getItem(event.params.itemId);
            if (!event.inverse) {
              tagService.removeTag(event.params.tag, item);
              return true;
            } else {
              tagService.addTag(event.params.tag, item);
              return true;
            }
          }
        },
        tuition: {
          enrollment: function(event) {
            if (!event.inverse) {
              tuitionService.enrollmentChange(
                event.params.classIndex,
                event.params.yearIndex,
                event.params.updated,
                event.params.carry
              );
              return true;
            } else {
              tuitionService.enrollmentChange(
                event.params.classIndex,
                event.params.yearIndex,
                event.params.previous,
                event.params.carry
              );
              return true;
            }
          },
          rate: {
            current: function(event) {
              if (!event.inverse) {
                tuitionService.rateChange(
                  event.params.classIndex,
                  event.params.updated
                );
                return true;
              } else {
                tuitionService.rateChange(
                  event.params.classIndex,
                  event.params.previous
                );
                return true;
              }
            },
            growth: function(event) {
              if (!event.inverse) {
                tuitionService.rateGrowthChange(
                  event.params.yearIndex,
                  event.params.classIndex,
                  event.params.updated,
                  event.params.carryOver,
                  event.params.carryDown
                );
                return true;
              } else {
                tuitionService.rateGrowthChange(
                  event.params.yearIndex,
                  event.params.classIndex,
                  event.params.previous,
                  event.params.carryOver,
                  event.params.carryDown
                );
                return true;
              }
            }
          }
        }
      };

      return {
        replay: function(eventList) {
          $rootScope.$broadcast("replay-start");
          var errors = [];
          _.forEach(eventList, function(event) {
            var fn = eventLookupService.lookup(events, event);
            var result = fn(event);
            console.info(event.id, dataService.state);
            if (!result) {
              errors.push(event);
            }
          });
          $rootScope.$broadcast("replay-end");
          $rootScope.$broadcast("budget-changed");
          return errors;
        }
      };
    }
  ])
  .service("eventLookupService", function() {
    return {
      lookup: function(obj, event) {
        if (event.id === "name-change") return obj.item.name;
        if (event.id === "value-change") return obj.item.value.current;
        if (event.id === "next-value-change") return obj.item.value.next;
        if (event.id === "remove-item") return obj.item.remove;
        if (event.id === "add-item") return obj.item.add;
        if (event.id === "add-category") return obj.category.add;
        if (event.id === "tag-add-item") return obj.tag.add;
        if (event.id === "tag-remove-item") return obj.tag.add;
        if (event.id === "item-assumption-mode-change")
          return obj.item.assumptions.mode;
        if (event.id === "item-assumption-rate-change")
          return obj.item.assumptions.rate;
        if (event.id === "item-assumption-delta-change")
          return obj.item.assumptions.delta;
        if (event.id === "item-assumption-granular-change")
          return obj.item.assumptions.granular;
        if (event.id === "item-start-change") return obj.item.year.start;
        if (event.id === "item-end-change") return obj.item.year.end;
        if (event.id === "item-linking-change") return obj.item.linking;
        if (event.id === "move-item") return obj.item.move;
        if (event.id === "item-duplicate") return obj.item.duplicate;

        /* Tuition */
        if (event.id === "enrollment-change") return obj.tuition.enrollment;
        if (event.id === "rate-change") return obj.tuition.rate.current;
        if (event.id === "rate-growth-change") return obj.tuition.rate.growth;

        return null;
      }
    };
  });
