"use strict";

var prettyYear = function(year) {
  return year + "-" + (year + 1);
};

var getYearArray = function(startYear) {
  var yearArray = [];
  for (var x = 0; x < 15; x++) {
    yearArray.push(prettyYear(startYear + x));
  }
  return yearArray;
};

const devEnv = {
  name: "development",
  endpoint: "http://localhost:8080",
  // nodeEndpoint: "http://localhost:8084",
  nodeEndpoint: "https://helme-iris.herokuapp.com",
  host: "http://localhost:8081",
  email: "demo@helme.io",
  pass: "demo",
  stripe: process.env.STRIPE_KEY,
  currentYear: 2017,
  prettyYears: getYearArray(17)
};

const prodEnv = {
  name: "production",
  endpoint: "https://api.helme.io",
  nodeEndpoint: "https://helme-iris.herokuapp.com",
  host: "https://app.helme.io",
  email: "",
  pass: "",
  stripe: process.env.STRIPE_KEY,
  cachebust: "" + Date.now(),
  currentYear: 2017,
  prettyYears: getYearArray(17)
};

angular
  .module("helme.config", [])
  .constant("ENV", process.env.NODE_ENV === "development" ? devEnv : prodEnv);
