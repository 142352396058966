import _ from "underscore";
import angular from "angular";

angular.module("helme.budgetCollection").service("collectionService", [
  "$q",
  "$rootScope",
  "sessionService",
  "dataService",
  "models",
  "treeService",
  "footerService",
  "formatService",
  "$state",
  "$modal",
  "ENV",
  "notify",
  "ledger",
  "guid",
  function(
    $q,
    $rootScope,
    sessionService,
    dataService,
    models,
    treeService,
    footerService,
    formatService,
    $state,
    $modal,
    ENV,
    notify,
    ledger,
    guid
  ) {
    var service = {};

    service.ready = $q.defer();

    var collectionStates = [
      "students",
      "fees",
      "development",
      "other-income",
      "personnel",
      "operations",
      "curricular",
      "co-curricular",
      "advancement",
      "other-expenses"
    ];

    service.mode = {
      futureItemsVisible: true,
      detailedView: false
    };

    service.setup = function(stateName) {
      if (!stateName) stateName = $state.current.name;

      if (
        !_.isEmpty(dataService.state) &&
        _.contains(collectionStates, stateName)
      ) {
        //special case for reductions
        if (stateName === "students") {
          service.tab = {
            key: "students"
          };

          if (sessionService.mode.tuitionView === "reductions") {
            service.type = "expense";
            service.root = dataService.state.tuition.reductions;
          } else {
            service.type = "income";
            service.root = dataService.state.tuition.tree;
          }
        } else {
          service.tab = dataService.getTab(stateName);
          service.title = dataService.tabTitle(service.tab);

          service.type = dataService.tabType(service.tab);
          service.root = dataService.getPageData(service.tab);
        }

        service.root.children = treeService.removeDuplicates(
          service.root.children
        );

        if (dataService.projection.length > 0) {
          footerService.useTab(service.root);
        }

        service.ready.resolve();
      }
    };

    service.bulkAddRoot = function() {
      service.bulkAdd(service.root);
    };

    service.bulkAdd = function(parent) {
      var modalInstance = $modal.open({
        templateUrl: "./bulk-add.html",
        controller: "BulkAddCtrl",
        windowClass: "helme-modal"
      });
      modalInstance.result.then(
        function(itemList) {
          _.forEach(itemList.reverse(), function(bulkItem) {
            var child = models.newItem();
            dataService.paths[child.id] = dataService.paths[parent.id].concat([
              child.id
            ]);
            child.name = bulkItem.name;
            child.value = bulkItem.value;
            dataService.itemTypes[child.id] = service.type;
            parent.children.unshift(child);
            ledger.events.item.add.log(child, parent);
          });
          $rootScope.$broadcast("push-state");
        },
        function(err) {}
      );
    };

    service.addChildItem = treeService.addChildItem;
    service.addItem = function() {
      var item = service.addChildItem(service.root);
      return item;
    };

    service.addChildCategory = treeService.addChildCategory;
    service.addCategory = function() {
      return service.addChildCategory(service.root);
    };

    service.duplicate = function(item, id) {
      var newItem = angular.copy(item);
      newItem.id = id ? id : guid.make();
      var path = dataService.paths[item.id];
      dataService.paths[newItem.id] = path;
      var parent = dataService.getItem(path[path.length - 2]);
      var index = _.findIndex(parent.children, function(child) {
        return child.id === item.id;
      });
      parent.children.splice(index, 0, newItem);
      ledger.events.item.duplicate.log(item, newItem, parent, index);
    };

    function hasRequiredChild(item) {
      return _.some(item.children, function(child) {
        if (!!child.children) return hasRequiredChild(child);
        return child.required;
      });
    }

    service.removeItem = function(item, showNotification) {
      var name = angular.copy(item.name);

      if (hasRequiredChild(item)) {
        notify({
          message: name + " has a required child, can't remove.",
          position: "right",
          duration: 3000
        });
        return false;
      }

      var path = dataService.paths[item.id];
      if (!path) return false;
      var parent = dataService.getItem(path[path.length - 2]);
      ledger.events.item.remove.log(item, parent);

      var length = parent.children.length;
      parent.children = treeService.remove(parent.children, item);
      var result = parent.children.length < length;

      $rootScope.$broadcast("push-state");
      $rootScope.$broadcast("item-removed");

      if (showNotification) {
        // Undo notification scope
        var notifyScope = $rootScope.$new();
        var notification;

        notifyScope.name = name;

        notifyScope.undo = function() {
          sessionService.undo();
          notification.close();
        };
        notifyScope.close = function() {
          notification.close();
        };

        notifyScope.changed = false;
        notifyScope.checkClose = function() {
          if (notifyScope.changeCount > 1) notification.close();
          else notifyScope.changed = true;
        };

        notifyScope.$on("budget-changed", notifyScope.checkClose);
        notifyScope.$on("$stateChangeStart", notifyScope.checkClose);
        notifyScope.$on("item-removed", notifyScope.checkClose);

        // Show undo notification
        notification = notify({
          message: "hey",
          position: "right",
          templateUrl: "./remove-undo.html",
          duration: 0,
          scope: notifyScope
        });
      }
      return result;
    };

    //Only used in what-if's
    service.moveItem = function(itemId, sourceId, destId) {
      var source = dataService.getItem(sourceId);
      var dest = dataService.getItem(destId);

      if (!source || !dest) return false;

      var item = _.find(source.children, function(child) {
        return child.id === itemId;
      });

      dest.children.unshift(angular.copy(item));

      source.children = _.filter(source.children, function(child) {
        return child.id !== item.id;
      });

      return true;
    };

    service.updateRoot = function(root) {
      dataService.setPageData(service.tab, root);
      $rootScope.$broadcast("budget-changed");
    };

    service.showPageMoveDialogue = function(item) {
      var modalInstance = $modal.open({
        templateUrl: "./page-move.html",
        controller: "PageMoveCtrl",
        resolve: {
          currentPage: function() {
            return $state.current.name;
          },
          item: function() {
            return item;
          }
        },
        windowClass: "edudash-modal"
      });
      modalInstance.result.then(
        function(page) {
          var destId = dataService.getPageData({
            key: page
          }).id;
          var path = dataService.paths[item.id];
          var sourceId = path[path.length - 2];

          service.moveItem(item.id, sourceId, destId);

          //push-state called in move-item
          $state.go(page);
          $rootScope.$broadcast("push-state");
          $rootScope.$broadcast("budget-changed");
        },
        function(err) {}
      );
    };

    service.updateCollection = function(newVal, oldVal) {
      if (!!newVal && newVal !== oldVal) {
        service.root.children = newVal;
        service.updateRoot(service.root);
      }
    };

    service.tabTotal = function(yearMode, tabId) {
      const id = tabId || (service.root && service.root.id);
      if (_.isEmpty(dataService.projection) || !id) return "$0";
      var year = 0;
      if (yearMode === "next") year = 1;
      var value = dataService.getItem(id, dataService.projection[year]).value;
      return formatService.formatDollarValue(value, false);
    };

    $rootScope.$on("projection-calculated", function() {
      footerService.useTab(service.root);
    });

    sessionService.ready.promise.then(function() {
      service.setup($state.current.name);

      $rootScope.$on("$stateChangeStart", function(event, toState) {
        service.setup(toState.name);
        service.mode.detailedView = false;
      });

      $rootScope.$on("tab-switch", function() {
        service.setup($state.current.name);
      });

      $rootScope.$on("full-state-change", function() {
        service.setup();
        $rootScope.$broadcast("collection-loaded");
      });
    });

    return service;
  }
]);
