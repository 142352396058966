import angular from "angular";

angular
  .module("helme.pages")
  .controller("tabSelectCtrl", [
    "$scope",
    "dataService",
    "sessionService",
    "pages",
    function($scope, dataService, sessionService, pages) {
      $scope.tabTitle = dataService.tabTitle;

      function setup() {
        $scope.expenseTabs = pages.expense;
        $scope.incomeTabs = pages.income;
      }
      $scope.changeVisibility = function(tab) {
        tab.hidden = !tab.hidden;
      };

      sessionService.ready.promise.then(setup);
    }
  ])
  .controller("accountListCtrl", [
    "$scope",
    "userService",
    "Session",
    "sessionService",
    function($scope, userService, Session, sessionService) {
      $scope.userTypes = userService.userTypes;

      $scope.createUser = userService.create;
      $scope.editUser = userService.edit;
      $scope.removeUser = userService.remove;

      function setup() {
        $scope.users = userService.exports;
        $scope.currentUser = Session.user;
      }
      sessionService.ready.promise.then(function() {
        userService.ready.promise.then(setup);
      });
    }
  ])
  .controller("StripeCtrl", [
    "$scope",
    "Session",
    "api",
    "ENV",
    function($scope, Session, api, ENV) {}
  ]);
