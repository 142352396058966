import _ from "underscore";
import angular from "angular";

angular.module("helme.pages").controller("excelImportCtrl", [
  "$scope",
  "$modal",
  "workbook",
  "guid",
  function($scope, $modal, workbook, guid) {
    function setup() {
      $scope.workbook = _.map(workbook, function(sheet) {
        var inverted = _.zip.apply(_, sheet.grid);

        var length = _.max(inverted, function(l) {
          return l.length;
        });
        length = _.max([length, 10]);

        if (inverted.length < 10)
          for (var i = 0; i < 10 - inverted.length; i++) {
            inverted.push(new Array(length));
          }

        for (var x = 0; x < inverted.length; x++) {
          if (inverted[x].length < length)
            for (var y = 0; y < length - inverted[0].length; y++)
              inverted[x].push("");
        }

        var grid = _.map(inverted, function(c) {
          return {
            label: "",
            values: _.map(c, function(v) {
              return !_.isUndefined(v) && v !== null && v !== "" ? v : "";
            })
          };
        });

        return {
          grid: grid,
          name: sheet.name
        };
      });
      $scope.cSheet = $scope.workbook[0];
      $scope.loading = false;
    }

    $scope.submitEnabled = function() {
      return !!$scope.excelResults && $scope.excelResults.length > 0;
    };

    $scope.selectSheet = function(sheet) {
      $scope.cSheet = sheet;
    };

    $scope.labels = ["None", "Name", "Value", "Description"];

    function parseExcel(grid) {
      var items = _.range(grid[0].values.length).map(function() {
        return {};
      });

      for (var col = 0; col < grid.length; col++) {
        for (var row = 0; row < grid[0].values.length; row++) {
          items[row].id = guid.make();
          if (grid[col].label === "Name") {
            if (!items[row].name) items[row].name = "";
            items[row].name += grid[col].values[row];
          }
          if (grid[col].label === "Value") {
            var cleaned = grid[col].values[row]
              .toString()
              .slice(0)
              .replace(/\$|,|\ |\(|\)|%/g, "");

            var val = parseInt(cleaned);
            if (!_.isNaN(val)) items[row].value = val;
          }
        }
      }
      items = _.filter(items, function(i) {
        return (
          !!i && !_.isEmpty(i) && !!i.name && typeof i.value !== "undefined"
        );
      });
      return items;
    }

    $scope.columnChange = function(index) {
      return function(value) {
        $scope.cSheet.grid[index].label = value;
        $scope.excelResults = _.flatten(
          _.map(_.pluck($scope.workbook, "grid"), parseExcel),
          true
        );
      };
    };

    $scope.submit = function() {
      if ($scope.submitEnabled()) {
        $scope.$close($scope.excelResults);
      }
    };
    setup();
  }
]);
