import _ from "underscore";
import angular from "angular";

angular
  .module("helme.budgetCollection", ["helme.models"])
  .service("linkItemService", [
    "dataService",
    "formatService",
    "metrics",
    "$modal",
    "ENV",
    function(dataService, formatService, metrics, $modal, ENV) {
      var service = {
        exports: {}
      };

      service.linkTypes = [
        {
          name: "None",
          _id: "none"
        },
        {
          name: "Gross Tuition",
          _id: "tuition-gross"
        },
        {
          name: "Total Enrollment",
          _id: "enrollment-total"
        },
        {
          name: "Grade Enrollment",
          _id: "enrollment-grade"
        }
      ];

      service.prettyTotal = function(linking, year) {
        return formatService.formatDollarValue(
          service.calculate(linking, year),
          true
        );
      };

      service.prettyLinkingType = function(linking) {
        if (!linking) return "";
        return _.find(service.linkTypes, function(l) {
          return l._id === linking.type;
        }).name;
      };

      service.calculate = function(linking, year) {
        if (linking.type === "tuition-gross") {
          return service.tuitionTotals[year] * (linking.percents[year] / 100);
        } else if (linking.type === "enrollment-total") {
          return (
            service.enrollmentTotals[year] *
            linking.multiples[year] *
            (linking.percents[year] / 100)
          );
        } else if (linking.type === "enrollment-grade") {
          return (
            service.gradeEnrollmentTotals[year][linking.grade] *
            linking.multiples[year] *
            (linking.percents[year] / 100)
          );
        }
      };

      service.showLinkModal = function(item) {
        return $modal.open({
          templateUrl: "./link-item-modal.html",
          controller: "LinkItemCtrl",
          resolve: {
            item: function() {
              return item;
            }
          },
          windowClass: "helme-modal"
        });
      };

      service.init = function(item) {
        service.enrollmentTotals = dataService.projection.map(
          metrics.enrollmentTotal
        );
        service.tuitionTotals = dataService.projection.map(
          metrics.tuitionGross
        );
        service.grades = dataService.state.tuition.classes.map(function(g) {
          return {
            name: g.name,
            _id: g.name
          };
        });
        service.gradeEnrollmentTotals = dataService.projection.map(function(
          budget
        ) {
          return budget.tuition.classes.reduce(function(acc, c) {
            acc[c.name] = c.item.students;
            return acc;
          }, {});
        });

        service.exports.item = item;
        service.exports.linking = item.linking;
        if (!service.exports.linking) {
          service.exports.linking = {
            type: "none",
            multiples: _.range(0, 8).map(function() {
              return 0;
            }),
            percents: _.range(0, 8).map(function() {
              return 100;
            })
          };
        }
      };

      return service;
    }
  ]);
