import _ from "underscore";
import angular from "angular";
import c3 from "c3";

angular.module("helme.graphs").directive("pieChart", [
  "sessionService",
  "graphService",
  "formatService",
  "$timeout",
  "ENV",
  function(sessionService, graphService, formatService, $timeout, ENV) {
    function formatData(data) {
      var columns = _.map(data.series[0], function(d, i) {
        return [data.axisLabels[i]].concat(d);
      });

      var c = ["#009688", "#0277bd", "#fb8c00"];

      var colors = {};
      _.forEach(columns, function(column, i) {
        if (i < columns.length) {
          colors[column[0]] = c[i];
        }
      });

      return {
        data: {
          columns: columns,
          colors: colors,
          type: "pie"
        }
      };
    }

    var containerWidth;

    function go(scope, element) {
      containerWidth = element[0].clientWidth;
      if (!scope.data) return {};
      var chartInfo = formatData(scope.data);
      return c3.generate({
        bindto: element[0],
        data: chartInfo.data,
        legend: {
          show: chartInfo.data.columns.length > 1
        },
        pie: {
          label: {
            format: function(value, ratio, id) {
              return formatService.formatShortDollarValue(value);
            }
          }
        }
      });
    }

    function link(scope, element, attribs) {
      var chart;
      $timeout(function() {
        chart = go(scope, element);
      }, 100);
      scope.$watch("data", function(val, old) {
        if (val !== old) {
          chart = go(scope, element);
        }
      });
      scope.$on("tile-resize", function() {
        scope.width = element;

        if (chart) $timeout(chart.flush, 150);
      });
    }

    return {
      restrict: "E",
      replace: true,
      link: link,
      scope: {
        data: "=",
        unit: "=",
        config: "=",
        rotated: "@?"
      },
      template: "<div class='c3-chart'> <svg></svg> </div>"
    };
  }
]);
