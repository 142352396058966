import _ from "underscore";
import angular from "angular";

angular.module("helme.pages").controller("CreateModalCtrl", [
  "$scope",
  "$modalInstance",
  "metrics",
  "tileService",
  "config",
  "ENV",
  "dataService",
  "tagService",
  "guid",
  function(
    $scope,
    $modalInstance,
    metrics,
    tileService,
    config,
    ENV,
    dataService,
    tagService,
    guid
  ) {
    $scope.metricLibrary = metrics.library;
    $scope.typeChange = tileService.typeChange;
    $scope.getChartTypes = tileService.getChartTypes;

    $scope.tags = tagService.tags;
    $scope.selectedTag = "";
    $scope.fundraising = dataService.state.modules.fundraising;

    $scope.editing = {
      data: [[1, 2, 3, 4, 5]],
      parsed: [[1, 2, 3, 4, 5]],
      labels: ["Series 1"]
    };

    $scope.step = 1;

    $scope.isEditing = config.editing;

    if (config.editing) {
      $scope.tile = config.tile;
      $scope.step = 4;
    } else {
      $scope.tile = {
        title: "New Tile",
        data: {
          type: "",
          fn: ""
        },
        vizType: ""
      };
    }

    $scope.tileTypes = [
      {
        text: "Chart a school metric",
        key: "metric"
      },
      {
        text: "Chart a budget category",
        key: "category"
      },
      {
        text: "Chart a tag",
        key: "tag"
      }
    ];

    var typeNames = {
      "single-line": "Line",
      pnl: "P&L Tile",
      "grouped-bar": "Bar",
      pie: "Pie",
      number: "Number",
      bullet: "Bullet",
      overview: "Bar + Line",
      "horizontal-bar": "Horizontal Bar"
    };

    $scope.getTypeName = function(type) {
      return typeNames[type];
    };

    $scope.budgetCategories = _.values(
      dataService.state.income.categories
    ).concat(_.values(dataService.state.expense.categories));

    $scope.categoryGroup = function(id) {
      if (_.contains(["other-income_0", "fees_0", "development_0"], id))
        return "Income";
      return "Expense";
    };

    // called on step 1 for every type.
    $scope.selectTileType = function(type) {
      tileService.selectTileType($scope.tile, type);
      if (type === "manual" && $scope.userSeries.length > 0)
        $scope.tile.data.ids = [_.first($scope.userSeries).id];

      $scope.step = 2;
    };

    $scope.selectTag = function(tag) {
      tileService.selectTag($scope.tile, tag);

      $scope.step = 4;
    };

    $scope.selectedCategory = null;
    $scope.selectCategory = function(category) {
      tileService.selectCategory($scope.tile, category);

      $scope.step = 4;
    };

    $scope.developmentDisplay = function(drive) {
      return drive.name;
    };

    $scope.selectDevelopment = function(id) {
      var drive = _.find($scope.fundraising, function(d) {
        return d.id === id;
      });
      if (!drive.id) drive.id = guid.make();
      $scope.tile.data.drive = drive.id;
      $scope.tile.title = drive.name;
    };

    $scope.metricDisplay = function(metric) {
      return metric.name;
    };

    $scope.metricName = function() {
      return _.find(metrics.library, function(metric) {
        return $scope.tile.data.fn === metric.key;
      }).name;
    };
    //called on step 2 for metric types.
    $scope.selectMetric = function(metric) {
      $scope.tile.data.fn = metric;
      tileService.metricChange($scope.tile);

      $scope.step = 4;
    };

    //called on step 2 for manual, step 3 for metric and category.
    //we set step to 4 for manual too because we want to show everything.
    $scope.selectVizType = function(type) {
      tileService.vizTypeChange($scope.tile, type);

      if ($scope.tile.data.type === "manual") $scope.step = 3;
      else $scope.step = 4;
    };

    $scope.disallowSubmit = function() {
      if ($scope.tile.data.fn === "fundraisers" && !$scope.tile.data.drive)
        return true;
      return $scope.step < 4;
    };

    $scope.submit = function() {
      if (!$scope.disallowSubmit()) {
        if ($scope.tile.data.type === "manual") {
        }
        $modalInstance.close($scope.tile);
      }
    };

    $scope.cancel = function() {
      $modalInstance.dismiss();
    };
  }
]);
