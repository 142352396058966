import fs from "fs";
import angular from "angular";

export const templates = [
  {name: "app.html", content: fs.readFileSync("./app/js/app.html", "utf8")},
  {
    name: "dropdown-inner.html",
    content: fs.readFileSync("./app/js/input/dropdown-inner.html", "utf8")
  },
  {
    name: "data-page/account-list.html",
    content: fs.readFileSync("./app/js/data-page/account-list.html", "utf8")
  },
  {
    name: "data-page/account.html",
    content: fs.readFileSync("./app/js/data-page/account.html", "utf8")
  },
  {
    name: "data-page/quickbooks.html",
    content: fs.readFileSync("./app/js/data-page/quickbooks.html", "utf8")
  },
  {
    name: "data-page/scenarios.html",
    content: fs.readFileSync("./app/js/data-page/scenarios.html", "utf8")
  },
  {
    name: "data-page/tab-select.html",
    content: fs.readFileSync("./app/js/data-page/tab-select.html", "utf8")
  },
  {
    name: "data-page/pnl.html",
    content: fs.readFileSync("./app/js/data-page/pnl.html", "utf8")
  },
  {
    name: "data-page/accounts-drives.html",
    content: fs.readFileSync("./app/js/data-page/accounts-drives.html", "utf8")
  },
  {
    name: "data-page/import.html",
    content: fs.readFileSync("./app/js/data-page/import.html", "utf8")
  },
  {
    name: "data-page/payments.html",
    content: fs.readFileSync("./app/js/data-page/payments.html", "utf8")
  },
  {
    name: "global-actions.html",
    content: fs.readFileSync("./app/js/header/global-actions.html", "utf8")
  },
  {
    name: "change-icon.html",
    content: fs.readFileSync("./app/js/header/change-icon.html", "utf8")
  },
  {
    name: "students-header.html",
    content: fs.readFileSync("./app/js/header/students-header.html", "utf8")
  },
  {
    name: "students-page.html",
    content: fs.readFileSync("./app/js/tuition/students-page.html", "utf8")
  },
  {
    name: "whatif-banner.html",
    content: fs.readFileSync("./app/js/whatif/whatif-banner.html", "utf8")
  },
  {
    name: "budget-overview.html",
    content: fs.readFileSync("./app/js/analytics/budget-overview.html", "utf8")
  },
  {
    name: "export-node.html",
    content: fs.readFileSync("./app/js/export/export-node.html", "utf8")
  },
  {
    name: "summary-overview.html",
    content: fs.readFileSync("./app/js/whatif/summary-overview.html", "utf8")
  },
  {
    name: "enrollment-page.html",
    content: fs.readFileSync("./app/js/tuition/enrollment-page.html", "utf8")
  },
  {
    name: "total-row.html",
    content: fs.readFileSync("./app/js/tuition/total-row.html", "utf8")
  },
  {
    name: "delta-row.html",
    content: fs.readFileSync("./app/js/tuition/delta-row.html", "utf8")
  },
  {
    name: "rate-row.html",
    content: fs.readFileSync("./app/js/tuition/rate-row.html", "utf8")
  },
  {
    name: "summary-row.html",
    content: fs.readFileSync("./app/js/tuition/summary-row.html", "utf8")
  },
  {
    name: "intro.html",
    content: fs.readFileSync("./app/js/wizard/intro.html", "utf8")
  },
  {
    name: "basics.html",
    content: fs.readFileSync("./app/js/wizard/basics.html", "utf8")
  },
  {
    name: "students.html",
    content: fs.readFileSync("./app/js/wizard/students.html", "utf8")
  },
  {
    name: "budget.html",
    content: fs.readFileSync("./app/js/wizard/budget.html", "utf8")
  },
  {
    name: "permissions.html",
    content: fs.readFileSync("./app/js/wizard/permissions.html", "utf8")
  },
  {
    name: "conclusion.html",
    content: fs.readFileSync("./app/js/wizard/conclusion.html", "utf8")
  },
  {
    name: "./side-menu.html",
    content: fs.readFileSync("./app/js/navigation/side-menu.html", "utf8")
  }
];

angular.module("compile.directive", []);
