import _ from "underscore";
import angular from "angular";

angular.module("helme.graphs").service("graphService", function() {
  return {
    niceBounds: function(seriesColl, override) {
      var max = _.max(
        _.map(seriesColl, function(d) {
          return _.max(d);
        })
      );
      var min = _.min(
        _.map(seriesColl, function(d) {
          return _.min(d);
        })
      );
      if (max !== 0) {
        var absMax = Math.abs(max),
          logMax = Math.floor(Math.log10(absMax)),
          niceMax =
            Math.sign(max) *
            0.25 *
            Math.ceil((absMax * 4) / Math.pow(10, logMax)) *
            Math.pow(10, logMax);
      } else {
        niceMax = 0;
      }
      if (min !== 0) {
        var absMin = Math.abs(min),
          logMin = Math.floor(Math.log10(absMin)),
          niceMin =
            Math.sign(min) *
            0.25 *
            Math.floor((absMin * 4) / Math.pow(10, logMin)) *
            Math.pow(10, logMin);
      } else {
        niceMin = 0;
      }

      if (!!override) {
        if (override.min === 0 || !!override.min) {
          niceMin = override.min;
        }

        if (override.max === 0 || !!override.max) {
          niceMax = override.max;
        }
      }

      if (_.isNaN(niceMin) || _.isNaN(niceMin)) {
        return [];
      }

      var interval = (niceMax - niceMin) / 5;
      return _.range(niceMin, niceMax + interval, interval);
    }
  };
});
