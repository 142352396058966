import _ from "underscore";
import angular from "angular";

angular.module("helme.ledger").service("ledger", [
  "$rootScope",
  "formatService",
  "guid",
  "eventLookupService",
  "sessionService",
  function(
    $rootScope,
    formatService,
    guid,
    eventLookupService,
    sessionService
  ) {
    var service = {};

    function log(eventId, params) {
      if (sessionService.mode.replaying) return false;
      var event = {
        id: eventId,
        _id: guid.make(),
        params: params
      };
      service.exports.list.unshift(event);
      service.exports.current = event;
      $rootScope.$broadcast("ledger-event", event);
      return true;
    }

    service = {
      exports: {
        list: [
          {
            id: "initial-state"
          }
        ]
      },

      revert: function(event) {
        if (!!event._id) {
          service.exports.current = event;
          $rootScope.$broadcast("set-state-history", event._id);
        }
      },
      logUndo: function(id) {
        var event = _.find(service.exports.list, function(e) {
          return e._id === id;
        });
        var cvent = angular.copy(event);
        cvent.inverse = true;
        cvent.hidden = true;
        cvent._id = guid.make();
        //service.exports.list.unshift(cvent);
        //service.exports.current = cvent;
        $rootScope.$broadcast("ledger-hidden-event", cvent);
      },
      logRedo: function(id) {
        var event = _.find(service.exports.list, function(e) {
          return e._id === id;
        });
        var cvent = angular.copy(event);
        cvent._id = guid.make();
        cvent.hidden = true;
        //service.exports.list.unshift(cvent);
        //service.exports.current = cvent;
        $rootScope.$broadcast("ledger-hidden-event", cvent);
      }
    };

    var events = {
      item: {
        move: {
          log: function(item, updated, previous) {
            log("move-item", {
              itemId: item.id,
              itemName: item.name,
              previous: {
                id: previous.id,
                name: previous.name
              },
              updated: {
                id: updated.id,
                name: updated.name
              }
            });
          },
          read: function(event) {
            return (
              "Moved '" +
              event.params.itemName +
              "' from '" +
              event.params.previous.name +
              "' to '" +
              event.params.updated.name +
              "'."
            );
          }
        },
        remove: {
          log: function(item, parent) {
            log("remove-item", {
              item: item,
              parentName: parent.name,
              parentId: parent.id
            });
          },
          read: function(event) {
            return (
              "Removed '" +
              event.params.item.name +
              "' from '" +
              event.params.parentName +
              "'."
            );
          }
        },
        duplicate: {
          log: function(item, duplicate, parent, index) {
            log("item-duplicate", {
              itemId: item.id,
              itemName: item.name,
              item: item,
              duplicate: duplicate,
              index: index
            });
          },
          read: function(event) {
            return "Duplicated" + event.itemName;
          }
        },
        add: {
          log: function(item, parent, isCategory) {
            log("add-item", {
              itemId: item.id,
              itemName: item.name,
              value: item.value,
              parentId: parent.id,
              parentName: parent.name,
              category: isCategory
            });
          },
          read: function(event) {
            return (
              "Added '" +
              event.params.itemName +
              "' to '" +
              event.params.parentName +
              "'."
            );
          }
        },

        linking: {
          log: function(item, linkingBefore, linkingAfter) {
            log("item-linking-change", {
              itemId: item.id,
              itemName: item.name,
              previous: linkingBefore,
              updated: linkingAfter
            });
          },
          read: function(event) {
            return "Changed '" + event.params.itemName + "' linking.";
          }
        },
        value: {
          current: {
            log: function(item, updated, previous) {
              log("value-change", {
                itemId: item.id,
                itemName: item.name,
                updated: updated,
                previous: previous
              });
            },
            read: function(event) {
              return (
                "Changed '" +
                event.params.itemName +
                "' from " +
                formatService.formatDollarValue(event.params.previous) +
                " to " +
                formatService.formatDollarValue(event.params.updated) +
                "."
              );
            }
          },
          next: {
            log: function(item, updated, previous) {
              log("next-value-change", {
                itemId: item.id,
                itemName: item.name,
                updated: updated,
                previous: previous
              });
            },
            read: function(event) {
              return (
                "Changed '" +
                event.params.itemName +
                "' next year's budget from " +
                formatService.formatDollarValue(event.params.previous) +
                " to " +
                formatService.formatDollarValue(event.params.updated) +
                "."
              );
            }
          }
        },
        name: {
          log: function(item, updated, previous) {
            log("name-change", {
              itemId: item.id,
              updated: updated,
              previous: previous
            });
          },
          read: function(event) {
            return (
              "Renamed '" +
              event.params.previous +
              "' to '" +
              event.params.updated +
              "'."
            );
          }
        },

        assumptions: {
          mode: {
            log: function(item, updated, previous) {
              log("item-assumption-mode-change", {
                itemId: item.id,
                itemName: item.name,
                updated: updated,
                previous: previous
              });
            },
            read: function(event) {
              return (
                "Changed '" +
                event.params.itemName +
                "' assumption mode to " +
                event.params.mode +
                "."
              );
            }
          },
          rate: {
            log: function(item, updated, previous) {
              log("item-assumption-rate-change", {
                itemId: item.id,
                itemName: item.name,
                updated: updated,
                previous: previous
              });
            },
            read: function(event) {
              return (
                "Changed '" +
                event.params.itemName +
                "' annual growth rate from " +
                event.params.previous +
                "% to " +
                event.params.updated +
                "%."
              );
            }
          },
          delta: {
            log: function(item, updated, previous) {
              log("item-assumption-delta-change", {
                itemId: item.id,
                itemName: item.name,
                updated: updated,
                previous: previous
              });
            },
            read: function(event) {
              return (
                "Changed '" +
                event.params.itemName +
                "' annual growth rate from $" +
                event.params.previous +
                " to $" +
                event.params.updated +
                "."
              );
            }
          },
          granular: {
            log: function(item, updated, previous, index) {
              log("item-assumption-granular-change", {
                itemId: item.id,
                itemName: item.name,
                updated: updated,
                previous: previous,
                index: index
              });
            },
            read: function(event) {
              return (
                "Changed '" +
                event.params.itemName +
                "' granular growth assumptions."
              );
            }
          }
        },
        year: {
          start: {
            log: function(item, updated, previous) {
              log("item-start-change", {
                itemId: item.id,
                itemName: item.name,
                previous: previous.index,
                previousDisplay: previous.display,
                updated: updated.index,
                updatedDisplay: updated.display
              });
            },
            read: function(event) {
              return (
                "Changed start year of '" +
                event.params.itemName +
                "' from " +
                event.params.previousDisplay +
                " to " +
                event.params.updatedDisplay
              );
            }
          },
          end: {
            log: function(item, updated, previous) {
              log("item-end-change", {
                itemId: item.id,
                itemName: item.name,
                previous: previous.index,
                previousDisplay: previous.display,
                updated: updated.index,
                updatedDisplay: updated.display
              });
            },
            read: function(event) {
              return (
                "Changed end year of '" +
                event.params.itemName +
                "' from " +
                event.params.previousDisplay +
                " to " +
                event.params.updatedDisplay
              );
            }
          }
        }
      },
      category: {
        add: {
          log: function(itemId, parent) {
            log("add-category", {
              itemId: itemId,
              parentId: parent.id,
              parentName: parent.name
            });
          },
          read: function(event) {
            return "Added category to '" + event.params.parentName + "'.";
          }
        }
      },
      tag: {
        add: {
          log: function(itemId, itemName, tag) {
            log("tag-add-item", {
              itemId: itemId,
              itemName: itemName,
              tag: tag
            });
          },
          read: function(event) {
            return (
              "Added '" +
              event.params.tag +
              "' tag to '" +
              event.params.itemName +
              "'."
            );
          }
        },
        remove: {
          log: function(itemId, itemName, tag) {
            log("tag-remove-item", {
              itemId: itemId,
              itemName: itemName,
              tag: tag
            });
          },
          read: function(event) {
            return (
              "Removed '" +
              event.params.tag +
              "' tag from '" +
              event.params.itemName +
              "'."
            );
          }
        }
      },
      tuition: {
        enrollment: {
          log: function(
            year,
            yearName,
            classIndex,
            className,
            previous,
            updated,
            carry
          ) {
            log("enrollment-change", {
              yearIndex: year,
              yearName: yearName,
              classIndex: classIndex,
              className: className,
              updated: updated,
              previous: previous,
              carry: carry
            });
          },
          read: function(event) {
            var s =
              "Changed " +
              event.params.yearName +
              " " +
              event.params.className +
              " enrollment from " +
              event.params.previous +
              " to " +
              event.params.updated +
              ".";
            if (event.params.carry) s += " The change carried.";
            return s;
          }
        },
        rate: {
          current: {
            log: function(classIndex, className, previous, updated) {
              log("rate-change", {
                classIndex: classIndex,
                className: className,
                updated: updated,
                previous: previous
              });
            },
            read: function(event) {
              var s =
                "Changed " +
                event.params.className +
                " tuition rate from " +
                event.params.previous +
                " to " +
                event.params.updated +
                ".";
              if (event.params.carry) s += " The change carried.";
              return s;
            }
          },
          growth: {
            log: function(
              classIndex,
              className,
              yearIndex,
              yearName,
              previous,
              updated,
              carryOver,
              carryDown
            ) {
              log("rate-growth-change", {
                yearIndex: yearIndex,
                yearName: yearName,
                classIndex: classIndex,
                className: className,
                updated: updated,
                previous: previous,
                carryDown: carryDown,
                carryOver: carryOver
              });
            },
            read: function(event) {
              var s =
                "Changed " +
                event.params.className +
                " tuition rate from " +
                event.params.previous +
                "% to " +
                event.params.updated +
                "%.";
              if (event.params.carryDown || event.params.carryOver)
                s += " The change carried ";
              if (event.params.carryDown && event.params.carryOver)
                s += "down and over.";
              else if (event.params.carryDown) s += "down.";
              else if (event.params.carryOver) s += "over.";
              return s;
            }
          }
        }
      }
    };

    service.events = events;

    service.readEvent = function(event) {
      if (event.id === "initial-state") return "Log in";
      /* Budget Tree */
      var ref = eventLookupService.lookup(events, event);
      if (!ref) return "";
      var prefix = "";
      if (event.hidden) {
        if (event.inverse) prefix = "Undo: ";
        else prefix = "Redo: ";
      }
      return prefix + ref.read(event);
    };

    return service;
  }
]);
