import _ from "underscore";
import angular from "angular";
import {AUTH_EVENTS} from "./auth/service";

const get = path => {};

angular.module("helme.api", []).service("api", [
  "$rootScope",
  "$http",
  "Session",
  "notify",
  "ENV",
  "Upload",
  function($rootScope, $http, Session, notify, ENV, Upload) {
    var schoolId = "";

    if (!!Session.id) {
      schoolId = Session.school._id;
    }

    $rootScope.$on(AUTH_EVENTS.loginSuccess, function() {
      schoolId = Session.school._id;
    });

    var offline = false;

    var errorHandle = function(err, status) {
      var message = "";
      console.info(status);
      if (status === -1) {
        $rootScope.$broadcast("request-failure");
        return;
      }
      if (!!err && err.message === "invalid_session") {
        $rootScope.$broadcast(AUTH_EVENTS.sessionExpired);
        message = "Session expired!";
      } else if (err === ":helme.user.database/no-such-user")
        message = "No such user.";
      else if (err === ":helme.user.database/invalid-credentials") return;
      else if (!!Session.loggedIn && Session.user.name === "demo@helme.io") {
        return;
      }
      notify({
        message: message,
        position: "right",
        duration: 1500
      });
      throw err;
    };
    var path =
      process.env.NODE_ENV === "development"
        ? "http://localhost:8080"
        : "https://api.helme.io";
    return {
      setSessionId: function(id) {
        $http.defaults.headers.common["session-id"] = id;
      },
      ping: function() {
        return $http.get(path + "/ping");
      },
      login: function(email, pass) {
        return $http
          .post(path + "/session/login", {
            email: email.toLowerCase(),
            pass: pass
          })
          .catch(errorHandle);
      },
      session: {
        isValid: function() {
          return $http.get(path + "/session/is-valid").catch(errorHandle);
        },
        refresh: function() {
          return $http.post(path + "/session/refresh").catch(errorHandle);
        },
        getDetails: function() {
          return $http.get(path + "/session/details").catch(errorHandle);
        },
        updateDetails: function() {
          return $http.post(path + "/session/details").catch(errorHandle);
        }
      },
      pdf: function(content) {
        return $http.post(ENV.nodeEndpoint + "/render-pdf", {
          content: content,
          filename: schoolId + "-" + new Date().getTime() + ".pdf"
        });
      },
      client: {
        update: function(props) {
          return $http
            .post(path + "/client/" + schoolId + "/update-client", {
              data: props
            })
            .catch(errorHandle);
        }
      },
      user: {
        list: function() {
          return $http
            .get(path + "/" + schoolId + "/user/list")
            .catch(errorHandle);
        },
        create: function(user) {
          user._id = null;
          user.name = user.name.toLowerCase();
          return $http
            .post(path + "/" + schoolId + "/user/create", user)
            .catch(errorHandle);
        },
        remove: function(user) {
          return $http
            .post(path + "/" + schoolId + "/user/remove", {
              "user-id": user._id
            })
            .catch(errorHandle);
        },
        update: function(user) {
          return $http
            .post(path + "/" + schoolId + "/user/" + user._id + "/update", {
              data: _.omit(user, "salt", "hash", "name", "pass")
            })
            .catch(errorHandle);
        },
        verify: function(user) {
          return $http
            .post(path + "/" + schoolId + "/user/verify-user", {
              email: user.name.toLowerCase()
            })
            .catch(errorHandle);
        },
        resetPassword: function(user) {
          return $http
            .post(path + "/user/reset-password-begin", {
              email: user.name.toLowerCase()
            })
            .catch(errorHandle);
        }
      },
      data: {
        get: function() {
          return $http.get(path + "/" + schoolId + "/data").catch(errorHandle);
        },
        update: function(data) {
          return $http
            .post(path + "/" + schoolId + "/data", {
              data: data
            })
            .catch(errorHandle);
        }
      },
      export: {
        pandl: function() {
          return $http({
            url: path + "/" + schoolId + "/export/pandl",
            method: "POST",
            headers: {
              "Content-type": "application/json",
              "session-id": Session.id
            },
            responseType: "arraybuffer"
          })
            .then(function(res, status, headers, config) {
              var blob = new Blob([res.data], {
                type:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              });
              var objectUrl = URL.createObjectURL(blob);
              var link = document.createElement("a");
              link.href = objectUrl;
              link.download = Session.school.name + " P&L.xlsx";
              link.click();
              window.URL.revokeObjectURL(link.href);
            })
            .catch(errorHandle);
        }
      },
      import: {
        quickbooks: function(file) {
          return Upload.upload({
            url: path + "/" + schoolId + "/import/quickbooks",
            file: file,
            headers: {
              "session-id": Session.id
            }
          }).catch(errorHandle);
        },
        excel: function(file) {
          return Upload.upload({
            url: path + "/" + schoolId + "/import/excel",
            file: file,
            headers: {
              "session-id": Session.id
            }
          }).catch(errorHandle);
        }
      },
      accounts: {
        get: function() {
          return $http.get(ENV.nodeEndpoint + "/actuals/" + schoolId);
        }
      },
      intuit: {
        test: function() {
          return $http.get(path + "/" + schoolId + "/qbo/test-connection");
        },
        budget: function() {
          return $http
            .post(path + "/" + schoolId + "/qbo/budget", {
              "budget-index": 0
            })
            .catch(errorHandle);
        },
        accounts: function() {
          return $http
            .get(path + "/" + schoolId + "/qbo/account-list")
            .catch(errorHandle);
        },
        actualsSummary: function() {
          return $http
            .get(path + "/" + schoolId + "/qbo/actuals")
            .catch(errorHandle);
        },
        disconnect: function() {
          return $http
            .post(path + "/" + schoolId + "/qbo/disconnect")
            .catch(errorHandle);
        },
        companyInfo: function() {
          return $http
            .get(path + "/" + schoolId + "/qbo/company-info")
            .catch(errorHandle);
        }
      },
      stripe: {
        update: function(token) {
          return $http
            .post(path + "/" + schoolId + "/update-payment", {
              "card-token": token
            })
            .catch(errorHandle);
        }
      },
      compare: function() {
        return $http.get(path + "/" + schoolId + "/compare").catch(errorHandle);
      },
      dashboard: {
        list: function() {
          return $http
            .get(path + "/" + schoolId + "/dashboard/list")
            .catch(errorHandle);
        },
        update: function(dashboard) {
          return $http
            .post(path + "/" + schoolId + "/dashboard/update", {
              dashboard: dashboard
            })
            .catch(errorHandle);
        },
        create: function(title, blank) {
          return $http
            .post(path + "/" + schoolId + "/dashboard/new", {
              title: title,
              blank: blank
            })
            .catch(errorHandle);
        },
        remove: function(dashboardId) {
          return $http
            .post(path + "/" + schoolId + "/dashboard/delete", {
              "dashboard-id": dashboardId
            })
            .catch(errorHandle);
        },
        reset: function() {
          return $http
            .post(path + "/" + schoolId + "/dashboard/reset", {})
            .catch(errorHandle);
        }
      },
      budget: {
        get: function() {
          return $http
            .get(path + "/" + schoolId + "/budget")
            .catch(errorHandle);
        },
        update: function(budget) {
          return $http
            .post(path + "/" + schoolId + "/budget/update", {
              budget: budget
            })
            .catch(errorHandle);
        },
        insert: function(sortedItems) {
          return $http
            .post(path + "/" + schoolId + "/budget/insert", {
              data: sortedItems
            })
            .catch(errorHandle);
        }
      },

      scenario: {
        getList: function() {
          return $http
            .get(path + "/" + schoolId + "/scenario-list")
            .catch(errorHandle);
        },
        remove: function(scenario) {
          return $http
            .post(path + "/" + schoolId + "/scenario/delete", {
              "scenario-id": scenario._id
            })
            .catch(errorHandle);
        },
        upsert: function(scenario) {
          return $http
            .post(path + "/" + schoolId + "/scenario/upsert", {
              scenario: scenario
            })
            .catch(errorHandle);
        }
      },
      compare: function() {
        return $http.get(path + "/" + schoolId + "/compare");
      }
    };
  }
]);
