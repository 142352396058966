import angular from "angular";

angular.module("helme.pages").controller("CreateDashboardCtrl", [
  "$scope",
  "dashboardService",
  "api",
  function($scope, dashboardService, api) {
    $scope.dashboardTypes = ["Blank", "Head of School"];
    $scope.dashboard = {
      title: "New Dashboard",
      type: "Blank"
    };
    $scope.selectType = function(t) {
      $scope.dashboard.type = t;
    };

    $scope.submit = function() {
      $scope.loading = true;
      api.dashboard
        .create($scope.dashboard.title, $scope.dashboard.type === "Blank")
        .then(function(res) {
          dashboardService.exports.list.push(res.data.dashboard);
          dashboardService.exports.current = res.data.dashboard;
          dashboardService.refreshAllTiles();
          $scope.$close();
        })
        .catch(function(err) {
          $scope.err = "Dashboard creation failed. Try again later.";
          $scope.loading = false;
        });
    };
  }
]);
