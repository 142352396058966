import _ from "underscore";
/* Modal that allows users to link quickbooks accounts to helme items and tags.
   When visible, takes over the space dedicated to the inspector and each item gets a "link" button.

   Main interface is a list of accounts, with number of links on them. Expand button expands accordeon style to show what accounts are linked.
   Link button on each acocunt. If clicked, that item becomes "active" (highlighted) and allows the user to link that account to items.

 ================================ */

import angular from "angular";

angular.module("helme.quickbooks").controller("actualsAccountLinkCtrl", [
  "$scope",
  "confirmDialog",
  "dataService",
  "actualsLinkService",
  "api",
  "collectionService",
  function(
    $scope,
    confirmDialog,
    dataService,
    actualsLinkService,
    api,
    collectionService
  ) {
    $scope.linker = actualsLinkService.exports;
    $scope.select = actualsLinkService.select;
    $scope.filter = "All";
    $scope.expand = function(account) {
      $scope.expanded[account.id] = true;
    };

    $scope.collapse = function(account) {
      delete $scope.expanded[account.id];
    };

    $scope.link = function(item) {
      var config = {
        title: "Link " + item.name + "?",
        cancelText: "Cancel",
        confirmtText: "Link"
      };
      confirmDialog.open(config).result.then(
        function() {
          actualsLinkService.addItemLink(item.id, actualsLinkService.active.id);
        },
        function() {}
      );
    };

    $scope.itemName = function(itemId) {
      var item = dataService.getItem(itemId);
      if (!item) return "";
      return item.name;
    };

    $scope.accountName = function(accountId) {
      var account = _.find($scope.accounts, function(account) {
        return accountId === account.id;
      });
      if (!account) return "";
      return account.name;
    };

    $scope.sortFn = function(account) {
      var s = ["Income", "Expense", "Bank"].indexOf(account.type);
      if (account.parent) s += $scope.accountName(account.parent);
      return s + account.name;
    };

    $scope.filterFn = function(account) {
      return (
        _.contains(["Income", "Expense", "Bank"], account.type) &&
        ($scope.filter === "All" || account.type === $scope.filter)
      );
    };

    $scope.accountColorClass = function(account) {
      if (!_.contains(["Income", "Expense", "Bank"], account.type))
        return {
          Income: "income-text",
          Expense: "expense-text",
          Bank: "helme-text"
        }[account.type];
    };

    function setup() {
      $scope.accounts = actualsLinkService.accountsWithLinks();
      $scope.filter = "All";
      if (collectionService.type === "income") $scope.filter = "Income";
      if (collectionService.type === "expense") $scope.filter = "Expense";
    }

    $scope.$watch(function() {
      return actualsLinkService.linkCount();
    }, setup);

    setup();
  }
]);
