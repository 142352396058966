import angular from "angular";
import * as d3 from "d3";

angular.module("helme.graphs").directive("areaChart", [
  "formatService",
  "$timeout",
  "ENV",
  function(formatService, $timeout, ENV) {
    var m = [15, 15, 15, 15]; // margins
    var w = 550 - m[1] - m[3]; // width
    var h = 70 - m[0] - m[2]; // height

    function initChart(el) {
      var labels = d3
        .select(el)
        .append("div")
        .attr("class", "labels");
      var grid = d3
        .select(el)
        .select("svg")
        .append("g")
        .attr("transform", "translate(" + m[3] + "," + m[0] + ")")
        .attr("class", "grid");
      var selection = d3.select(el);
      w = selection[0][0].clientWidth - m[1] - m[3];
    }

    function drawAxis(svg) {
      // Scales and axes. Note the inverted domain for the y-scale: bigger is up!
      var x = d3.scale
        .linear()
        .range([m[1], w - m[3]])
        .domain([ENV.currentYear, ENV.currentYear + 7]);

      var y = d3.scale.linear().range([h + 20, 0]);

      var xAxis = d3.svg
        .axis()
        .scale(x)
        .tickSize(-h)
        .tickSubdivide(true)
        .orient("center")
        .tickFormat(function(d) {
          return d;
        });

      var yAxis = d3.svg
        .axis()
        .scale(y)
        .ticks(4)
        .orient("right");

      if (d3.select("g.x-axis").empty())
        svg
          .append("g")
          .attr("class", "x-axis")
          .attr("transform", "translate(10," + (h + 12) + ")")
          .call(xAxis);
    }

    function drawLine(data, svg, x, y) {
      var areaFn = d3.svg
        .area()
        .x(function(d, i) {
          return x(d.year);
        })
        .y0(h - m[1])
        .y1(function(d, i) {
          return y(d.value);
        });
      var lineFn = d3.svg
        .line()
        .x(function(d, i) {
          return x(d.year);
        })
        .y(function(d, i) {
          return y(d.value);
        });

      var path = svg.selectAll("path.line-graph.line").data([data]);
      var area = svg.selectAll("path.line-graph.area").data([data]);

      path
        .enter()
        .append("path")
        .attr("transform", "translate(" + m[3] + "," + m[0] + ")")
        .attr("class", "line-graph line");
      area
        .enter()
        .append("path")
        .attr("transform", "translate(" + m[3] + "," + m[0] + ")")
        .attr("class", "line-graph area");

      path
        .transition()
        .duration(500)
        .ease("linear")
        .attr("d", lineFn);
      area
        .transition()
        .duration(500)
        .ease("linear")
        .attr("d", areaFn);

      path.exit().remove();
      area.exit().remove();
    }

    function labelFormat(d, type) {
      var value = d;
      if (type === "dollar")
        value = formatService.formatDollarValue(value, true);
      else if (type === "percent")
        value = formatService.formatPercentage(value);

      return value;
    }

    function drawLabels(data, el, svg, x, y) {
      // draw dots
      var labels = d3
        .select(el)
        .select(".labels")
        .selectAll(".label")
        .data(data);

      labels
        .enter()
        .append("div")
        .attr("class", "label");

      labels
        .html(function(d) {
          return (
            "<div class='value'>" + labelFormat(d.value, d.type) + "</div>"
          );
        })
        .style("position", "absolute")
        .style("margin-top", function(d) {
          return y(d.value) - 85 + "px";
        })
        .style("left", function(d, i) {
          return x(ENV.currentYear + i) - 5 + "px";
        });

      labels.exit().remove();
    }

    function drawPoints(data, el, svg, x, y) {
      // draw dots
      var dots = svg.selectAll(".dot").data(data);

      dots
        .enter()
        .append("circle")
        .attr("class", "dot");

      dots
        .transition()
        .duration(500)
        .ease("linear")
        .attr("r", 3)
        .attr("cx", function(d) {
          return x(d.year);
        })
        .attr("cy", function(d) {
          return y(d.value);
        })
        .attr("transform", "translate(" + m[3] + "," + m[0] + ")");

      dots.exit().remove();
    }

    function draw(data, el) {
      var svg = d3.select(el).select("svg");

      svg.attr("width", w + m[1] + m[3]).attr("height", h + m[0] + m[2]);

      var x = d3.scale
        .linear()
        .domain(
          d3.extent(data, function(d) {
            return d.year;
          })
        )
        .range([m[1], w - m[3]]);

      var y = d3.scale
        .linear()
        .domain(
          d3.extent(data, function(d) {
            return d.value;
          })
        )
        .range([h - m[1], 0]);

      svg
        .transition()
        .duration(500)
        .ease("linear")
        .select("g.grid")
        .call(
          d3.svg
            .axis()
            .scale(y)
            .orient("left")
            .ticks(4)
            .tickSize(-w, 0, 0)
            .tickFormat("")
        );

      drawAxis(svg, y);
      drawLine(data, svg, x, y);
      drawPoints(data, el, svg, x, y);
      drawLabels(data, el, svg, x, y);
    }

    function link(scope, element, attribs) {
      initChart(element[0]);

      scope.$watch("data", function(previous, next) {
        if (previous !== next) {
          draw(scope.data, element[0]);
        }
      });
      scope.$on("refresh-viz", function() {
        $timeout(function() {
          draw(scope.data, element[0]);
        }, 200);
      });
    }

    return {
      restrict: "E",
      replace: true,
      link: link,
      scope: {
        data: "="
      },
      template: " <div class='area-chart'> <svg></svg> </div>"
    };
  }
]);
